import { memo, ReactElement } from 'react';
import * as PropTypes from 'prop-types';

import FooterNav from '../FooterNav/FooterNav';
import FooterCopyright from '../FooterCopyright/FooterCopyright';
import SocialIcons from '../SocialIcons/SocialIcons';
import MobileAppButtons from '../MobileAppButtons/MobileAppButtons';
import HBLogo from '../../svg/honeybook-logo.inline.svg';
// import HBLogoSmall from '../../svg/honeybook-logo-mini.inline.svg';
import './footer.scss';
import NavigateLink from '../Link/NavigateLink';
import IntercomService from '../../Services/IntercomService';

export interface ILink {
    id: string;
    link?: string;
    clickHandler?: () => void;
}

export interface FooterNavbarItem {
    id: string;
    links: ILink[];
}

const context = { source: 'footer' };

const footerNavBarItems: FooterNavbarItem[] = [
    {
        id: 'product',
        links: [
            {
                id: 'features',
                link: '/features'
            },
            {
                id: 'contracts',
                link: '/online-contract'
            },
            {
                id: 'onlineSignatureGenerator',
                link: '/online-signature-generator'
            },
            {
                id: 'onlineDocumentSigning',
                link: '/online-document-signing'
            },
            {
                id: 'invoices',
                link: '/online-invoices'
            },
            {
                id: 'onlinePayments',
                link: '/online-payment-software'
            },
            {
                id: 'payments',
                link: '/payment-reminders'
            },
            {
                id: 'proposals',
                link: '/proposal-software'
            },
            {
                id: 'scheduling',
                link: '/meeting-scheduler'
            },
            {
                id: 'automation',
                link: '/automations'
            },
            {
                id: 'finance',
                link: '/lp/finance'
            }
        ]
    },
    {
        id: 'resources',
        links: [
            {
                id: 'chat',
                clickHandler: () => IntercomService.showIntercom('footer')
            },
            {
                id: 'help',
                link: 'https://help.honeybook.com'
            },
            {
                id: 'blog',
                link: '/blog'
            },
            {
                id: 'education',
                link: '/product-education'
            },
            {
                id: 'academy',
                link: '//academy.honeybook.com/'
            },
            {
                id: 'podcast',
                link: 'https://podcast.honeybook.com/'
            },
            {
                id: 'pros',
                link: 'https://pros.honeybook.com'
            },
            {
                id: 'fileSetup',
                link: '/free-account-migration'
            },
            {
                id: 'sitemap',
                link: '/sitemap'
            }
        ]
    },
    {
        id: 'tools',
        links: [
            {
                id: 'contractTemplates',
                link: '/templates/category/contract-templates'
            },
            {
                id: 'invoiceTemplates',
                link: '/templates/category/invoice-templates'
            },
            {
                id: 'invoiceGenerator',
                link: '/invoice-template'
            },
            {
                id: 'taxCalculator',
                link: '/tools/self-employment-tax-calculator'
            },
            {
                id: 'emailSignature',
                link: '/email-signature'
            },
            {
                id: 'missionStatement',
                link: '/mission-statement'
            },
            {
                id: 'bizTool',
                link: '/business-personality-test'
            }
        ]
    },
    {
        id: 'honeybook',
        links: [
            {
                id: 'pricing',
                link: '/pricing'
            },
            {
                id: 'reviews',
                link: '/reviews'
            },
            {
                id: 'about',
                link: '/about'
            },
            {
                id: 'careers',
                link: '/careers'
            },
            {
                id: 'press',
                link: '/press'
            },
            {
                id: 'news',
                link: '//news.honeybook.com'
            },
            {
                id: 'referrals',
                link: '/referrals'
            },
            {
                id: 'tos',
                link: '/terms/terms-of-service'
            },
            {
                id: 'subpoena',
                link: '/lp/policy/subpoena-policy'
            },
            {
                id: 'responsibleAI',
                link: '/lp/responsible-ai'
            },
            {
                id: 'contact',
                link: '/contact-us'
            }
        ]
    }
];

interface Props {
    copyrightOnly?: boolean;
}

const Footer = ({ copyrightOnly }: Props): ReactElement => {
    return (
        <footer className="footer">
            {!copyrightOnly && (
                <>
                    <div className="footer-container footer-container--primary">
                        <div className="footer-column footer-column--logo">
                            <NavigateLink source="logo" link="/">
                                <HBLogo />
                            </NavigateLink>
                        </div>
                        <FooterNav items={footerNavBarItems} />
                    </div>
                    <div className="footer-container footer-container--secondary">
                        <div className="footer-column" />
                        <div className="footer-column">
                            <FooterCopyright
                                customClass={`${
                                    copyrightOnly ? 'footer-only' : ''
                                }`}
                            />
                        </div>
                        <div className="footer-column" />
                        <div className="footer-column">
                            <div className="footer__social-icons-list">
                                <SocialIcons
                                    context={context}
                                    source="footer"
                                />
                            </div>
                        </div>
                        <div className="footer-column">
                            <div className="footer__app-btn-list">
                                <MobileAppButtons darkMode />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </footer>
    );
};

Footer.propTypes = {
    copyrightOnly: PropTypes.bool
};

export default memo(Footer);
