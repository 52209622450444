import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import { useABTestContext } from '../../../ab-tests/context/useABTestContext';

import './client-relationships-hero.scss';
import VideoModal from '../../Modals/VideoModal/VideoModal';
import HBVideo from '../../honeybook-ui/HBVideo/HBVideo';

const ClientRelationshipsHero = () => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();
    const {
        variantPerTest: { client_relationships_hero_test }
    } = useABTestContext();

    const subtitle =
        client_relationships_hero_test === 'test'
            ? t('clientRelationships.hero.subtitleAlt')
            : t('clientRelationships.hero.subtitle');

    const [videoModalOpen, setVideoModalOpen] = useState(false);

    const toggleVideoModal = useCallback(isOpenModal => {
        AnalyticsService.trackClick(
            AnalyticsEvents.interactive_files_video_modal,
            {
                source: isOpenModal ? 'open video modal' : 'close video modal'
            }
        );
        setVideoModalOpen(videoModalOpen => !videoModalOpen);
    }, []);

    return (
        <FeatureBlockSection customClass="client-relationships-hero">
            <FeatureBlockContent customClass="client-relationships-hero__content">
                <div className="client-relationships-hero__split-layout">
                    <div className="client-relationships-hero__text-container">
                        <HBText
                            tag="h1"
                            type="title-m"
                            customClass="client-relationships-hero__title"
                        >
                            {t('clientRelationships.hero.title')}
                        </HBText>
                        <div className="client-relationships-hero__bubble-container">
                            <HBText
                                tag="p"
                                type="body-xl"
                                customClass="client-relationships-hero__bubble-text client-relationships-hero__bubble-text--first"
                            >
                                {t('clientRelationships.hero.bubbleText1')}
                            </HBText>
                            <HBText
                                tag="p"
                                type="body-xl"
                                customClass="client-relationships-hero__bubble-text client-relationships-hero__bubble-text--second"
                            >
                                {t('clientRelationships.hero.bubbleText2')}
                            </HBText>
                        </div>
                        <HBText
                            tag="p"
                            type="body-xl"
                            customClass="client-relationships-hero__subtitle"
                        >
                            {subtitle}
                        </HBText>

                        <RegistrationForm
                            source="hero"
                            showNoCcRequired
                            size="large"
                            buttonStyle="primary--bright"
                        />

                        <VideoModal
                            video={{
                                id: 'hero',
                                videoURL:
                                    'https://youtube.com/embed/Lg4uX6bjExc'
                            }}
                            customClass="homepage-product-hero-new__video-modal"
                            isOpen={videoModalOpen}
                            onClose={() => toggleVideoModal(false)}
                        />
                    </div>

                    {breakpoints.medium ? (
                        <HBVideo
                            videoId="1031365924"
                            muted={false}
                            autoplay={true}
                            controls={true}
                        />
                    ) : (
                        <div onClick={() => toggleVideoModal(true)}>
                            <HBMediaElement
                                customClass="client-relationships-hero__illustration"
                                name={
                                    breakpoints.medium
                                        ? 'marketing_site/october_gtm/oct_gtm_hero_mobile.png'
                                        : 'v1731543436/marketing_site/october_gtm/oct-gtm-hero-video'
                                }
                                lazyLoad={false}
                            />
                        </div>
                    )}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default ClientRelationshipsHero;
