import { useTranslation } from 'react-i18next';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import './star-rating-block.scss';
import HBText from '../../honeybook-ui/HBText/HBText';
import {
    CAPTERRA_REVIEWS,
    G2_REVIEWS,
    APPLE_REVIEWS
} from '../../../constants/constants';
import UtilsService from '../../../Services/UtilsService';
import StarsIcon from '../../../svg/starRatings.inline.svg';

const ratings = [G2_REVIEWS, CAPTERRA_REVIEWS, APPLE_REVIEWS];

interface StarRatingCardProps {
    rating: number;
    iconUrl: string;
    totalReviews: number;
}

const StarRatingCard = ({
    rating,
    iconUrl,
    totalReviews
}: StarRatingCardProps) => {
    const { t } = useTranslation();

    return (
        <div className="star-rating-card">
            <div className="star-rating-card__ratings">
                <HBText
                    tag="p"
                    type="body-m"
                    customClass="star-rating-card__rating-text"
                >
                    {t(rating)}
                </HBText>
                <StarsIcon />
            </div>
            <div className="star-rating-card__reviews">
                <HBMediaElement
                    customClass="star-rating-card__icon"
                    name={iconUrl}
                />
                <HBText
                    tag="p"
                    type="body-m"
                    customClass="star-rating-card__reviews-text"
                >
                    {UtilsService.formatReviewsNumber(totalReviews)}{' '}
                    {t('starRatingBlock.reviews')}
                </HBText>
            </div>
        </div>
    );
};

const StarRatingBlock = () => {
    return (
        <div className="star-rating-block">
            <FeatureBlockSection>
                <FeatureBlockContent>
                    {ratings.map(({ rating, totalReviews, iconUrl, url }) => (
                        <a
                            className="star-rating-card__link"
                            href={url}
                            key={url}
                        >
                            <StarRatingCard
                                rating={rating}
                                iconUrl={iconUrl}
                                totalReviews={totalReviews}
                            />
                        </a>
                    ))}
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

export default StarRatingBlock;
