import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import HBText from '@honeybook-ui/HBText/HBText';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './discover-link-block.scss';

const defaultLinkData = [
    {
        id: 'blog',
        url: '/blog'
    },
    {
        id: 'businessAcademy',
        url: '//academy.honeybook.com/'
    },
    {
        id: 'community',
        url: '//community.honeybook.com/'
    },
    {
        id: 'help',
        url: '//help.honeybook.com/en/'
    }
];

const handleLinkClick = source => {
    AnalyticsService.trackClick(AnalyticsEvents.discover_link_block, {
        source
    });
};

export interface LinkDataType {
    id: string;
    url: string;
    imageUrl?: string;
}

interface Props {
    customTitle?: string;
    customSubtitle?: string;
    linkData?: LinkDataType[];
}

const DiscoverLinkBlock = ({
    customTitle,
    customSubtitle,
    linkData = defaultLinkData
}: Props) => {
    const { t } = useTranslation();

    const { mediumUp } = useBreakpoint();

    return (
        <FeatureBlockSection
            customClass="discover-link-block"
            darkBackground
            smallHeight
        >
            <FeatureBlockContent>
                <HBText
                    tag="h2"
                    type="title-l"
                    customClass="discover-link-block__title"
                >
                    {customTitle ? customTitle : t('DiscoverLinkBlock.title')}
                </HBText>
                {customSubtitle && (
                    <HBText
                        tag="h3"
                        type="body-l"
                        customClass="discover-link-block__subtitle"
                    >
                        {customSubtitle}
                    </HBText>
                )}
                <div className="discover-link-box-container">
                    {mediumUp ? (
                        <DiscoverLinkBlockContainerDesktop
                            linkData={linkData}
                        />
                    ) : (
                        <DiscoverLinkBlockContainerMobile linkData={linkData} />
                    )}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(DiscoverLinkBlock);

interface TDiscoverLinkBlockContainer {
    linkData: LinkDataType[];
}

const DiscoverLinkBlockContainerDesktop = ({
    linkData
}: TDiscoverLinkBlockContainer) => {
    return (
        <>
            {linkData.map(link => (
                <DiscoverLinkBlockCard key={link.id} link={link} />
            ))}
        </>
    );
};

const DiscoverLinkBlockContainerMobile = ({
    linkData
}: TDiscoverLinkBlockContainer) => {
    return (
        <Swiper
            className="discover-link-block__swiper"
            slidesPerView={1.5}
            initialSlide={0}
            centeredSlides={true}
        >
            {linkData.map(link => (
                <SwiperSlide key={link.id}>
                    {<DiscoverLinkBlockCard link={link} />}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

const DiscoverLinkBlockCard = ({ link }) => {
    const { t } = useTranslation();
    return (
        <a
            key={link.id}
            target="_blank"
            className="discover-link-box__link"
            href={link.url}
            onClick={() => handleLinkClick(link.id)}
        >
            <div className="discover-link-box">
                {link.imageUrl && (
                    <HBMediaElement
                        customClass="discover-link-box__image"
                        name={link.imageUrl}
                    />
                )}

                <div className="discover-link-box__text-container">
                    {t(`DiscoverLinkBlock.${link.id}.title`, {
                        defaultValue: null
                    }) && (
                        <h3 className="discover-link-box__title">
                            {t(`DiscoverLinkBlock.${link.id}.title`)}
                        </h3>
                    )}
                    <p className="discover-link-box__description">
                        {t(`DiscoverLinkBlock.${link.id}.description`)}
                    </p>
                    <p className="discover-link-box__link-text">
                        {t(`DiscoverLinkBlock.${link.id}.linkText`)}{' '}
                        <HBIcon
                            customClass="discover-link-box__arrow"
                            name="nx-nx-arrow-right-42"
                        />
                    </p>
                </div>
            </div>
        </a>
    );
};
