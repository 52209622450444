import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import ComparisonTableBlock from '../components/FeatureBlocks/ComparisonTableBlock/ComparisonTableBlock';
import ImageTextElementLinkGrid from '../components/FeatureBlocks/ImageTextElementLinkGridBlock/ImageTextElementLinkGridBlock';
import TestimonialsCarousel from '../components/TestimonialsCarousel/TestimonialsCarousel';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../components/FeatureBlockElements';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';

import '../styles/comparison.scss';

import { TRANSLATION_COMPONENTS } from '../constants/constants';

const ogTags = {
    dubsado: {
        url: 'https://www.honeybook.com/honeybook-vs-dubsado',
        title: 'HoneyBook vs. Dubsado: Compare Platforms',
        description: `HoneyBook vs Dubsado: what's the difference? Check this quick side-by-side comparison to find the right platform for your small business.`
    },
    freshbooks: {
        url: 'https://www.honeybook.com/honeybook-vs-freshbooks',
        title: 'HoneyBook vs. FreshBooks: Compare Platforms',
        description: `HoneyBook vs FreshBooks: what's the difference? Check this quick side-by-side comparison to find the right platform for your small business.`
    },
    bonsai: {
        url: 'https://www.honeybook.com/honeybook-vs-bonsai',
        title: 'HoneyBook vs. Bonsai: Compare Platforms',
        description: `HoneyBook vs Bonsai: what's the difference? Check this quick side-by-side comparison to find the right platform for your small business.`
    },
    seventeenHats: {
        url: 'https://www.honeybook.com/honeybook-vs-17hats',
        title: 'HoneyBook vs. 17Hats: Compare Platforms',
        description: `HoneyBook vs 17Hats: what's the difference? Check this quick side-by-side comparison to find the right platform for your small business.`
    },
    pipedrive: {
        url: 'https://www.honeybook.com/honeybook-vs-pipedrive',
        title: 'HoneyBook vs. Pipedrive: Compare Platforms',
        description: `HoneyBook vs Pipedrive: what's the difference? Check this quick side-by-side comparison to find the right platform for your small business.`
    },
    mondayDotCom: {
        url: 'https://www.honeybook.com/honeybook-vs-monday',
        title: 'HoneyBook vs. Monday.com: Compare Platforms',
        description: `HoneyBook vs Monday.com: what's the difference? Check this quick side-by-side comparison to find the right platform for your small business.`
    },
    vcita: {
        url: 'https://www.honeybook.com/honeybook-vs-vcita',
        title: 'HoneyBook vs. vcita: Compare Platforms',
        description: `HoneyBook vs vcita: what's the difference? Check this quick side-by-side comparison to find the right platform for your small business.`
    }
};

const otherFeatures = [
    {
        id: 'learning',
        image: 'marketing_site/comparison/learning.svg'
    },
    {
        id: 'setup',
        image: 'marketing_site/comparison/setup.svg'
    },
    {
        id: 'paid',
        image: 'marketing_site/comparison/payments.svg'
    },
    {
        id: 'mobileApp',
        image: 'marketing_site/comparison/mobile.svg'
    },
    {
        id: 'support',
        image: 'marketing_site/comparison/illustration-human.svg'
    },
    {
        id: 'feedback',
        image: 'marketing_site/comparison/illustration-feedback.svg'
    }
];

const quoteCarouselItems = [
    {
        id: 'greg',
        name: 'Greg Tosi',
        transKey: 'testimonials.greg',
        thumbImage: 'marketing_site/comparison/greg.png'
    },
    {
        id: 'patrick',
        name: 'Patrick Hellenga',
        transKey: 'testimonials.patrick',
        thumbImage: 'marketing_site/comparison/patrick.png'
    },
    {
        id: 'alexia',
        name: 'Alexia Butts',
        transKey: 'testimonials.alexia',
        thumbImage: 'marketing_site/comparison/alexia.png'
    },
    {
        id: 'stephanie',
        name: 'Stephanie Danforth',
        transKey: 'testimonials.stephanie',
        thumbImage: 'marketing_site/comparison/stephanie.png'
    },
    {
        id: 'yesenia',
        name: 'Yesenia Rosado',
        transKey: 'testimonials.yesenia',
        thumbImage: 'marketing_site/comparison/Yesenia.png'
    }
];

const ComparisonPage = ({ pageContext: { contentKey, isCtaTest } }) => {
    const { t } = useTranslation();

    return (
        <Layout ogTags={ogTags[contentKey]} isCtaTest={isCtaTest}>
            <div className="comparison-page">
                <WhiteHero
                    mainTitle={t(`${contentKey}.hero.title`)}
                    description={t(`${contentKey}.hero.subtitle`)}
                    image="marketing_site/comparison/hero-rebrand.png"
                    showRegistration
                    imageWidth={'auto'}
                />

                <ComparisonTableBlock
                    contentKey={contentKey}
                    transKey={`${contentKey}.table`}
                    showRegistration
                    finePrint={
                        <Trans
                            i18nKey={`table.finePrint`}
                            components={TRANSLATION_COMPONENTS}
                        />
                    }
                />

                <FeatureBlockSection
                    lightBackground
                    customClass="comparison-page__testimonials"
                >
                    <FeatureBlockContent>
                        <FeatureBlockText
                            primary={t('testimonials.title')}
                            fullWidth
                        />
                        <TestimonialsCarousel quotes={quoteCarouselItems} />
                    </FeatureBlockContent>
                </FeatureBlockSection>

                <ImageTextElementLinkGrid
                    lightBackground
                    customClass="comparison-page__other-features"
                    transKey="otherFeatures"
                    imageTextElements={otherFeatures}
                />

                <FeatureBlockSection
                    lightBackground
                    customClass="comparison-page__fine-print"
                >
                    <FeatureBlockContent>
                        <p className="comparison-page__fine-print__text">
                            {t('otherFeatures.finePrint.line1')}
                        </p>
                        <p className="comparison-page__fine-print__text">
                            {t('otherFeatures.finePrint.line2')}
                        </p>
                    </FeatureBlockContent>
                </FeatureBlockSection>

                <FeatureBlockSection customClass="comparison-page__text-block">
                    <FeatureBlockContent>
                        <FeatureBlockText
                            primary={t('textBlock.title')}
                            fullWidth
                        />
                        <p className="comparison-page__text-block__body-text">
                            {t('textBlock.body.line1')}
                        </p>
                        <p className="comparison-page__text-block__body-text">
                            {t('textBlock.body.line2')}
                        </p>
                    </FeatureBlockContent>
                </FeatureBlockSection>
                <PromoBlockLeft
                    title={t('PromoBlockLeftYellow.title')}
                    customClass="promo-block-left--yellow"
                    source="promo block left - yellow"
                    buttonStyle="primary--dark"
                />
            </div>
        </Layout>
    );
};

ComparisonPage.propTypes = {
    pageContext: PropTypes.object
};

export default ComparisonPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "comparison"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
