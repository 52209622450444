import * as PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../FeatureBlockElements';
import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../honeybook-ui/HBText/HBText';

import './featured-in.scss';

const defaultFeaturedItems = [
    {
        imgUrl: 'marketing_site/home_page/fortune.png',
        alt: 'Fortune'
    },
    {
        imgUrl: 'marketing_site/home_page/cnbc.png',
        alt: 'CNBC'
    },
    {
        imgUrl: 'marketing_site/home_page/fast-company.png',
        alt: 'Fast Company'
    },
    {
        imgUrl: 'marketing_site/home_page/forbes.png',
        alt: 'Forbes'
    },
    {
        imgUrl: 'marketing_site/home_page/reuters.png',
        alt: 'Reuters'
    },
    {
        imgUrl: 'marketing_site/home_page/tech-crunch.png',
        alt: 'Tech Crunch'
    }
];

const FeaturedIn = ({ featuredItems = defaultFeaturedItems }) => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection lightBackground customClass="featured-in">
            <FeatureBlockContent>
                <HBText
                    customClass="featured-in__title"
                    tag="h2"
                    type="title-s"
                >
                    {t(`featuredIn`)}
                </HBText>
                <div className="marquee-container">
                    <div className="marquee-content">
                        <ul className="featured-in__list">
                            {[...featuredItems, ...featuredItems].map(item => (
                                <li
                                    key={item.alt}
                                    className="featured-in__list-item"
                                >
                                    <HBMediaElement
                                        name={item.imgUrl}
                                        alt={item.alt}
                                        lazyLoad={false}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

FeaturedIn.propTypes = {
    featuredItems: PropTypes.arrayOf(PropTypes.object)
};

export default memo(FeaturedIn);
