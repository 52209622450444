import * as queryString from 'query-string';

import CookiesService from './CookiesService';
import UtilsService from './UtilsService';
import ApiService from './ApiService';
import AnalyticsService, { AnalyticsEvents } from './AnalyticsService';
import RegistrationFormError from '../Errors/RegistrationFormError';

import type { GeoData } from './GeolocationService';
import type {
    ValidateOnboardingResponse,
    SignupUserResponse
} from './ApiService';
import StorageService from './StorageService';
import ABTestToolServiceV2 from './ABTestToolService.v2';
import ABTestToolService from './ABTestToolService';
import { trackError } from '../utils/error.utils';
import type { TSignupDataTests } from '../ab-tests/types/ab-test-types';
import { trackAction } from '../utils/datadogaction.utils';

interface UserInitiatedData {
    vendor_email?: string;
    vendor_name?: string;
    is_sso: boolean;
    sso_type?: string;
    auth_token?: string;
    auth_code?: string;
    profile_img_url?: string;
    registration_variant: 'paywall' | 'homepage' | 'homepage_facebook';
    isFromSingleCta?: boolean;
    start_trial_variant?: string;
    couponCode?: string;
    dataForIntakeFormTest?: Record<string, any>;
}

interface InitialRegistrationData {
    is_demo: string | string[] | null;
    signup_landing_page: string;
    geo_data?: GeoData;
    ab_tests: TSignupDataTests;
    referrer: string;
    signupRedirectUrl?: string;
}

export interface SsoError {
    errorObject: { hbStatusText: string };
}

export interface SsoData {
    auth_token: string;
    sso_type: string;
    auth_code: string;
    is_sso: boolean;
    email: string;
    name: string;
    first_name: string;
    profile_img_url: string;
    registration_variant?: 'paywall' | 'homepage' | 'homepage_facebook';
    pricingPlan?: unknown;
    geo_data?: unknown;
    fullName?: string;
}

export type SsoResponse = SsoData & SsoError;

export interface AdsData {
    utmCampaign: string;
    utmContent: string;
    utmMedium: string;
    utmSource: string;
    utmTerm: string;
    utmMbsy: string;
    utmOrigin: string;
    utmInitiative: string;
    liFatId: string;
    referralSource: string;
    gclid: string;
    fbclid: string;
    irclickid: string;
    placement: string;
    promo: string;
}

interface IUserDataForIntakeForm {
    name: string;
    email: string;
    auth_token: string;
    sso_type: 'google_one_tap';
    auth_code: string;
    is_sso: boolean;
    is_firebase: boolean;
    profile_img_url: string;
}

export type RegistrationData = UserInitiatedData &
    InitialRegistrationData &
    AdsData;

export interface DataForRegister {
    vendor_email?: string;
    vendor_name?: string;
    registration_variant?: 'paywall' | 'homepage' | 'homepage_facebook';
    signup_landing_page?: string;
    is_demo?: string | string[] | null;
    country_name?: string;
    city?: string;
    country_code?: string;
    longitude?: string;
    latitude?: string;
    is_sso?: boolean;
    auth_token?: string;
    auth_code?: string;
    profile_img_url?: string;
    sso_type?: string;

    utm_campaign?: string;
    utm_content?: string;
    utmMbsy?: string;
    utm_medium?: string;
    utm_source?: string;
    utm_term?: string;
    utm_origin?: string;
    utm_initiative?: string;
    li_fat_id?: string;
    referralSource?: string;
    gclid?: string;
    fbclid?: string;
    irclickid?: string;
    promo?: string;
    placement?: string;
    referrer?: string;
    ab_tests?: TSignupDataTests;
    isFromSingleCta?: boolean;
    start_trial_variant?: string;
    dataForIntakeFormTest?: Record<string, any>;
    userData?: IUserDataForIntakeForm;
}

export interface OrganizeDataForRegisterArg {
    vendor_email?: string;
    vendor_name?: string;
    registration_variant?: 'paywall' | 'homepage' | 'homepage_facebook';
    signup_landing_page?: string;
    is_demo?: string | string[] | null;
    geo_data?: {
        city: string;
        country_name: string;
        country_code: string;
        longitude: string;
        latitude: string;
    };
    is_sso: boolean;
    auth_token?: string;
    auth_code?: string;
    profile_img_url?: string;
    sso_type?: string;
    utmCampaign?: string;
    utm_campaign?: string;
    utmContent?: string;
    utm_content?: string;
    utmMbsy?: string;
    utmMedium?: string;
    utm_medium?: string;
    utmSource?: string;
    utm_source?: string;
    utmTerm?: string;
    utm_term?: string;
    utmInitiative?: string;
    utm_initiative?: string;
    liFatId?: string;
    li_fat_id?: string;
    utmOrigin?: string;
    utm_origin?: string;
    referralSource: string;
    gclid: string;
    fbclid: string;
    irclickid: string;
    promo: string;
    placement: string;
    referrer: string;
    ab_tests: TSignupDataTests;
    isFromSingleCta?: boolean;
    start_trial_variant?: string;
    dataForIntakeFormTest?: Record<string, any>;
    userData?: IUserDataForIntakeForm;
}

class RegistrationService {
    geoLocationData?: GeoData;
    registrationVariant: 'paywall' | 'homepage' | 'homepage_facebook';

    getRegistrationData = (ssoData?: SsoData | SsoError): RegistrationData => {
        let userInitiatedData: UserInitiatedData = {} as UserInitiatedData;

        if (ssoData) {
            userInitiatedData = {
                vendor_email: (ssoData as SsoData).email,
                vendor_name:
                    (ssoData as SsoData).fullName || (ssoData as SsoData).name,
                is_sso: (ssoData as SsoData).is_sso || false,
                sso_type: (ssoData as SsoData).sso_type || '',
                auth_token: (ssoData as SsoData).auth_token || '',
                auth_code: (ssoData as SsoData).auth_code || '',
                profile_img_url: (ssoData as SsoData).profile_img_url || '',
                registration_variant:
                    (ssoData as SsoData).registration_variant ||
                    this.getRegistrationVariant(ssoData)
            };
        }
        const userABTests = UtilsService.shouldUseABTestToolV2()
            ? ABTestToolServiceV2.getUserFlatABTests()
            : ABTestToolService.getAllUserABTests();

        const initialRegistrationData: InitialRegistrationData = {
            is_demo: this.isDemoRegistration(),
            signup_landing_page:
                UtilsService.getSignupLandingPageFromLocationPath(),
            geo_data: this.geoLocationData, // || ssoData.geo_data,
            ab_tests: Object.values(userABTests),
            referrer:
                CookiesService.getCookie('referringDomain') || document.referrer
        };

        const adsData = this.getUtms(ssoData);

        return {
            ...userInitiatedData,
            ...initialRegistrationData,
            ...adsData
        };
    };

    getUtms = (ssoData?: SsoData | SsoError): AdsData => {
        // if the user signed up from a page that is different from the one he started with,
        // and the original one had utms, we want to pass them to the user.
        const storedUtms = this.getInitialStoredUtmsFromCookie();
        const queryStringData = queryString.parse(window.location.search);

        // We have three places to check for UTMs :/
        // Let's improve this:
        //
        // _getUtm = Check the ssoData object if we already have it
        // if not: check the current URL params
        // if not: check the UTM store
        function getUtm(utmKey: string) {
            return (
                (ssoData && ssoData[utmKey]) ||
                queryStringData[utmKey] ||
                storedUtms[utmKey]
            );
        }

        const otherUtms = this.getAdditionalUtms(queryStringData); 

        return {
            utmCampaign: getUtm('utm_campaign'),
            utmContent: getUtm('utm_content'),
            utmMedium: getUtm('utm_medium'),
            utmSource: getUtm('utm_source'),
            utmTerm: getUtm('utm_term'),
            utmMbsy: getUtm('code'),
            utmOrigin: getUtm('utm_origin'),
            utmInitiative: getUtm('utm_initiative'),
            liFatId: getUtm('li_fat_id'),
            referralSource: getUtm('referral_source'),
            gclid: getUtm('gclid'),
            fbclid: getUtm('fbclid'),
            irclickid: getUtm('irclickid'),
            placement: getUtm('placement'),
            promo: getUtm('promo'),
            ...otherUtms
        };
    };

    getAdditionalUtms = (queryStringData) => {
        const standardUtmKeys = new Set([
            'utm_campaign',
            'utm_content',
            'utm_medium',
            'utm_source',
            'utm_term',
            'code',
            'utm_origin',
            'utm_initiative',
            'li_fat_id',
            'referral_source',
            'gclid',
            'fbclid',
            'irclickid',
            'placement',
            'promo'
        ]);

        const otherUtms: Record<string, string> = {};

        Object.entries(queryStringData).forEach(([key, value]) => {
            // Skip if it's a standard UTM or value is empty
            if (standardUtmKeys.has(key) || !value) return;
            otherUtms[key] = value as string;
        });

        return otherUtms;
    };

    getRegistrationVariant = (ssoData?: SsoData | SsoError) => {
        if (ssoData && (ssoData as SsoData).pricingPlan) {
            this.registrationVariant = 'paywall';
        }

        const pathIsHomeOrRegister =
            window.location.pathname === '/' ||
            window.location.pathname === '/register';

        if (!this.registrationVariant && pathIsHomeOrRegister) {
            this.registrationVariant = 'homepage';
        }

        if (
            this.registrationVariant === 'homepage' &&
            ssoData &&
            (ssoData as SsoData).is_sso
        ) {
            this.registrationVariant = 'homepage_facebook';
        }

        return this.registrationVariant;
    };

    handleRegistration = async (
        ssoData: SsoData | SsoError
    ): Promise<
        RegistrationData & {
            server_response: ValidateOnboardingResponse;
            returnLocation: string;
        }
    > => {
        const registrationData = this.getRegistrationData(ssoData);
        registrationData.isFromSingleCta = false;
        registrationData.start_trial_variant = 'email-with-name';
        return this.validateDataWithServer(registrationData).then(
            async (serverResponse: ValidateOnboardingResponse) => {
                const newRegistrationData: RegistrationData & {
                    server_response: ValidateOnboardingResponse;
                    returnLocation: string;
                } = {
                    ...registrationData,
                    server_response: serverResponse,
                    returnLocation: window.location.href
                };

                return await this.parseServerResponse(newRegistrationData);
            }
        );
    };

    handleRegistrationWithoutFields = (geoData?: GeoData): RegistrationData => {
        this.geoLocationData = geoData;

        const registrationData = this.getRegistrationData();
        registrationData.isFromSingleCta = true;
        registrationData.start_trial_variant = 'single-cta';
        return registrationData;
    };

    parseServerResponse = async (
        registrationData: RegistrationData & {
            server_response: ValidateOnboardingResponse;
            returnLocation: string;
        }
    ): Promise<
        RegistrationData & {
            server_response: ValidateOnboardingResponse;
            returnLocation: string;
        }
    > => {
        const isValid = registrationData.server_response.is_valid;
        const domainNotValid =
            registrationData.server_response.domain_not_valid;
        const redirectToUrl = registrationData.server_response.redirect_to_url;

        if (domainNotValid) {
            return Promise.reject(
                new RegistrationFormError({ type: 'domainNotValid' })
            );
        }

        if (!isValid && redirectToUrl && !registrationData.is_sso) {
            return Promise.reject(
                new RegistrationFormError({
                    type: 'redirect',
                    url: redirectToUrl.slice(1)
                })
            );
        }

        return Promise.resolve(registrationData);
    };

    getInitialStoredUtmsFromCookie = () => {
        let storedUtmParams = {};

        try {
            const utms: string | undefined =
                CookiesService.getCookie('utm_params');

            if (utms) {
                storedUtmParams = JSON.parse(utms);
            }
        } catch (error) {
            trackError(error, {
                message: 'unable to get stored utms from cookie'
            });
        }

        return storedUtmParams;
    };

    handleSSOUserExist(user) {
        try {
            StorageService.setItem({
                key: 'jStorage',
                data: {
                    HB_CURR_USER: user,
                    HB_API_VERSION: user.api_version,
                    HB_AUTH_TOKEN: user.authentication_token,
                    HB_AUTH_USER_ID: user._id
                },
                shouldThrowError: true
            });
        } catch (error) {
            trackError(error, {
                message: 'ssoRegistrationDone | localStorage disabled'
            });
        }
        const data = {
            id: user._id,
            email: user.email,
            name: user.full_name
        };
        window.setTimeout(() => {
            AnalyticsService.identify(user._id, data);
            AnalyticsService.track(
                AnalyticsEvents.registration_validation_redirect,
                data
            );
            AnalyticsService.track(AnalyticsEvents.sso_user_exists, data);
            setTimeout(() => {
                window.location.assign(`${window.location.origin}/app/login`);
            }, 100);
        }, 10);
    }

    successSSO = (ssoData: SsoData | SsoError) => {
        const errorObject = (ssoData as SsoError).errorObject;
        if (errorObject) {
            trackError('Could not connect to Google', { errorObject });
            // TODO:
            //  _hideButtonLoader(_$ssoButton);
            return;
        }

        return ApiService.ssoSignupUser({
            auth_token: (ssoData as SsoData).auth_token,
            sso_type: (ssoData as SsoData).sso_type,
            auth_code: (ssoData as SsoData).auth_code,
            is_sso: (ssoData as SsoData).is_sso
        })
            .then((user?: SignupUserResponse) => {
                if (user?._id) {
                    this.handleSSOUserExist(user);
                } else {
                    AnalyticsService.track(
                        AnalyticsEvents.on_registration_form_click,
                        {
                            email: (ssoData as SsoData).email,
                            name: (ssoData as SsoData).name,
                            is_sso: (ssoData as SsoData).is_sso
                        }
                    );
                }

                return this.handleRegistration(ssoData);
            })
            .then(this.onRegistrationSuccess)
            .catch((error: Error) => {
                console.error(error);
                trackError(error, {
                    message: 'HB API sso_signup_user / POST failed'
                });
                return this.handleRegistration(ssoData).then(
                    this.onRegistrationSuccess
                );
            })
            .catch((error: Error) => {
                console.error(error);
            });
    };

    onRegistrationSuccess = (
        data?: RegistrationData & {
            server_response: ValidateOnboardingResponse;
            returnLocation: string;
        }
    ): void => {
        if (data) {
            let queryParams = '';

            let redirect = '/signup';
            const organizedData = this.organizeDataForRegister(data);

            if (data.is_sso) {
                try {
                    StorageService.setItem({
                        key: 'signupData',
                        data,
                        shouldThrowError: true
                    });

                    if (
                        data.server_response.redirect_to_url &&
                        data.server_response.redirect_to_url !== ''
                    ) {
                        window.location.assign(
                            `${data.server_response.redirect_to_url}${queryParams}`
                        );

                        return;
                    }
                } catch (ex) {
                    queryParams = `?${queryString.stringify(data)}`;
                }
            } else {
                try {
                    StorageService.setItem({
                        key: 'signupData',
                        data: organizedData,
                        shouldThrowError: true
                    });
                } catch (ex) {
                    queryParams = `?${queryString.stringify(organizedData)}`;
                }
            }

            window.location.assign(
                `${window.location.origin + redirect}${queryParams}`
            );
        }

        return;
    };

    onRegistrationSuccessWithoutFields = (data?: RegistrationData): void => {
        if (data) {
            let queryParams = '';
            let redirect = '/signup';

            const organizedData = this.organizeDataForRegister(data);

            try {
                StorageService.setItem({
                    key: 'signupData',
                    data: organizedData,
                    shouldThrowError: true
                });
                trackAction(
                    'TOF_DEBUG: StorageService.setItem',
                    {
                        signup_landing_page: data.signup_landing_page,
                    }
                );
            } catch (error) {
                queryParams = `?${queryString.stringify(organizedData)}`;
                trackError(error, {
                    message: 'unable to store signupData'
                });
            }
            if (data.signupRedirectUrl) {
                window.location.assign(data.signupRedirectUrl);
                return;
            }

            const customIntakePages = [
                {
                    url: '/client-relationships',
                    source: 'client_relationships'
                }
            ];

            const matchedCustomIntakePage = customIntakePages.find(
                page => page.url === window.location.pathname
            );

            if (matchedCustomIntakePage) {
                const urlSource = queryParams
                    ? `&utm_source=${matchedCustomIntakePage.source}`
                    : `?utm_source=${matchedCustomIntakePage.source}`;
                window.location.assign(
                    `${
                        window.location.origin + redirect
                    }${queryParams}${urlSource}`
                );
            } else {
                window.location.assign(
                    `${window.location.origin + redirect}${queryParams}`
                );
            }
        }

        AnalyticsService.track('(gatsby start trail) registration success data', {
            method: 'onRegistrationSuccessWithoutFields',
            data: data
        });

        return;
    };

    organizeDataForRegister = (
        data: OrganizeDataForRegisterArg
    ): DataForRegister => {
        const newData: DataForRegister = {};

        if (data.vendor_email) {
            newData.vendor_email = data.vendor_email;
        }

        if (data.vendor_name) {
            newData.vendor_name = data.vendor_name;
        }

        if (data.registration_variant) {
            newData.registration_variant = data.registration_variant;
        }

        if (data.signup_landing_page) {
            newData.signup_landing_page = data.signup_landing_page;
        }

        if (data.is_demo) {
            newData.is_demo = data.is_demo;
        }

        if (data.geo_data) {
            newData.city = data.geo_data.city;
            newData.country_name = data.geo_data.country_name;
            newData.country_code = data.geo_data.country_code;
            newData.longitude = data.geo_data.longitude;
            newData.latitude = data.geo_data.latitude;
        } else if (this.geoLocationData) {
            newData.city = this.geoLocationData.city;
            newData.country_name = this.geoLocationData.country_name;
            newData.country_code = this.geoLocationData.country_code;
            newData.longitude = this.geoLocationData.longitude;
            newData.latitude = this.geoLocationData.latitude;
        }

        if (data.is_sso) {
            newData.is_sso = data.is_sso;
            newData.auth_token = data.auth_token;
            newData.auth_code = data.auth_code;
            newData.profile_img_url = data.profile_img_url;
            newData.sso_type = data.sso_type;
        }

        if (data.dataForIntakeFormTest) {
            newData.dataForIntakeFormTest = data.dataForIntakeFormTest;
        }
        if (data.userData) {
            newData.userData = data.userData;
        }

        // Utms
        newData.utm_campaign = data.utmCampaign || data.utm_campaign;
        newData.utm_content = data.utmContent || data.utm_content;
        newData.utmMbsy = data.utmMbsy;
        newData.utm_medium = data.utmMedium || data.utm_medium;
        newData.utm_source = data.utmSource || data.utm_source;
        newData.utm_term = data.utmTerm || data.utm_term;
        newData.utm_origin = data.utmOrigin || data.utm_origin;
        newData.utm_initiative = data.utm_initiative || data.utmInitiative;
        newData.li_fat_id = data.liFatId || data.li_fat_id;
        newData.referralSource = data.referralSource;
        newData.gclid = data.gclid;
        newData.fbclid = data.fbclid;
        newData.irclickid = data.irclickid;
        newData.promo = data.promo;
        newData.placement = data.placement;
        newData.referrer = data.referrer;
        newData.ab_tests = data.ab_tests;
        newData.isFromSingleCta = data.isFromSingleCta;
        newData.start_trial_variant = data.start_trial_variant;

        return newData;
    };

    validateDataWithServer = (
        registrationData: RegistrationData
    ): Promise<ValidateOnboardingResponse> => {
        return ApiService.validateOnboarding(registrationData);
    };

    isDemoRegistration = (): string | string[] | null => {
        const queryStringData: queryString.ParsedQuery<string> =
            queryString.parse(window.location.search);

        return queryStringData.is_demo || queryStringData.demo;
    };
}

export default new RegistrationService();
