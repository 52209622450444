import { Trans, useTranslation } from 'react-i18next';
import FullBleedBlock from '../../FullBleedBlock/FullBleedBlock';
import ArrowDown16 from '../../../svg/navbar-icons/arrow-down-16.inline.svg';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import UtilsService from '../../../Services/UtilsService';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';

import './run-business-block.scss';

const pills = [
    {
        transKey: 'clientRelationships',
        imageUrl: 'marketing_site/icons/client-relationships.png'
    },
    {
        transKey: 'projectManagement',
        imageUrl: 'marketing_site/icons/project-management.png'
    },
    {
        transKey: 'ai',
        imageUrl: 'marketing_site/icons/AI.png'
    },
    {
        transKey: 'integrations',
        imageUrl: 'marketing_site/icons/inytegrations.png'
    },
    {
        transKey: 'finance',
        imageUrl: 'marketing_site/icons/finances.png'
    }
];

const RunBusinessBlock = () => {
    const { t } = useTranslation();

    return (
        <FullBleedBlock
            customClass="run-business"
            title={
                <Trans
                    i18nKey="runBusiness.title"
                    components={TRANSLATION_COMPONENTS}
                />
            }
            backgroundImage="https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1742247524/marketing_site/home_page/grow-business.png"
        >
            <div className="run-business__pills-container">
                {pills.map(({ transKey, imageUrl }) => (
                    <div key={transKey} className="run-business__pill">
                        <HBMediaElement
                            customClass="run-business__pill__image"
                            name={imageUrl}
                            alt={t(`runBusiness.pills.${transKey}`)}
                        />
                        <span>{t(`runBusiness.pills.${transKey}`)}</span>
                    </div>
                ))}
            </div>
            <div
                className="run-business__learn"
                onClick={() =>
                    UtilsService.scrollToElementId('feature-carousel', 55)
                }
            >
                {t('general.learnMore')}
                <ArrowDown16 />
            </div>
        </FullBleedBlock>
    );
};

export default RunBusinessBlock;
