import { memo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import HBIcon from '../../honeybook-ui/HBIcon/HBIcon';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import RegistrationFormAsText from '../../RegistrationFormAsText/RegistrationFormAsText';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Keyboard, Pagination } from 'swiper';
import type { Swiper as SwiperType } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import './feature-carousel.scss';

interface FeatureSlideProps {
    transKey: string;
    mediaUrl: string;
    iconUrl: string;
    link?: string;
}

const CustomPaginationBullet = ({
    index,
    activeIndex,
    onClick,
    text,
    imageUrl
}) => {
    return (
        <span
            className={`feature-slide__custom-bullet ${
                activeIndex === index ? 'active' : ''
            }`}
            onClick={() => onClick(index)}
        >
            <HBMediaElement name={imageUrl} />
            {text}
        </span>
    );
};

const FeatureSlide = ({
    transKey,
    mediaUrl,
    iconUrl,
    link
}: FeatureSlideProps) => {
    const { t } = useTranslation();

    return (
        <div className={`feature-slide feature-slide--${transKey}`}>
            <div className="feature-slide__media">
                <div className="feature-slide__custom-bullet feature-slide__custom-bullet--mobile">
                    <HBMediaElement name={iconUrl} />
                    {t(`featureCarousel.slides.${transKey}.eyebrow`)}
                </div>
                <div className="feature-slide__stats-container">
                    <HBText
                        tag="p"
                        type="body-l"
                        customClass="feature-slide__stat"
                    >
                        {t(`featureCarousel.slides.${transKey}.stat`)}
                    </HBText>
                    <HBText
                        tag="p"
                        type="body-l"
                        customClass="feature-slide__stat-description"
                    >
                        {t(
                            `featureCarousel.slides.${transKey}.statDescription`
                        )}
                    </HBText>
                </div>
                <HBMediaElement name={mediaUrl} />
            </div>
            <div className="feature-slide__text">
                <HBText
                    tag="p"
                    type="body-m"
                    customClass="feature-slide__eyebrow"
                >
                    {t(`featureCarousel.slides.${transKey}.eyebrow`)}
                </HBText>
                <HBText
                    tag="h3"
                    type="title-l"
                    customClass="feature-slide__title"
                >
                    {t(`featureCarousel.slides.${transKey}.title`)}
                </HBText>
                <HBText
                    tag="p"
                    type="title-s"
                    customClass="feature-slide__description"
                >
                    {t(`featureCarousel.slides.${transKey}.description`)}
                </HBText>
                {link ? (
                    <a className="feature-slide__link" href={link}>
                        {t('featureCarousel.slides.linkText')}
                        <HBIcon name="nx-arrow-right-16" />
                    </a>
                ) : (
                    <RegistrationFormAsText
                        color="dark"
                        source={`feature carousel - ${transKey}`}
                    />
                )}
            </div>
        </div>
    );
};

const FeatureCarousel = () => {
    const swiperRef = useRef<SwiperType | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const slides = [
        {
            transKey: 'slide1',
            mediaUrl: 'marketing_site/home_page/slide1.gif',
            imageUrl: 'marketing_site/icons/client-relationships.png',
            buttonText: 'Client relationships'
        },
        {
            transKey: 'slide2',
            mediaUrl: 'marketing_site/home_page/Frame02_2x_15fps.gif',
            imageUrl: 'marketing_site/icons/project-management.png',
            buttonText: 'Project management'
        },
        {
            transKey: 'slide3',
            mediaUrl: 'marketing_site/home_page/Frame03_2x_15fps.gif',
            link: '/lp/ai-start',
            imageUrl: 'marketing_site/icons/AI.png',
            buttonText: 'HoneyBook AI'
        },
        {
            transKey: 'slide4',
            mediaUrl: 'marketing_site/home_page/Frame04_2x_15fps.gif',
            imageUrl: 'marketing_site/icons/inytegrations.png',
            buttonText: 'Integrations'
        },
        {
            transKey: 'slide5',
            mediaUrl: 'marketing_site/home_page/Frame05_2x_15fps.gif',
            link: '/lp/finance',
            imageUrl: 'marketing_site/icons/finances.png',
            buttonText: 'Finances'
        }
    ];

    return (
        <div id="feature-carousel">
            <FeatureBlockSection customClass="feature-carousel">
                <FeatureBlockContent>
                    <Swiper
                        className={`feature-carousel__swiper feature-carousel__swiper--${activeIndex}`}
                        draggable
                        onSwiper={swiper => {
                            swiperRef.current = swiper;
                        }}
                        onSlideChange={swiper =>
                            setActiveIndex(swiper.activeIndex)
                        }
                        modules={[Mousewheel, Keyboard, Pagination]}
                        pagination={{
                            clickable: true
                        }}
                        centeredSlides
                        threshold={20}
                        touchMoveStopPropagation={false}
                        preventClicksPropagation={false}
                    >
                        {slides.map((slide, index) => (
                            <SwiperSlide key={index}>
                                <FeatureSlide
                                    transKey={slide.transKey}
                                    mediaUrl={slide.mediaUrl}
                                    iconUrl={slide.imageUrl}
                                    link={slide.link}
                                />
                            </SwiperSlide>
                        ))}
                        <div className="feature-carousel__custom-pagination">
                            {slides.map((button, index) => (
                                <CustomPaginationBullet
                                    key={index}
                                    index={index}
                                    activeIndex={activeIndex}
                                    onClick={index =>
                                        swiperRef.current?.slideTo(index)
                                    }
                                    text={button.buttonText}
                                    imageUrl={button.imageUrl}
                                />
                            ))}
                        </div>
                    </Swiper>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

export default memo(FeatureCarousel);
