import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import HBText from '../honeybook-ui/HBText/HBText';
import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../FeatureBlockElements';
import RegistrationForm from '../RegistrationForm/RegistrationForm';

import './ai-block.scss';

const AiBlock = () => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="ai-block">
            <FeatureBlockContent customClass="ai-block__content">
                <div className="ai-block__media-container">
                    <HBMediaElement
                        customClass="ai-block__video"
                        name="marketing_site/home_page/Frame03_2x_15fps.gif"
                    />
                    <div className="ai-block__info-bubble">
                        <HBText
                            tag="p"
                            type="title-s"
                            customClass="ai-block__info-bubble__title"
                        >
                            {t('aiBlock.info_bubble.title')}
                        </HBText>
                        <HBText
                            tag="p"
                            type="body-xs"
                            customClass="ai-block__info-bubble__subtitle"
                        >
                            {t('aiBlock.info_bubble.subtitle')}
                        </HBText>
                    </div>
                </div>
                <div className="ai-block__text-container">
                    <div className="ai-block__ai-badge">
                        <HBMediaElement
                            customClass="ai-block__ai-badge__icon"
                            name="marketing_site/icons/AI.png"
                        />
                        <HBText
                            tag="p"
                            type="body-s"
                            customClass="ai-block__ai-badge__text"
                        >
                            {t('aiBlock.ai_badge')}
                        </HBText>
                    </div>
                    <HBText
                        tag="h2"
                        type="title-l"
                        customClass="ai-block__title"
                    >
                        {t('aiBlock.title')}
                    </HBText>
                    <HBText
                        tag="p"
                        type="body-l"
                        customClass="ai-block__subtitle"
                    >
                        {t('aiBlock.subtitle')}
                    </HBText>
                    <RegistrationForm
                        buttonStyle="primary--dark"
                        source="ai-block"
                        direction="row"
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(AiBlock);
