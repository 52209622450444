import { useTranslation } from 'react-i18next';
import ImageTextElementsBlock from '../ImageTextElementsBlock/ImageTextElementsBlock';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import './built-for-teams.scss';

const imageTextElements = [
    {
        title: 'builtForTeams.imageTextElements.manage.title',
        text: 'builtForTeams.imageTextElements.manage.description',
        imagePath: 'marketing_site/home_page/teams/manage.png',
        altText: 'builtForTeams.imageTextElements.manage.title',
        mediaFlags: { forceImgTag: true }
    },
    {
        title: 'builtForTeams.imageTextElements.clients.title',
        text: 'builtForTeams.imageTextElements.clients.description',
        imagePath: 'marketing_site/home_page/teams/clients2.png',
        altText: 'builtForTeams.imageTextElements.clients.title',
        mediaFlags: { forceImgTag: true }
    },
    {
        title: 'builtForTeams.imageTextElements.performance.title',
        text: 'builtForTeams.imageTextElements.performance.description',
        imagePath: 'marketing_site/home_page/teams/performance.png',
        altText: 'builtForTeams.imageTextElements.performance.title',
        mediaFlags: { forceImgTag: true }
    }
];

const BuiltForTeamsBlock = () => {
    const { t } = useTranslation();

    return (
        <div className="built-for-teams">
            <ImageTextElementsBlock
                title={t('builtForTeams.title')}
                imageTextElements={imageTextElements}
            />
            <FeatureBlockSection>
                <FeatureBlockContent>
                    <a
                        href="/p/for-teams"
                        className="hbui-button hbui-button--medium hbui-button--primary--dark"
                    >
                        {t('builtForTeams.CTAText')}
                    </a>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

export default BuiltForTeamsBlock;
