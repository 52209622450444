import { memo, useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import QuoteIcon from '../../../svg/quote.inline.svg';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Keyboard, Pagination, Navigation, Autoplay } from 'swiper';
import ArrowIcon from '../../../svg/arrow-line.inline.svg';
import type { Swiper as SwiperType } from 'swiper';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';

import 'swiper/css';
import 'swiper/css/pagination';
import './testimonial-carousel.scss';

const testimonials = [
    {
        name: 'Dee T.',
        transKey: 'testimonialCardsBlock.testimonials.dee',
        imageUrl: 'marketing_site/home_page/dee.png'
    },
    {
        name: 'Dianuh A.',
        transKey: 'testimonialCardsBlock.testimonials.dianuh',
        imageUrl: 'marketing_site/home_page/dianuh.png'
    },
    {
        name: 'Danielle J.',
        transKey: 'testimonialCardsBlock.testimonials.danielle',
        imageUrl: 'marketing_site/home_page/danielle.png'
    }
];

const TestimonialCard = ({ quote, name, jobTitle }) => {
    return (
        <div className="testimonial-carousel__testimonial">
            <QuoteIcon className="testimonial-carousel__testimonial__quote-icon" />
            <HBText
                tag="p"
                type="body-l"
                customClass="testimonial-carousel__testimonial__quote"
            >
                {quote}
            </HBText>
            <HBText
                tag="p"
                type="body-l"
                customClass="testimonial-carousel__testimonial__name"
            >
                {name}
            </HBText>
            <HBText
                tag="p"
                type="body-l"
                customClass="testimonial-carousel__testimonial__job-title"
            >
                {jobTitle}
            </HBText>
        </div>
    );
};

const TestimonialSlide = ({ transKey, name, imageUrl }) => {
    const { t } = useTranslation();

    return (
        <div className="testimonial-carousel__testimonial-slide">
            <div className="testimonial-carousel__testimonial-slide-container">
                <TestimonialCard
                    key={transKey}
                    quote={t(`${transKey}.quote`)}
                    name={name}
                    jobTitle={t(`${transKey}.jobTitle`)}
                />
                <HBMediaElement
                    customClass="testimonial-carousel__photo"
                    name={imageUrl}
                />
            </div>
        </div>
    );
};

const FeatureCarousel = () => {
    const swiperRef = useRef<SwiperType | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideChange = (activeIndex: number) => {
        setActiveIndex(activeIndex);
    };

    return (
        <div className="testimonial-carousel-wrapper">
            <FeatureBlockSection>
                <FeatureBlockContent>
                    <HBText
                        tag="h2"
                        type="title-l"
                        customClass="testimonial-carousel__title"
                    >
                        <Trans
                            i18nKey="testimonialCarousel.title"
                            components={TRANSLATION_COMPONENTS}
                        />
                    </HBText>
                    <HBMediaElement
                        name="marketing_site/home_page/Star-teal.svg"
                        customClass="testimonial-carousel__star testimonial-carousel__star1"
                    />
                    <HBMediaElement
                        name="marketing_site/home_page/Star-teal.svg"
                        customClass="testimonial-carousel__star testimonial-carousel__star2"
                    />
                    <HBMediaElement
                        name="marketing_site/home_page/Star-pink.svg"
                        customClass="testimonial-carousel__star testimonial-carousel__star3"
                    />
                </FeatureBlockContent>
            </FeatureBlockSection>
            <FeatureBlockSection customClass="testimonial-carousel">
                <FeatureBlockContent>
                    <Swiper
                        className={`testimonial-carousel__swiper testimonial-carousel__swiper--${activeIndex}`}
                        draggable
                        onSwiper={swiper => {
                            swiperRef.current = swiper;
                        }}
                        onSlideChange={swiper =>
                            handleSlideChange(swiper.activeIndex)
                        }
                        modules={[
                            Mousewheel,
                            Navigation,
                            Keyboard,
                            Pagination,
                            Autoplay
                        ]}
                        pagination={{
                            clickable: true
                        }}
                        spaceBetween={50}
                        centeredSlides
                        threshold={20}
                        autoplay={{ delay: 4000 }}
                        preventClicksPropagation={false}
                        navigation={{
                            prevEl: '.swiper-container__prev',
                            nextEl: '.swiper-container__next'
                        }}
                    >
                        {testimonials.map(({ transKey, name, imageUrl }) => (
                            <SwiperSlide key={transKey}>
                                <TestimonialSlide
                                    transKey={transKey}
                                    name={name}
                                    imageUrl={imageUrl}
                                />
                            </SwiperSlide>
                        ))}

                        <div className="swiper-container__controls">
                            <div
                                className={`swiper-container__button swiper-container__prev ${
                                    activeIndex < 1
                                        ? 'swiper-container__button--disabled'
                                        : ''
                                }`}
                            >
                                <ArrowIcon />
                            </div>
                            <div
                                className={`swiper-container__button swiper-container__next ${
                                    activeIndex >= testimonials.length - 1
                                        ? 'swiper-container__button--disabled'
                                        : ''
                                }`}
                            >
                                <ArrowIcon />
                            </div>
                        </div>
                    </Swiper>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

export default memo(FeatureCarousel);
