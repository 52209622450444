import { memo } from 'react';
import { Helmet } from 'react-helmet';
import type { TOGTags } from '../types/util-types';
import { CAPTERRA_REVIEWS } from '../constants/constants';
import UtilsService from './UtilsService';

function OGTags({
    description = 'Book clients, manage projects, and get paid all in one place on HoneyBook. The clientflow management platform for independent businesses. Get started with a free 7-day trial.',
    image = 'https://res.cloudinary.com/honeybook/image/upload/v1742922845/marketing_site/home_page/ogTag1.png',
    url,
    title,
    noIndex,
    lang = 'en'
}: TOGTags): React.ReactElement {
    const snippetData = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: title,
        image,
        brand: {
            '@type': 'Brand',
            name: 'HoneyBook'
        },
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: CAPTERRA_REVIEWS.rating,
            bestRating: CAPTERRA_REVIEWS.bestRating,
            worstRating: CAPTERRA_REVIEWS.worstRating,
            ratingCount: CAPTERRA_REVIEWS.totalReviews
        }
    };

    return (
        <Helmet htmlAttributes={{ lang }}>
            <title>{title} | HoneyBook</title>
            <meta name="description" content={description} />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="fb:app_id" content={process.env.GATSBY_fbAppId} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={url} />
            <meta name="twitter:creator" content="@honeybook" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <script type="application/ld+json">
                {JSON.stringify(snippetData)}
            </script>
            {(noIndex || !UtilsService.isProduction()) && (
                <meta name="robots" content="noindex" />
            )}
            <link rel="canonical" href={url} />
        </Helmet>
    );
}

export default memo(OGTags);
