import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import FeatureGridBlock from '../components/FeatureBlocks/FeatureGridBlock/FeatureGridBlock';
import PictureAndListBlock from '../components/FeatureBlocks/PictureAndListBlock/PictureAndListBlock';
import ThinkOfSwitchingBlock from '../components/FeatureBlocks/ThinkOfSwitchingBlock/ThinkOfSwitchingBlock';
import TestimonialsContainer from '../components/TestimonialsContainer/TestimonialsContainer';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import VerticalTemplatesIntro from '../components/FeatureBlocks/VerticalTemplatesIntro/VerticalTemplatesIntro';
import VerticalTemplatesBlock from '../components/FeatureBlocks/VerticalTemplatesBlock/VerticalTemplatesBlock';
import VerticalEmailTemplatesBlock from '../components/FeatureBlocks/VerticalEmailTemplatesBlock/VerticalEmailTemplatesBlock';

import '../styles/dj-booking-software.scss';

const imageTextElements = [
    {
        title: 'DJPage.imageTextElements.trackLeads.title',
        text: 'DJPage.imageTextElements.trackLeads.body',
        imagePath: 'marketing_site/online-payments/Dispute.svg',
        altText: 'DJPage.imageTextElements.trackLeads.title',
        mediaFlags: { forceImgTag: true }
    },
    {
        title: 'DJPage.imageTextElements.services.title',
        text: 'DJPage.imageTextElements.services.body',
        imagePath: 'marketing_site/online-payments/secure.svg',
        altText: 'DJPage.imageTextElements.services.title',
        mediaFlags: { forceImgTag: true }
    },
    {
        title: 'DJPage.imageTextElements.faster.title',
        text: 'DJPage.imageTextElements.faster.body',
        imagePath: 'marketing_site/online-payments/cashflow.svg',
        altText: 'DJPage.imageTextElements.faster.title',
        mediaFlags: { forceImgTag: true }
    }
];

const quotes = [
    {
        image: 'marketing_site/DJPage/raymond_lopez.png',
        text: 'DJPage.quotes.Raymond.text',
        name: 'Raymond Lopez',
        description: 'DJPage.quotes.Raymond.description'
    },
    {
        image: 'marketing_site/DJPage/jennie_holzmann.png',
        text: 'DJPage.quotes.Jennie.text',
        name: 'Jennie Holzmann',
        description: 'DJPage.quotes.Jennie.description'
    },
    {
        image: 'marketing_site/DJPage/dj_rick_web.jpg',
        text: 'DJPage.quotes.Rick.text',
        name: 'DJ Rick Web',
        description: 'DJPage.quotes.Rick.description'
    }
];

const templateBlocks = [
    'invoiceTemplates',
    'contractTemplates',
    'brocureTemplates',
    'proposalTemplates',
    'questionnarieTemplates'
];

const templateFeatureImages = {
    invoiceTemplates: {
        name: 'marketing_site/vertical-lps/template-images/vertical-groups/Group%203%20-%20Events/Group_75_Copy_3_1.png'
    },
    contractTemplates: {
        name: 'marketing_site/vertical-lps/template-images/vertical-groups/Group%203%20-%20Events/Group_56_Copy.png'
    },
    brocureTemplates: {
        name: 'marketing_site/vertical-lps/template-images/vertical-groups/Group%203%20-%20Events/proposal_final_events.png'
    },
    proposalTemplates: {
        name: 'marketing_site/vertical-lps/template-images/vertical-groups/Group%203%20-%20Events/Group_77_Copy_5.png'
    },
    questionnarieTemplates: {
        name: 'marketing_site/vertical-lps/template-images/group-42_3x.png'
    }
};

const faqs = [
    {
        id: 'DJOverview',
        answer: '#TEXT# <a href="/features">#LINK_TEXT#</a> #TEXT'
    },
    {
        id: 'DJTools',
        answer: '#TEXT# <a href="/features">#LINK_TEXT#</a> #TEXT'
    },
    {
        id: 'DJInvoiceTemplate',
        answer: '#TEXT# <a href="/invoice">#LINK_TEXT#</a> #TEXT'
    },
    {
        id: 'DJContract',
        answer: '#TEXT# <a href="/online-contract">#LINK_TEXT#</a> #TEXT'
    },
    {
        id: 'DJMultiple'
    },
    {
        id: 'DJForm',
        answer: '#TEXT# <a href="https://www.youtube.com/watch?v=15J-NSzEeEs">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'DJOthers',
        answer: '#TEXT# <a href="https://www.youtube.com/watch?v=DLyvZmfktIM&t=1703s">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/watch?v=KLBrzxxIeMY"></a> #TEXT# <a href="https://www.honeybook.com/blog/dj-j-smoov-grew-business-40-percent-annually">#LINK_TEXT#</a>#TEXT#'
    },
    {
        id: 'DJVersusPlanner',
        answer: '#TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'DJVersusIntel',
        answer: '#TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT# <a href="https://play.google.com/store/apps/details?id=com.honeybook.alfred&hl=en_US">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'tutorials',
        answer: '#TEXT# <a href="https://help.honeybook.com/en/collections/1473940-training-video-tutorials">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/channel/UCxnwFDZbnNO4GCEfOBoPZSg">#LINK_TEXT#</a>#TEXT#'
    },
    {
        id: 'HBCost',
        answer: '#TEXT# <a href="/pricing">#LINK_TEXT#</a>#TEXT#'
    }
];

function DjBookingSoftwareTemplate({ pageContext: { isCtaTest } }) {
    const { t } = useTranslation();

    const ogTags = {
        url: 'https://www.honeybook.com/dj-booking-software',
        title: 'All-in-One DJ Booking Software',
        description:
            'Grow your DJ business and manage gigs from anywhere with DJ booking software. Generate leads, communicate with clients and manage payments all in one place.'
    };

    const contentKey = 'DJPage';

    return (
        <div className="dj-lp">
            <Layout ogTags={ogTags} isCtaTest={isCtaTest}>
                <WhiteHero
                    image="marketing_site/DJPage/hero-prod-edu-img_2x.jpg"
                    imageAltTag={t('DJPage.hero.imageAltTag')}
                    mainTitle={t('DJPage.hero.title')}
                    description={t('DJPage.hero.subtitle')}
                    showRegistration
                />

                <ImageTextElementsBlock
                    lightBackground
                    customClass="dj-lp__image-text-block payments-block"
                    imageTextElements={imageTextElements}
                />

                <PictureAndListBlock
                    title="DJPage.featureTitleList.trackLeads.title"
                    listName="DJPage.featureTitleList.trackLeads.list"
                    image="marketing_site/DJPage/pipeline.png"
                    imageAltTag="DJPage.featureTitleList.trackLeads.imageAltTag"
                />

                <PictureAndListBlock
                    title="DJPage.featureTitleList.communicateWithClients.title"
                    listName="DJPage.featureTitleList.communicateWithClients.list"
                    image="marketing_site/DJPage/workspace.png"
                    imageAltTag="DJPage.featureTitleList.communicateWithClients.imageAltTag"
                />

                <PictureAndListBlock
                    title="DJPage.featureTitleList.manageYourInquiries.title"
                    listName="DJPage.featureTitleList.manageYourInquiries.list"
                    image="marketing_site/DJPage/mobile.png"
                    imageAltTag="DJPage.featureTitleList.manageYourInquiries.imageAltTag"
                />

                <ThinkOfSwitchingBlock
                    title="DJPage.CTAStrip.title"
                    preTitle="DJPage.CTAStrip.preTitle"
                />

                <FeatureGridBlock
                    title={t('DJPage.featureGridTitle')}
                    lightBackground
                />

                <TestimonialsContainer
                    customClass="dj-lp__testimonial"
                    quotes={quotes}
                />

                <VerticalTemplatesIntro contentKey={contentKey} />

                {templateBlocks.map(templateBlock => (
                    <VerticalTemplatesBlock
                        key={'dj-' + templateBlock}
                        contentKey={contentKey}
                        templateBlock={templateBlock}
                        templateFeatureImages={templateFeatureImages}
                    />
                ))}

                <VerticalEmailTemplatesBlock contentKey={contentKey} />

                <FAQBlock title={t('DJPage.faqs.title')} faqs={faqs} />

                <FreeToTryBlockRebrand />
            </Layout>
        </div>
    );
}

export default DjBookingSoftwareTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "DJPage", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
