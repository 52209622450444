import { graphql } from 'gatsby';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Layout from '../components/Layout/Layout';
import HomeContentControl from '../components/Home/HomeContentControl';
import HomeContentVariant from '../components/Home/HomeContentVariant';
import { usePromoCode } from '../hooks/usePromoCode';
import { useABTestContext } from '../ab-tests/context/useABTestContext';

import '../styles/home.scss';

const ogTags = {
    url: 'https://www.honeybook.com/',
    title: 'HoneyBook | Client relationships platform for independent businesses',
    description:
        'Simplify your workflow with HoneyBook—the AI-powered platform that brings all your leads, clients, projects, and payments together in one place. Start your free 7-day trial.',
    image: 'https://res.cloudinary.com/honeybook/image/upload/v1742922845/marketing_site/home_page/ogTag1.png',
    noIndex: false
};

const featuredItems = [
    {
        imgUrl: 'marketing_site/featured_in/fortune-dark.png',
        alt: 'Fortune'
    },
    {
        imgUrl: 'marketing_site/featured_in/cnbc-dark.png',
        alt: 'CNBC'
    },
    {
        imgUrl: 'marketing_site/featured_in/fast-company-dark.png',
        alt: 'Fast Company'
    },
    {
        imgUrl: 'marketing_site/featured_in/forbes-dark.png',
        alt: 'Forbes'
    },
    {
        imgUrl: 'marketing_site/featured_in/reuters-dark.png',
        alt: 'Reuters'
    },
    {
        imgUrl: 'marketing_site/featured_in/tc-dark.png',
        alt: 'Tech Crunch'
    }
];

export const carouselSlideData = [
    {
        id: 'bray',
        imageUrl: 'marketing_site/testimonial-avatars/bray-new.png'
    },
    {
        id: 'ashley',
        imageUrl: 'marketing_site/testimonial-avatars/ashley-new.png'
    },
    {
        id: 'alexis',
        imageUrl: 'marketing_site/testimonial-avatars/alexis-new.png'
    },
    {
        id: 'pamela',
        imageUrl: 'marketing_site/testimonial-avatars/pamela-new.png'
    },
    {
        id: 'amber',
        imageUrl: 'marketing_site/testimonial-avatars/amber-new.png'
    }
];

const Home = () => {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    const stats = useMemo(
        () => [
            {
                title: <Trans>{t('homeStatsAnimation.earned.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.earned.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('homeStatsAnimation.invoices.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.invoices.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('homeStatsAnimation.members.title')}</Trans>,
                subtitle: (
                    <Trans>{t('homeStatsAnimation.members.subtitle')}</Trans>
                )
            }
        ],
        []
    );

    const promoKey = promoCode || 'default';

    const {
        variantPerTest: { home_ai_test }
    } = useABTestContext();

    const isAiHomeTestVariant = home_ai_test !== 'ai';

    return (
        <Layout ogTags={ogTags} mobileStickyCTA>
            <div className={`home ${isAiHomeTestVariant ? 'home--new' : ''}`}>
                {isAiHomeTestVariant ? (
                    <HomeContentVariant featuredItems={featuredItems} />
                ) : (
                    <HomeContentControl
                        promoKey={promoKey}
                        promoCode={promoCode}
                        stats={stats}
                        featuredItems={featuredItems}
                    />
                )}
            </div>
        </Layout>
    );
};

export default Home;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: ["common", "home", "checklistHero", "valuePropBlock"]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
