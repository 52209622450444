import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import { ButtonStyles } from '@honeybook-ui/HBButton/HBButton';
import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import RegistrationForm from '../components/RegistrationForm/RegistrationForm';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import PictureAndListBlock from '../components/FeatureBlocks/PictureAndListBlock/PictureAndListBlock';
import CTABlock from '../components/FeatureBlocks/CTABlock/CTABlock';
import WereFirstClassBlock from '../components/FeatureBlocks/WereFirstClassBlock/WereFirstClassBlock';
import WorkflowsCustomizeBlock from '../components/FeatureBlocks/WorkflowsCustomizeBlock/WorkflowsCustomizeBlock';
import WorkflowsFollowUpBlock from '../components/FeatureBlocks/WorkflowsFollowUpBlock/WorkflowsFollowUpBlock';
import TabsBlock from '../components/FeatureBlocks/TabsBlock/TabsBlock';
import CheckboxTabContent from '../components/CheckboxTabContent/CheckboxTabContent';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';

import '../styles/workflows.scss';

const faqs = [
    {
        id: 'workflowNeed'
    },
    {
        id: 'workflowLeads'
    },
    {
        id: 'workflowBusiness'
    },
    {
        id: 'workflowApproval'
    },
    {
        id: 'workflowActions'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/automations',
    title: 'Client Workflow Automation Software',
    description:
        'From first inquiry to final payment, client workflow automation software makes it easy for you to close your ideal client faster . Free for 7 days.'
};

const WorkflowsPage = ({ pageContext: { isCtaTest } }) => {
    const { t } = useTranslation();

    const tabsContent = [
        {
            name: t('workflowsPage.tabsBlock.tab1.title'),
            id: 'meet',
            panel: (
                <CheckboxTabContent bulletsString="workflowsPage.tabsBlock.tab1.bullets" />
            ),
            alt: 'test'
        },
        {
            name: t('workflowsPage.tabsBlock.tab2.title'),
            id: 'work',
            panel: (
                <CheckboxTabContent bulletsString="workflowsPage.tabsBlock.tab2.bullets" />
            )
        },
        {
            name: t('workflowsPage.tabsBlock.tab3.title'),
            id: 'impress',
            panel: (
                <CheckboxTabContent bulletsString="workflowsPage.tabsBlock.tab3.bullets" />
            )
        }
    ];

    return (
        <div className="workflows-lp">
            <Layout ogTags={ogTags} isCtaTest={isCtaTest}>
                <WhiteHero
                    mainTitle={t('workflowsPage.hero.title')}
                    description={t('workflowsPage.hero.subtitle')}
                    image="marketing_site/workflows/Hero_desktop-rebrand.png"
                    showRegistration
                />

                <ImageTextElementsBlock
                    lightBackground
                    customClass="workflows-lp__post-hero-block"
                    title={t(`workflowsPage.postHero.preTitle`)}
                    subtitle={t(`workflowsPage.postHero.title`)}
                    imageTextElements={[
                        {
                            imagePath: 'marketing_site/workflows/Rocket_2x.png',
                            mediaFlags: {
                                forceImgTag: true,
                                alt: t('workflowsPage.postHero.title'),
                                lazyLoad: false
                            }
                        }
                    ]}
                />

                <WorkflowsCustomizeBlock />

                <WorkflowsFollowUpBlock lightBackground />

                <PictureAndListBlock
                    title="workflowsPage.everyMoment.title"
                    listName="workflowsPage.everyMoment.list"
                    image="marketing_site/workflows/automation-illustration-static_2x.png"
                    imageWidth={500}
                    imageAltTag={t('workflowsPage.everyMoment.title')}
                >
                    <RegistrationForm
                        source="workflows-every-moment"
                        buttonText={t('registrationForm.getStarted')}
                        buttonStyle={ButtonStyles.PRIMARY}
                    />
                </PictureAndListBlock>

                <CTABlock
                    primary={t('workflowsPage.ctaBlock.title')}
                    lightBackground
                    showNoCcRequired
                    ctaSource="cruise_control_module"
                />

                <TabsBlock
                    title={t('workflowsPage.tabsBlock.title')}
                    tabsContent={tabsContent}
                />

                <FAQBlock title={t('workflowsPage.faqTitle')} faqs={faqs} />

                <WereFirstClassBlock />
            </Layout>
        </div>
    );
};

export default WorkflowsPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "workflows", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
