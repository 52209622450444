import UtilsService from "../Services/UtilsService";

export function trackAction(
    action: string,
    context: { [key: string]: any } = {}
) {
    if (UtilsService.isProduction()) {
        const datadog = window?.DD_RUM;

        if (!datadog || !datadog.addAction) {
            return;
        }
        datadog.addAction(action, context);
    }
}
