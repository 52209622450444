import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import PricingCard from '../../PricingCard/PricingCard';
import ToggleSelector from '../../ToggleSelector/ToggleSelector';
import SwiperWithTabs from '../../SwiperWithTabs/SwiperWithTabs';
import MoneyBackGuarantee from '../../MoneyBackGuarantee/MoneyBackGuarantee';
import HBText from '../../honeybook-ui/HBText/HBText';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { usePromoCode } from '../../../hooks/usePromoCode';
import type { TPlansForDisplay, TTabsContent } from '../../../types/util-types';
import UtilsService from '../../../Services/UtilsService';
import useReferral from '../../../hooks/useReferral';

import './pricing-block.scss';

interface Props {
    title?: string;
    subtitle?: string;
    togglePricing: () => void;
    isPriceMonthly: boolean;
    plans: TPlansForDisplay[];
    defaultToggleIdx: number;
}

function PricingBlock({
    title,
    subtitle,
    togglePricing,
    isPriceMonthly,
    plans,
    defaultToggleIdx
}: Props) {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();
    const breakpoints = useBreakpoint();
    const [selectedTooltipId, setSelectedTooltipId] = useState('');
    const { referralData } = useReferral();

    const referralDiscount = UtilsService.convertDiscountStringToNumber(
        referralData?.discountAmount
    );

    const toggleButtons = [
        {
            id: 'monthly',
            text: t(`${promoCode}.pricingBlock.pricingToggle.monthly`)
        },
        {
            id: 'yearly',
            text: t(`${promoCode}.pricingBlock.pricingToggle.annually`)
        }
    ];

    useEffect(() => {
        const PRICING_HASH = '#pricing';
        const PRICING_SELECTOR = '.pricing-block';
        let timeout;

        if (
            window.location.hash === PRICING_HASH &&
            document.querySelector(PRICING_SELECTOR)
        ) {
            timeout = window.setTimeout(() => {
                const element = document.querySelector(PRICING_SELECTOR);
                element?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end'
                });
            }, 1000);
        }
        return () => {
            window.clearTimeout(timeout);
        };
    });

    const tabsContent: TTabsContent[] = useMemo(
        () =>
            plans.map(
                ({
                    plan,
                    originalPrice,
                    priceAfterDiscount,
                    discountPercentage
                }) => {
                    const planPrice = referralDiscount
                        ? UtilsService.getPriceAfterDiscount(
                              originalPrice,
                              referralDiscount
                          )
                        : priceAfterDiscount;

                    const planDiscountPercentage =
                        referralDiscount || discountPercentage;

                    return {
                        name: t(`pricingTabs.${plan}`),
                        id: plan,
                        panel: (
                            <PricingCard
                                key={plan}
                                plan={plan}
                                originalPrice={originalPrice}
                                priceAfterDiscount={planPrice}
                                isMonthly={isPriceMonthly}
                                popular={plan === 'essentials'}
                                discountPercentage={planDiscountPercentage}
                                selectedTooltipId={selectedTooltipId}
                                setSelectedTooltipId={setSelectedTooltipId}
                            />
                        )
                    };
                }
            ),
        [plans, isPriceMonthly, selectedTooltipId]
    );

    return (
        <div className="pricing-block">
            <FeatureBlockSection>
                <FeatureBlockContent>
                    {title && (
                        <HBText
                            customClass="pricing-block__title"
                            tag="h2"
                            type="title-m"
                        >
                            {title}
                        </HBText>
                    )}
                    {subtitle && (
                        <HBText
                            customClass="pricing-block__subtitle"
                            tag="h2"
                            type="body-m"
                        >
                            {subtitle}
                        </HBText>
                    )}
                </FeatureBlockContent>
            </FeatureBlockSection>
            <FeatureBlockSection customClass="pricing-block__container">
                <FeatureBlockContent>
                    <ToggleSelector
                        buttons={toggleButtons}
                        handleToggle={togglePricing}
                        defaultSelectedId={
                            toggleButtons[defaultToggleIdx]?.id || 1
                        }
                    />

                    {breakpoints.large ? (
                        <SwiperWithTabs
                            tabsContent={tabsContent}
                            customClass="pricing-block__swiper-with-tabs"
                            cb={() => setSelectedTooltipId('')}
                        />
                    ) : (
                        <div className="pricing-block__body">
                            {tabsContent.map(({ panel }) => panel)}
                        </div>
                    )}

                    <MoneyBackGuarantee />

                    <p className="pricing-block__legal">
                        {t('featureBlock.pricing.legal') as string}
                    </p>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
}

export default memo(PricingBlock);
