import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import HBText from '@honeybook-ui/HBText/HBText';
import HBButton, { TButtonStyle } from '@honeybook-ui/HBButton/HBButton';
import UtilsService from '../../Services/UtilsService';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import { TAX_TOOL_RESULT_CTA_CLASS_NAME } from '../TaxCalculatorResults/TaxCalculatorResults';

import './email-form.scss';

interface Props {
    customClass?: string;
    onFormSubmit: (email: string, isChecked: boolean) => void;
    showAgreement?: boolean;
    buttonText?: string;
    buttonTextMobile?: string;
    buttonStyle?: TButtonStyle;
    placeholder?: string;
    errorText?: string;
    useRegistrationForm?: boolean;
}

const EmailForm = ({
    customClass,
    onFormSubmit,
    showAgreement = false,
    buttonText,
    buttonStyle,
    placeholder = '',
    errorText,
    useRegistrationForm
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(true);
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [hasEmailSent, setHasEmailSent] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    const handleFormSubmit = () => {
        const email = inputRef.current?.value!;

        const isValidEmail = UtilsService.isEmailValid(email);
        setIsEmailInvalid(!isValidEmail);

        if (isValidEmail) {
            onFormSubmit(email, isChecked);

            setHasEmailSent(true);
            setTimeout(() => {
                setHasEmailSent(false);
            }, 2000);
        }
    };

    const handleInputTyping = () => {
        setIsEmailInvalid(false);
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleFormSubmit();
        }
    };

    const ctaText = buttonText || t('emailForm.buttonText');

    return (
        <div className={`email-form ${customClass}`} onKeyDown={handleKeyDown}>
            <div className="email-form__container">
                <input
                    ref={inputRef}
                    className="email-form__input"
                    type="email"
                    required
                    onChange={handleInputTyping}
                    placeholder={placeholder || t('emailForm.placeholder')}
                />
                {useRegistrationForm ? (
                    <RegistrationForm
                        source="tax-tool-cta"
                        customClass={TAX_TOOL_RESULT_CTA_CLASS_NAME}
                        dataForIntakeFormTest={{
                            taxToolData: { email: inputRef.current?.value }
                        }}
                        buttonText={
                            hasEmailSent ? t('emailForm.sent') : ctaText
                        }
                    />
                ) : (
                    <HBButton
                        customClass="email-form__button"
                        buttonStyle={buttonStyle}
                        text={hasEmailSent ? t('emailForm.sent') : ctaText}
                        onClick={handleFormSubmit}
                    />
                )}
            </div>
            {isEmailInvalid && (
                <HBText customClass="email-form__error" tag="p" type="body-xs">
                    {errorText || t('emailForm.invalidEmail')}
                </HBText>
            )}
            {showAgreement && (
                <div className="email-form__agreement">
                    <input
                        name="email-checkbox"
                        type="checkbox"
                        className="email-form__agreement__checkbox"
                        checked={isChecked}
                        onChange={toggleCheckbox}
                    />
                    <label
                        className="email-form__agreement__label"
                        htmlFor="email-checkbox"
                    >
                        {t('emailForm.agree')}
                    </label>
                </div>
            )}
        </div>
    );
};

export default EmailForm;
