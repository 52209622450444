import {
    MAP_OF_PROMO_CODES_CONFIG,
    DEFAULT_PROMO_CODE,
    Coupons
} from './../constants/promoCodes';
import UtilsService from '../Services/UtilsService';
import { useABTestContext } from '../ab-tests/context/useABTestContext';
import { useMemo } from 'react';

export const usePromoCode = () => {
    const {
        variantPerTest: { jan25_promo: jan25PromoVariant }
    } = useABTestContext();
    const isJan25Promo = jan25PromoVariant === 'jan25';

    const userPromoCode = useMemo(() => {
        if (isJan25Promo) {
            return Coupons.jan25;
        }
        return (
            UtilsService.getCouponFromQueryParams() ||
            (UtilsService.isBrowser() && UtilsService.getCouponFromCookies()) ||
            undefined
        );
    }, [
        isJan25Promo,
        UtilsService.getCouponFromQueryParams,
        UtilsService.isBrowser,
        UtilsService.getCouponFromCookies
    ]);

    const isUserPromoCodeValid =
        !!userPromoCode && !!MAP_OF_PROMO_CODES_CONFIG[userPromoCode];

    const promoCode = isUserPromoCodeValid ? userPromoCode : DEFAULT_PROMO_CODE;

    return {
        promoCode
    };
};
