import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import RegistrationForm from '../components/RegistrationForm/RegistrationForm';
import ContractTemplateHero from '../components/Hero/ContractTemplateHero/ContractTemplateHero';
import ContractTemplatePostHero from '../components/FeatureBlocks/ContractTemplatePostHero/ContractTemplatePostHero';
import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../components/FeatureBlockElements';
import PictureAndTextBlock from '../components/FeatureBlocks/PictureAndTextBlock/PictureAndTextBlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import CTABlock from '../components/FeatureBlocks/CTABlock/CTABlock';
import InvoiceTemplatesBlock from '../components/FeatureBlocks/InvoiceTemplatesBlock/InvoiceTemplatesBlock';
import NumberTextElement from '../components/NumberTextElement/NumberTextElement';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import PictureAndTextColumnBlock from '../components/FeatureBlocks/PictureAndTextColumnBlock/PictureAndTextColumnBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';

import '../styles/contract-template.scss';

const data = {
    photography: {
        ogTags: {
            url: 'https://www.honeybook.com/photography-contract-templates',
            title: `Free Photography Contract Template Collection`,
            description: `Create and customize a photography contract template from HoneyBook’s collection. Send professional contracts that get signed faster.`
        },
        hero: {
            image: 'marketing_site/photography-contract/Hero/Hero_image.png'
        },
        postHero: {
            image: 'marketing_site/photography-contract/Module%201/module_1_image.png'
        },
        bestSoftwareBlock: {
            image: 'marketing_site/photography-contract/sec-02-img-all_2x.png',
            ctaLink: '/crm-for-photographers',
            imageAlt: 'The Best Software for Photography Contracts'
        },
        sampleContractBlock: {
            image: 'marketing_site/photography-contract/Module%205/module_5_together_2x.png',
            mobileImage:
                'marketing_site/photography-contract/Module%205/module-5-mobile.png',
            imageAlt: 'Photography contract examples'
        },
        invoiceIds: [
            'photoGeneralContract',
            'servicesAgreement',
            'photoShootContract',
            'photoReleaseContract',
            'photoEventContract'
        ],
        howToFeaturesBlock: [
            {
                title: 'contractTemplatePage.photography.imageTextElements.choose.title',
                text: 'contractTemplatePage.photography.imageTextElements.choose.body',
                imagePath:
                    'marketing_site/photography-contract/choose_illustration.svg',
                altText:
                    'contractTemplatePage.photography.imageTextElements.choose.title',
                mediaFlags: { forceImgTag: true, width: 130 }
            },
            {
                title: 'contractTemplatePage.photography.imageTextElements.populate.title',
                text: 'contractTemplatePage.photography.imageTextElements.populate.body',
                imagePath:
                    'marketing_site/photography-contract/populate_illustration.svg',
                altText:
                    'contractTemplatePage.photography.imageTextElements.populate.title',
                mediaFlags: { forceImgTag: true, width: 130 }
            },
            {
                title: 'contractTemplatePage.photography.imageTextElements.share.title',
                text: 'contractTemplatePage.photography.imageTextElements.share.body',
                imagePath:
                    'marketing_site/photography-contract/share_illustration.svg',
                altText:
                    'contractTemplatePage.photography.imageTextElements.share.title',
                mediaFlags: { forceImgTag: true, width: 130 }
            }
        ],
        snagBlock: {
            image: 'marketing_site/photography-contract/Module%203/module_3_image.png'
        },
        personalizeBlock: {
            image: 'marketing_site/photography-contract/Module%208/Module_8_image.png',
            imageAlt: 'Personalize a General Photography Contract'
        },
        featuresBlock: {
            image: 'marketing_site/photography-contract/Module%209/module_9_image_2x.png',
            imageAlt: 'HoneyBook’s Simple Photography Contract Features',
            textBlocks: [
                {
                    title: 'contractTemplatePage.photography.features.freeTemplates.title',
                    body: 'contractTemplatePage.photography.features.freeTemplates.body'
                },
                {
                    title: 'contractTemplatePage.photography.features.customExperience.title',
                    body: 'contractTemplatePage.photography.features.customExperience.body'
                },
                {
                    title: 'contractTemplatePage.photography.features.simplify.title',
                    body: 'contractTemplatePage.photography.features.simplify.body'
                },
                {
                    title: 'contractTemplatePage.photography.features.smartFields.title',
                    body: 'contractTemplatePage.photography.features.smartFields.body'
                }
            ]
        },
        faqs: [
            {
                id: 'photoContractNeed',
                answer: '#TEXT# <a href="/free-invoice-templates/photographers">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'photoContractHowTo'
            },
            {
                id: 'photoContractWhy'
            },
            {
                id: 'photoContractStart'
            },
            {
                id: 'photoContractFee'
            },
            {
                id: 'photoContractPaid'
            },
            {
                id: 'photoContractNecessary'
            }
        ]
    },
    consultants: {
        ogTags: {
            url: 'https://www.honeybook.com/freelance-consulting-contract-template',
            title: 'Professional Freelance Consulting Contract Templates',
            description:
                'Build trust and get paid faster by using a freelance consulting contract template. Explore our customizable collection to meet your business needs.'
        },
        hero: {
            image: 'marketing_site/contract-templates/Consultants/Hero/Hero_image.png'
        },
        postHero: {
            image: 'marketing_site/contract-templates/Consultants/Module%201/module_1_image.png'
        },
        bestSoftwareBlock: {
            image: 'marketing_site/contract-templates/Consultants/Module%204/module_4_image.png',
            ctaLink: '/crm-for-consultants'
        },
        sampleContractBlock: {
            image: 'marketing_site/contract-templates/Consultants/Module%206/module_6_together_2x.png',
            mobileImage:
                'marketing_site/contract-templates/Consultants/Module%206/module_6_mweb_2x.png'
        },
        invoiceIds: ['consultantIndyContract', 'consultantGeneralContract'],
        howToFeaturesBlock: [
            {
                title: 'contractTemplatePage.consultants.imageTextElements.choose.title',
                text: 'contractTemplatePage.consultants.imageTextElements.choose.body',
                imagePath:
                    'marketing_site/photography-contract/choose_illustration.svg',
                altText:
                    'contractTemplatePage.consultants.imageTextElements.choose.title',
                mediaFlags: { forceImgTag: true, width: 130 }
            },
            {
                title: 'contractTemplatePage.consultants.imageTextElements.terms.title',
                text: 'contractTemplatePage.consultants.imageTextElements.terms.body',
                imagePath:
                    'marketing_site/photography-contract/populate_illustration.svg',
                altText:
                    'contractTemplatePage.consultants.imageTextElements.terms.title',
                mediaFlags: { forceImgTag: true, width: 130 }
            },
            {
                title: 'contractTemplatePage.consultants.imageTextElements.share.title',
                text: 'contractTemplatePage.consultants.imageTextElements.share.body',
                imagePath:
                    'marketing_site/photography-contract/share_illustration.svg',
                altText:
                    'contractTemplatePage.consultants.imageTextElements.share.title',
                mediaFlags: { forceImgTag: true, width: 130 }
            }
        ],
        snagBlock: {
            image: 'marketing_site/contract-templates/Consultants/Module%203/module_3_image.png',
            textBlocks: [
                {
                    title: 'contractTemplatePage.consultants.snag.graphicDesign.title',
                    link: 'https://www.honeybook.com/app/template-gallery?preview=62bc1f3d1f85e6e683c5ad23&search=graphic%20design',
                    body: 'contractTemplatePage.consultants.snag.graphicDesign.body'
                },
                {
                    title: 'contractTemplatePage.consultants.snag.socialMedia.title',
                    link: 'https://www.honeybook.com/app/template-gallery?preview=6626a31c1a59d300251f82ca&search=social%20media',
                    body: 'contractTemplatePage.consultants.snag.socialMedia.body'
                },
                {
                    title: 'contractTemplatePage.consultants.snag.marketing.title',
                    link: 'https://www.honeybook.com/app/template-gallery?preview=63ac877ac4beb80ac250856f&search=consultant',
                    body: 'contractTemplatePage.consultants.snag.marketing.body'
                },
                {
                    title: 'contractTemplatePage.consultants.snag.business.title',
                    link: 'https://www.honeybook.com/app/template-gallery?preview=636eb370c6426f11f7123471&search=business%20consultant',
                    body: 'contractTemplatePage.consultants.snag.business.body'
                },
                {
                    title: 'contractTemplatePage.consultants.snag.copywriter.title',
                    link: 'https://www.honeybook.com/app/template-gallery?search=copywriter',
                    body: 'contractTemplatePage.consultants.snag.copywriter.body'
                }
            ]
        },
        personalizeBlock: {
            image: 'marketing_site/contract-templates/Consultants/Module%208/Module_8_image.png'
        },
        featuresBlock: {
            image: 'marketing_site/contract-templates/Consultants/Module%209/Module_9_image.png',
            textBlocks: [
                {
                    title: 'contractTemplatePage.consultants.features.freeTemplates.title',
                    body: 'contractTemplatePage.consultants.features.freeTemplates.body'
                },
                {
                    title: 'contractTemplatePage.consultants.features.customExperience.title',
                    body: 'contractTemplatePage.consultants.features.customExperience.body'
                },
                {
                    title: 'contractTemplatePage.consultants.features.simplify.title',
                    body: 'contractTemplatePage.consultants.features.simplify.body'
                },
                {
                    title: 'contractTemplatePage.consultants.features.smartFields.title',
                    body: 'contractTemplatePage.consultants.features.smartFields.body'
                },
                {
                    title: 'contractTemplatePage.consultants.features.eSignatures.title',
                    body: 'contractTemplatePage.consultants.features.eSignatures.body'
                }
            ]
        },
        faqs: [
            {
                id: 'consultantContractIncluded'
            },
            {
                id: 'consultantContractWriting'
            },
            {
                id: 'consultantContractOwn'
            },
            {
                id: 'consultantContractStartingOut'
            },
            {
                id: 'consultantContractFee'
            },
            {
                id: 'consultantContractPaymentTime'
            },
            {
                id: 'consultantContractNecessary'
            },
            {
                id: 'consultantDontHave'
            }
        ]
    }
};

const handleBestSoftwareClick = () => {
    AnalyticsService.trackClick(AnalyticsEvents.contracts_link_to_vertical, {
        source: 'contract templates'
    });
};

interface Props {
    pageContext: {
        contentKey: string;
        isCtaTest: boolean;
    };
}

// TODO: use Layout component here?
function ContractTemplatePage({
    pageContext: { contentKey, isCtaTest }
}: Props): JSX.Element {
    const { t } = useTranslation();

    const {
        ogTags,
        hero,
        postHero,
        invoiceIds,
        snagBlock,
        bestSoftwareBlock,
        sampleContractBlock,
        howToFeaturesBlock,
        personalizeBlock,
        featuresBlock,
        faqs
    } = data[contentKey];

    const tips = useMemo(
        () =>
            Object.keys(
                t(`contractTemplatePage.${contentKey}.tipsModule.tips`, {
                    returnObjects: true
                })
            ),
        [contentKey]
    );

    return (
        <Layout ogTags={ogTags} isCtaTest={isCtaTest}>
            <div className={'contract-template-lp'}>
                <div className="contract-template-lp__hero-container">
                    <ContractTemplateHero
                        contentKey={contentKey}
                        image={hero.image}
                    />
                    <ContractTemplatePostHero
                        contentKey={contentKey}
                        imageUrl={postHero.image}
                    />
                </div>

                <div
                    id="contract-template-lp__templates-block-container"
                    className="contract-template-lp__templates-block-container"
                >
                    <InvoiceTemplatesBlock
                        customClass="contract-template-lp__templates-block"
                        title={t(
                            `contractTemplatePage.${contentKey}.freeContracts.title`
                        )}
                        subtitle={t(
                            `contractTemplatePage.${contentKey}.freeContracts.subtitle`
                        )}
                        invoices={invoiceIds}
                        templateTypeId="contract"
                        hasGatedDownload
                    />
                </div>

                <PictureAndTextColumnBlock
                    customClass="contract-template-lp__snag"
                    title={t(`contractTemplatePage.${contentKey}.snag.title`)}
                    description={t(
                        `contractTemplatePage.${contentKey}.snag.intro`
                    )}
                    textBlocks={snagBlock.textBlocks}
                    image={snagBlock.image}
                    imageAlt={t(
                        `contractTemplatePage.${contentKey}.snag.imageAlt`
                    )}
                    crop="scale"
                    width={800}
                >
                    <RegistrationForm
                        source=""
                        buttonText={t(
                            `contractTemplatePage.${contentKey}.snag.ctaText`
                        )}
                    />
                </PictureAndTextColumnBlock>

                <PictureAndTextBlock
                    customClass="contract-template-lp__best-software"
                    lightBackground
                    primary={t(
                        `contractTemplatePage.${contentKey}.bestSoftware.title`
                    )}
                    secondary={t(
                        `contractTemplatePage.${contentKey}.bestSoftware.body`
                    )}
                    image={bestSoftwareBlock.image}
                    imageAltTag={bestSoftwareBlock.imageAlt}
                >
                    <a
                        href={bestSoftwareBlock.ctaLink}
                        className="hbui-button hbui-button--medium hbui-button--primary"
                        target="_blank"
                        onClick={handleBestSoftwareClick}
                    >
                        {t(
                            `contractTemplatePage.${contentKey}.bestSoftware.ctaText`
                        )}
                    </a>
                </PictureAndTextBlock>

                <CTABlock
                    customClass="contract-template-lp__cta-block"
                    primary={t(
                        `contractTemplatePage.${contentKey}.ctaBlock.title`
                    )}
                    imageUrl="marketing_site/photography-contract/Module%204/contracts.png"
                    mediaFlags={{ forceImgTag: true }}
                    buttonText={t(
                        `contractTemplatePage.${contentKey}.ctaBlock.ctaText`
                    )}
                    ctaSource="accept esignature"
                    showNoCcRequired
                />

                <PictureAndTextBlock
                    customClass="contract-template-lp__sample-contract"
                    lightBackground
                    primary={t(
                        `contractTemplatePage.${contentKey}.sampleContract.title`
                    )}
                    secondary={t(
                        `contractTemplatePage.${contentKey}.sampleContract.body`
                    )}
                    image={sampleContractBlock.image}
                    imageMobile={sampleContractBlock.mobileImage}
                    imageAltTag={sampleContractBlock.imageAlt}
                />

                <ImageTextElementsBlock
                    customClass="contract-template-lp__how-to"
                    title={t(
                        `contractTemplatePage.${contentKey}.imageTextElements.title`
                    )}
                    bodyText={t(
                        `contractTemplatePage.${contentKey}.imageTextElements.description`
                    )}
                    imageTextElements={howToFeaturesBlock}
                />

                <FeatureBlockSection
                    customClass="contract-template-lp__tips-block"
                    lightBackground
                >
                    <FeatureBlockContent>
                        <FeatureBlockText
                            fullWidth
                            primary={t(
                                `contractTemplatePage.${contentKey}.tipsModule.title`
                            )}
                            bodyText={t(
                                `contractTemplatePage.${contentKey}.tipsModule.subtitle`
                            )}
                        />

                        <div className="tips-block__container">
                            {tips.map((tip, index) => (
                                <NumberTextElement
                                    number={index + 1}
                                    key={tip}
                                    description={`contractTemplatePage.${contentKey}.tipsModule.tips.${tip}.body`}
                                />
                            ))}
                        </div>
                    </FeatureBlockContent>
                </FeatureBlockSection>

                <PictureAndTextBlock
                    customClass="contract-template-lp__personalize"
                    primary={t(
                        `contractTemplatePage.${contentKey}.personalize.title`
                    )}
                    secondary={t(
                        `contractTemplatePage.${contentKey}.personalize.body`
                    )}
                    image={personalizeBlock.image}
                    imageAltTag={personalizeBlock.imageAlt}
                />

                <PictureAndTextColumnBlock
                    customClass="contract-template-lp__features"
                    lightBackground
                    title={t(
                        `contractTemplatePage.${contentKey}.features.title`
                    )}
                    contentKey={contentKey}
                    textBlocks={featuresBlock.textBlocks}
                    image={featuresBlock.image}
                    imageAlt={featuresBlock.imageAlt}
                    width={800}
                />

                <FAQBlock
                    title={t(`contractTemplatePage.${contentKey}.faqTitle`)}
                    faqs={faqs}
                />

                <FreeToTryBlockRebrand />
            </div>
        </Layout>
    );
}

export default ContractTemplatePage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: [
                        "common"
                        "contractTemplate"
                        "templateGallery"
                        "faqs"
                    ]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
