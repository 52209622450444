"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.testsConfig = exports.AB_TESTS_CONFIG = void 0;
exports.AB_TESTS_CONFIG = {
    test_manual_exposure: {
        variants: {
            control: { ab_test_variation: 'control' },
            test: {
                ab_test_variation: 'test'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/']
    },
    paid_webflow: {
        variants: {
            control: { ab_test_variation: 'control' },
            webflow: {
                ab_test_variation: 'webflow'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: false,
        pages: ['/marketing-consultant-software'],
        webflowPath: '/p/marketing-consultant-software'
    },
    adobe_abc_test: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            adobeB: {
                ab_test_variation: 'adobeB'
            },
            adobeC: {
                ab_test_variation: 'adobeC'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/adobe']
    },
    referral_recipient_experience: {
        variants: {
            control: { ab_test_variation: 'control' },
            recipient: {
                ab_test_variation: 'recipient',
                redirectPath: '/signup'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: false,
        pages: ['/invites']
    },
    client_relationships_hero_test: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            test: {
                ab_test_variation: 'test'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/client-relationships']
    },
    jan25_promo: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            jan25: { ab_test_variation: 'jan25' }
        },
        controlVariantName: 'control',
        shouldAffectBuild: false
    },
    teams_test: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            teams: { ab_test_variation: 'teams' }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true
    },
    referral_landing_page: {
        variants: {
            control: { ab_test_variation: 'control' },
            landing_page: {
                ab_test_variation: 'landing_page',
                redirectPath: '/signup'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: false,
        pages: ['/invites']
    },
    home_ai_test: {
        variants: {
            control: { ab_test_variation: 'control' },
            ai: {
                ab_test_variation: 'ai'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/']
    }
};
exports.testsConfig = {
    ...exports.AB_TESTS_CONFIG
};
