import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import HomeHero from '../Hero/HomeHero/HomeHero';
import RunBusinessBlock from '../FeatureBlocks/RunBusinessBlock/RunBusinessBlock';
import FeatureCarousel from '../FeatureBlockElements/FeatureCarousel/FeatureCarousel';
import TrustedBlock from '../FeatureBlocks/TrustedBlock/TrustedBlock';
import TestimonialCarousel from '../FeatureBlocks/TestimonialCarousel/TestimonialCarousel';
import FullBleedBlock from '../FullBleedBlock/FullBleedBlock';
import TeamsBlock from '../FeatureBlocks/TeamsBlock/TeamsBlock';
import FeaturedIn from '../FeaturedIn/FeaturedIn';
import KeepExploringBlock from '../FeatureBlocks/KeepExploringBlock/KeepExploringBlock';
import PromoBlockLeft from '../FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import { TRANSLATION_COMPONENTS } from '../../constants/constants';
import StarRatingBlock from '../FeatureBlocks/StarRatingBlock/StarRatingBlock';

interface Props {
    featuredItems: {
        imgUrl: string;
        alt: string;
    }[];
}
const HomeContentVariant = ({ featuredItems }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <HomeHero />
            <RunBusinessBlock />
            <FeatureCarousel />
            <TrustedBlock />
            <TestimonialCarousel />
            <StarRatingBlock />
            <FullBleedBlock
                customClass="home__make-living"
                title={
                    <Trans
                        i18nKey="makeLiving.title"
                        components={TRANSLATION_COMPONENTS}
                    />
                }
                backgroundImage="https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1742247794/marketing_site/home_page/make-living.png"
            />
            <TeamsBlock />
            <FeaturedIn featuredItems={featuredItems} />
            <KeepExploringBlock />
            <PromoBlockLeft
                title={t('PromoBlockLeftYellow.title')}
                customClass="promo-block-left--yellow"
                source="promo block left - yellow"
                buttonStyle="primary--dark"
            />
        </>
    );
};

export default memo(HomeContentVariant);
