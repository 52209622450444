import React from 'react';
/* eslint-disable */
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../components/FeatureBlockElements';

import './tab-content.scss';
import CookieSettingsButton from '../../components/CookieSettingsPreferences/CookieSettingsButton';

const PrivacyTabContent = () => {
    return (
        <FeatureBlockSection customClass="tos-tab-content tab-content">
            <FeatureBlockContent fullWidth>
                <div id="privacyPage" className="terms-text">
                    <p className="date-revision">
                        Updated March 20th, 2025
                        <br />
                        (Review the previous version{' '}
                        <a className="link-act" href="/terms/privacy-original">
                            here
                        </a>
                        )
                    </p>

                    <div className="terms-paragraph">
                        <p>
                            <b className="terms-marker">
                                Notice of Updated Terms of Service and Privacy
                                Policy
                            </b>
                            <br />
                            We have updated our Terms of Service and Privacy
                            Policy. These updated terms are effective for new
                            users of our Services as of March 20, 2025, and for
                            existing users of our Services as of April 22, 2025.
                            By continuing to use our Services after the
                            applicable effective date, you agree to the updated
                            Terms of Service and Privacy Policy.
                        </p>
                        <br />
                        <p>
                            This Privacy Policy describes how HoneyBook, Inc.
                            (“HoneyBook,” “we”, “us”, or “our”) handles personal
                            information that we collect through our website and
                            mobile applications ( the “Services”). The Services
                            are designed to help our customers manage their
                            businesses (“Businesses”).
                        </p>
                        <p>
                            We reserve the right to modify this Privacy Policy
                            at any time. If we make material changes to this
                            Privacy Policy, we will notify you by updating the
                            date of this Privacy Policy and posting it on our
                            website and/or other Services, or as required by
                            law.
                        </p>
                        <h2 className="terms-title">
                            Personal Information We Collect
                        </h2>

                        <p>
                            <b className="terms-marker">
                                Information you provide to us:
                            </b>{' '}
                            Personal information you may provide to us through
                            the Services includes:
                        </p>

                        <p>
                            <b className="terms-marker">
                                Identification information:
                            </b>{' '}
                            including your name, email address, phone number,
                            company name, date of birth, signature, and photos
                            of your driver’s license and/or passport.
                        </p>

                        <p>
                            <b className="terms-marker">Communications:</b> when
                            you contact us with questions, feedback, respond to
                            one of our surveys, or otherwise communicate with us
                            we may collect the information in such
                            communications.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Payment and transactional information:
                            </b>{' '}
                            we collect information needed to process your orders
                            or transmit direct deposits to you, including tax
                            ID/Employer Identification Number (“EIN”), last four
                            digits of your Social Security Number (“SSN”),
                            payment card information, bank account number and
                            related information, billing information, legal
                            business name, doing business as name, business
                            address, personal address, date of birth, job title,
                            and transaction history.
                            <ul className="terms-items">
                                <li>
                                    <b>Plaid:</b> HoneyBook also collects bank
                                    account numbers to transmit direct deposits
                                    to Businesses. If you elect to link your
                                    bank account automatically through the API
                                    provided by Plaid Inc. (“Plaid”), HoneyBook
                                    will also receive information related to the
                                    transactions associated with that account,
                                    including the transaction ID, transaction
                                    category and type, merchant name, amount,
                                    currency, date, and location. By using the
                                    Plaid API, you grant HoneyBook and Plaid the
                                    right to access and transmit your personal
                                    and financial information from the relevant
                                    financial institution and you agree that
                                    your personal and financial information will
                                    be transferred, stored, and processed by
                                    Plaid in accordance with{' '}
                                    <a href="https://plaid.com/legal/">
                                        Plaid Privacy Policy
                                    </a>
                                    .
                                </li>
                            </ul>
                        </p>

                        <p>
                            <b className="terms-marker">
                                Marketing information:
                            </b>{' '}
                            such as your preferences for receiving our marketing
                            communications, and details about your engagement
                            with them, which may be collected using cookies or
                            web beacons.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Third party account information:
                            </b>{' '}
                            if you choose to connect third party accounts to the
                            Services, we may collect information associated with
                            your third party account such as the name, email
                            address, and profile information you provided to the
                            relevant third party. In particular if you are a
                            Business and you opt to import or sync your Google
                            account, contacts, iCal or similar accounts or
                            services with our Services, we will collect and
                            store information such as your contacts' (including
                            contacts your services provider(s) or application
                            automatically added to your address book when you
                            communicated with addresses or numbers not already
                            on your list) email address, name, address, website
                            URL, phone number and other metadata assigned to a
                            contact (collectively, “Contacts’ Information”). We
                            obtain this data in order to create profiles,
                            suggest existing profiles for you to connect with
                            and invite new persons to use the Services.
                            <ul className="terms-items">
                                <li>
                                    <b>Google account integration:</b> If you
                                    integrate your Google account with the
                                    Services, we will have access to your Google
                                    account information including but not
                                    limited to Gmail, Google Calendar, Google
                                    Contacts, and Google Drive (“Google Account
                                    Information”). We will have access to your
                                    inbox and calendar, for example, and you
                                    will be able to use the Services to compose
                                    and send emails and create calendar events.
                                    Additionally, you may also be able to access
                                    other integrated Google services, including
                                    Google API services. If you use our lead
                                    import feature, we may also use Google
                                    Account Information, including email
                                    content, to identify potential leads for
                                    you, summarize their inquiries, and import
                                    those leads into the Services.{' '}
                                </li>
                                <li>
                                    <b>Google API Services User Data Policy:</b>{' '}
                                    Notwithstanding anything else in this
                                    Privacy Policy, HoneyBook’s use of Google
                                    Account Information will adhere to the{' '}
                                    <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
                                        Google API Services User Data Policy
                                    </a>
                                    , including the limited use requirements. In
                                    particular, we:
                                    <ol>
                                        <li>
                                            only use Google Account Information
                                            to provide and improve the Services;
                                        </li>
                                        <li>
                                            do not transfer Google Account
                                            Information to third parties except
                                            as necessary to provide or improve
                                            the Services, as required by law, or
                                            in connection with a merger,
                                            acquisition, or sale of assets where
                                            we provide notice to users;
                                        </li>
                                        <li>
                                            do not use the Google Account
                                            Information for serving
                                            advertisements; and
                                        </li>
                                        <li>
                                            do not permit humans (other than
                                            intended recipients, if applicable)
                                            to read Google Account Information,
                                            except (a) if we obtain your
                                            affirmative consent, (b) as
                                            necessary for security purposes or
                                            to comply with applicable law or (c)
                                            our use is limited to internal
                                            operations and the Gmail Information
                                            (including derivations) has been
                                            aggregated and anonymized.
                                        </li>
                                    </ol>
                                </li>
                            </ul>
                        </p>
                        <p>
                            <b className="terms-marker">
                                Social Media Information:
                            </b>{' '}
                            You may be able to create a HoneyBook account
                            through a third party social media platform (“SMP”)
                            and/or share your HoneyBook experiences on a SMP. To
                            take advantage of this feature, we will ask you to
                            log into or grant us permission via the relevant
                            SMP. If you do so, we may receive information from
                            your SMP (such as your contact details or friend
                            lists). You may be able to adjust the information we
                            receive by changing your SMP settings. Please note
                            that the manner in which the SMP uses, stores and
                            discloses your information is governed by the
                            policies of the SMP and that if you share on an SMP
                            your friends, followers, or subscribers will be able
                            to view what you share.
                        </p>
                        <p>
                            <b className="terms-marker">Meeting Notetaker:</b>{' '}
                            If a Business uses the In-Person Meeting Notetaker
                            feature, we may collect content generated during
                            meetings (“Meeting Content”), which may include
                            audio, video, in-meeting messages, chat messaging
                            content, transcriptions, transcript edits and
                            recommendations.
                        </p>
                        <p>
                            <b className="terms-marker">Derived Information:</b>{' '}
                            We may infer your preferences for certain products
                            based on the personal information we collect about
                            you.
                        </p>
                        <p>
                            <b className="terms-marker">
                                Information We Collect from Others:
                            </b>{' '}
                            We may also obtain information from third parties
                            and sources other than you and the Services. These
                            sources include data providers, such as information
                            services and data licensors, and public sources,
                            such as social media platforms. The types of
                            information we collect from third parties include
                            contact information, company roles, and system
                            identification numbers.
                        </p>
                        <p>
                            If you are not a representative or employee of a
                            Business, we may also receive your Contact
                            Information if Businesses choose to import or sync
                            their Google account, contacts, iCal or similar
                            accounts or services with our Services. For more
                            information, see the “Third Party Account
                            Information” section above. We may also collect
                            content generated during your meetings with
                            Businesses if Businesses enable the Meeting
                            Notetaker function of the Services. For more
                            information, see the “Meeting Content” section
                            above. Finally, we may collect your information if
                            you interact with a Business that uses our Services
                            or when you interact with us on the Services.{' '}
                        </p>
                        <p>
                            We may combine this information with personal
                            information we receive from you.
                        </p>

                        <p>
                            <b>Information We Collect Automatically</b>. We, our
                            service providers, and our advertising partners may
                            automatically log information about you, your
                            computer or mobile device, and your interaction over
                            time with HoneyBook, our communications and other
                            online services, such as:
                        </p>
                        <ul className="terms-items">
                            <li>
                                Device information, such as your computer’s or
                                mobile device’s operating system type and
                                version, manufacturer and model, browser type,
                                screen resolution, RAM and disk size, CPU usage,
                                device type (e.g., phone, tablet), IP address,
                                unique identifiers (including identifiers used
                                for advertising purposes), language settings,
                                mobile device carrier, radio/network information
                                (e.g., WiFi, LTE, 3G).
                            </li>
                            <li>
                                Online activity information, such as pages or
                                screens you viewed, how long you spent on a page
                                or screen, the website you visited before
                                browsing HoneyBook’s Services, navigation paths
                                between pages or screens, information about your
                                activity on a page or screen, access times, and
                                duration of access, and whether you have opened
                                our marketing emails or clicked links within
                                them. We also use Google Analytics to monitor
                                activity on the Site and to assist with
                                advertising. Click{' '}
                                <a href="https://policies.google.com/technologies/partner-sites">
                                    here
                                </a>{' '}
                                to learn how Google Analytics collects and
                                processes data.
                            </li>
                            <li>
                                Chats. Chats on our Services may be monitored
                                and retained.
                            </li>
                            <li>
                                Location information. When you use the Services,
                                we and third parties integrated into our
                                Services may collect your general location based
                                on IP address
                            </li>
                            <li>
                                User Interactions. In some cases, we may collect
                                click or touch stream data, movement, scroll,
                                keystroke activity and third-party sites or
                                services you were using before and after
                                interacting with the Services.
                            </li>
                        </ul>
                        <p>
                            Technologies we use for automated data collection
                            include:
                        </p>

                        <ul className="terms-items">
                            <li>
                                Cookies, which are text files that websites
                                store on a visitor‘s device to uniquely identify
                                the visitor’s browser or to store information or
                                settings in the browser for the purpose of
                                helping you navigate between pages efficiently,
                                remembering your preferences, enabling
                                functionality, helping us understand user
                                activity and patterns, and facilitating online
                                advertising. For example, we use cookies to keep
                                track of your shopping cart and to provide other
                                personalized experiences.
                            </li>
                            <li>
                                Local storage technologies, like HTML5, that
                                provide cookie-equivalent functionality but can
                                store larger amounts of data, including on your
                                device outside of your browser in connection
                                with specific applications.
                            </li>
                            <li>
                                Web beacons, also known as pixel tags or clear
                                GIFs, which are used to demonstrate that a
                                webpage or email was accessed or opened, or that
                                certain content was viewed or clicked.
                            </li>
                        </ul>

                        <h2 className="terms-title">
                            How We Use Your Personal Information
                        </h2>
                        <p>
                            We and our vendors may use your personal information
                            for:
                        </p>
                        <p>
                            <b className="terms-marker">Service delivery:</b>{' '}
                            including to:
                        </p>
                        <ul className="terms-items">
                            <li>
                                Provide, operate and improve the Services and
                                our business of providing an all-in-one platform
                                to manage Businesses;
                            </li>
                            <li>
                                To create, maintain, and authenticate your
                                account;
                            </li>
                            <li>
                                Conduct research and analysis relating to
                                HoneyBook, our Services, and our business;
                            </li>
                            <li>
                                Transmit your payments (through third party
                                vendors) and complete transactions with you;
                            </li>
                            <li>
                                Communicate with you about HoneyBook, including
                                by sending surveys, offers, announcements,
                                updates, security alerts, and support and
                                administrative messages;
                            </li>
                            <li>
                                Understand your needs and interests, and
                                personalize your experience with HoneyBook and
                                our communications; and
                            </li>
                            <li>
                                Provide support for HoneyBook, and respond to
                                your requests, questions and feedback.
                            </li>
                        </ul>
                        <p>
                            <b className="terms-marker">
                                Research and development:
                            </b>{' '}
                            We may use your personal information, as well as
                            information available in Vendor contracts and
                            related proposals that you may upload, for research
                            and development, including to analyze and improve
                            the Services. HoneyBook does not use or aggregate
                            Google Account Information for these purposes.
                        </p>
                        <p>
                            <b className="terms-marker">
                                Marketing and advertising:
                            </b>{' '}
                            We and our advertising partners may use your
                            personal information for marketing and advertising
                            purposes, including:
                        </p>
                        <ul className="terms-items">
                            <li>
                                <b>Direct marketing</b>. We or our advertising
                                partners may send Businesses direct marketing
                                communications as permitted by law, including,
                                but not limited to, notifying you of special
                                promotions, offers and events via postal mail,
                                email, telephone, text message, and other means.
                            </li>
                            <li>
                                <b>Interest-based advertising</b>. We engage
                                advertising partners, including third party
                                advertising companies and social media
                                companies, to display ads on the Services and
                                other online services. These companies may use
                                cookies and similar technologies to collect
                                information about your interaction (including
                                the data described in the “Information we
                                collection automatically” section above) over
                                time across the Services, our communications,
                                and other online services, and use that
                                information to serve online ads that they think
                                will interest you, including ads for products
                                and services provided by third parties. This is
                                called interest-based advertising. We may also
                                share information about our users with these
                                companies to facilitate interest-based
                                advertising to those or similar users on other
                                online platforms. You can learn more about your
                                choices for limiting interest-based advertising
                                in the “Online tracking opt out” section below.
                            </li>
                        </ul>

                        <p>
                            <b className="terms-marker">
                                Connections and Invitations:
                            </b>{' '}
                            We may also use Contacts’ Information to suggest
                            connections and to enable you to invite others to
                            use our Services and connect with you. It is your
                            choice whether to invite someone to our Services.
                            For clarity, if an invitee accepts the invitation,
                            then the invitee shall be subject to the terms and
                            conditions of this Privacy Policy and our Terms of
                            Service.
                        </p>
                        <p>
                            <b className="terms-marker">
                                Compliance and protection:
                            </b>{' '}
                            We may use your personal information to:
                            <ul className="terms-items">
                                <li>
                                    Comply with applicable laws, lawful
                                    requests, and legal process, such as to
                                    respond to subpoenas or requests from
                                    government authorities
                                </li>
                                <li>
                                    Protect our, your or others’ rights,
                                    privacy, safety or property (including by
                                    making and defending legal claims);
                                </li>
                                <li>
                                    Audit our internal processes for compliance
                                    with legal and contractual requirements and
                                    internal policies;
                                </li>
                                <li>
                                    Enforce the terms and conditions that govern
                                    HoneyBook and our Services; and
                                </li>
                                <li>
                                    Prevent, identify, investigate and deter
                                    fraudulent, harmful, unauthorized, unethical
                                    or illegal activity, including cyberattacks
                                    and identity theft.
                                </li>
                            </ul>
                        </p>
                        <p>
                            <b className="terms-marker">Notice to Non-Users:</b>{' '}
                            We may receive personal information about you when
                            Businesses import or sync their Contacts’
                            Information (e.g., your email address, name,
                            address, website URL, phone number and other
                            metadata assigned to a contact). This contact
                            information may include people who are HoneyBook
                            users and other contacts who are not users and don’t
                            have an HoneyBook account (i.e., Non-Users). We may
                            use this information to create profiles, suggest
                            existing profiles for Businesses to connect with,
                            and invite Non-Users to use the Services. We may
                            also receive your information when Businesses use
                            our Meeting Notetaker feature (as described above)
                            or our lead import feature, which we will use to
                            identify potential leads for the Business, summarize
                            your inquiries, and import your information into the
                            Services. Finally, we may collect your information
                            if you interact with a Business that uses our
                            Services or when you interact with us on the
                            Services.
                        </p>
                        <h2 className="terms-title">
                            How We Disclose Your Personal Information
                        </h2>
                        <p>
                            We may disclose your personal information to the
                            following parties and as otherwise described in this
                            Privacy Policy or at the time of collection:
                        </p>
                        <p>
                            <b className="terms-marker">Related Companies:</b>{' '}
                            We may disclose your personal information (excluding
                            Gmail Information) with our subsidiaries and
                            affiliates for purposes consistent with this Privacy
                            Policy.
                        </p>
                        <p>
                            <b className="terms-marker">Vendors:</b> Companies
                            and individuals that provide services on our behalf
                            or help us operate HoneyBook, the Services, or our
                            business (such as hosting, information technology,
                            customer support, email delivery, and website
                            analytics services).
                        </p>
                        <p>
                            <b className="terms-marker">
                                Advertising partners:
                            </b>{' '}
                            Third party advertising companies and other third
                            parties, including HoneyBook’s parent, affiliates,
                            other brands and business partners for commercial
                            purposes like the interest-based advertising
                            purposes described above.
                        </p>
                        <p>
                            <b className="terms-marker">
                                Professional advisors:
                            </b>{' '}
                            Professional advisors, such as lawyers, auditors,
                            bankers and insurers, where necessary in the course
                            of the professional services that they render to us.
                        </p>
                        <p>
                            <b className="terms-marker">
                                Legal authorities and others:
                            </b>{' '}
                            Law enforcement, government authorities, and private
                            parties, as we believe in good faith to be necessary
                            or appropriate for the compliance and protection
                            purposes described above.
                        </p>
                        <p>
                            <b className="terms-marker">
                                Business transferees:
                            </b>{' '}
                            Acquirers and other relevant participants in
                            business transactions (or negotiations for such
                            transactions) involving a corporate financing,
                            divestiture, merger, consolidation, acquisition,
                            reorganization, sale or other disposition of all or
                            any portion of the business or assets of, or equity
                            interests in, HoneyBook (including, in connection
                            with a bankruptcy or similar proceedings). We may
                            also disclose your information in connection with
                            the consideration or negotiation of such a
                            transaction.
                        </p>
                        <p>
                            <b>Consent:</b> We may also disclose personal
                            information where we have your consent to do so.
                        </p>
                        <p>
                            <b>Other Users or the Public:</b>
                            Please keep in mind that whenever you voluntarily
                            make your personal information available for viewing
                            by third parties or the public on or through the
                            Services or posting via SMP, that information can be
                            seen, collected and used by others. We are not
                            responsible for any use of such information by
                            others.
                        </p>
                        <h2 className="terms-title">Your Choices</h2>

                        <p>
                            <b>Update or correct your information</b>. To keep
                            your information accurate, current, and complete,
                            please contact us as specified below. We will take
                            reasonable steps to update or correct information in
                            our possession that you have previously submitted
                            via the Services. Please also feel free to contact
                            us if you have any questions.
                            <br />
                            <br />
                            <b>Opt out of marketing communications</b>. You may
                            opt out of marketing- related communications by
                            following the opt out or unsubscribe instructions
                            contained in the marketing communication we send
                            you.
                        </p>

                        <p>
                            <b>Online tracking opt out</b>. There are a number
                            of ways to opt out of having your online activity
                            and device information collected through the
                            Services, which we have summarized below:
                        </p>
                        <ul className="terms-items">
                            <li>
                                <i>Blocking cookies in your browser</i>. Most
                                browsers let you remove or reject cookies,
                                including cookies used for interest-based
                                advertising. To do this, follow the instructions
                                in your browser settings. Many browsers accept
                                cookies by default until you change your
                                settings. Click here for more information about
                                cookies, including how to see what cookies have
                                been set on your device and how to manage and
                                delete them.
                            </li>
                            <li>
                                <i>Blocking advertising ID</i> use in your
                                mobile settings. Your mobile device settings may
                                provide functionality to limit use of the
                                advertising ID associated with your mobile
                                device for interest-based advertising purposes.
                            </li>
                            <li>
                                <i>Using privacy plug-ins or browsers</i>. You
                                can block our websites from setting cookies used
                                for interest-based ads by using a browser with
                                privacy features, like Brave, or installing
                                browser plugins like Privacy Badger, Ghostery,
                                or uBlock Origin, and configuring them to block
                                third party cookies/trackers. You can also opt
                                out of Google Analytics by downloading and
                                installing the browser plug-in.
                            </li>
                            <li>
                                <i>Platform opt outs</i>. The following
                                advertising partners offer opt out features that
                                let you opt out of use of your information for
                                interest-based advertising:
                                <br />
                                <ul className="terms-items-inner">
                                    <li>
                                        <a href="https://myadcenter.google.com/controls?hl=en_US">
                                            Google
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/help/1075880512458213">
                                            Facebook
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads">
                                            Twitter
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Advertising industry opt out tools. You can also
                                use these opt out options to limit use of your
                                information for interest-based advertising by
                                participating companies:
                                <br />
                                <ul className="terms-items-inner">
                                    <li>
                                        <a href="https://optout.aboutads.info/?c=2&lang=EN">
                                            Digital Advertising Alliance for
                                            Websites
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://thenai.org/opt-out/">
                                            Network Advertising Initiative
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            Note that you will need to opt out on every browser
                            and device that you use because these opt out
                            mechanisms are specific to the device or browser on
                            which they are exercised.
                            <br />
                            <br />
                            <i>Do Not Track</i>. Some Internet browsers may be
                            configured to send “Do Not Track” signals to the
                            online services that you visit. We currently do not
                            respond to "Do Not Track" or similar signals. Please
                            note that Do Not Track is a different privacy
                            mechanism than the Global Privacy Control browser
                            choice referenced below.
                            <br />
                            <br />
                            <i>Declining to provide information</i>. We need to
                            collect personal information to provide certain
                            services. If you do not provide the information we
                            identify as required or mandatory, we may not be
                            able to provide those services.
                        </p>
                        <p>
                            <h2 className="terms-title">Your Rights</h2>
                            Applicable law may give you various rights regarding
                            your information. If these rights apply to you, they
                            may permit you to request that we:
                            <ul className="terms-items">
                                <li>
                                    confirm whether or not we are processing
                                    your information and provide access to
                                    and/or a copy of certain information we hold
                                    about you
                                </li>
                                <li>
                                    correct information which is out of date or
                                    incorrect
                                </li>
                                <li>
                                    delete certain information which we are
                                    holding about you
                                </li>
                                <li>
                                    revoke your consent for the processing of
                                    your information
                                </li>
                            </ul>
                            We will consider all requests and provide our
                            response within the time period stated by applicable
                            law. Please note, however, that certain information
                            may be exempt from such requests in some
                            circumstances, which may include if we need to keep
                            processing your information for our legitimate
                            interests or to comply with a legal obligation.
                        </p>
                        <p>
                            To exercise any of these rights, please contact us
                            using the contact details provided under the “How To
                            Contact Us” section below. We may request you
                            provide us with information necessary to verify your
                            identity before responding to your request as
                            required or permitted by applicable law, such as
                            your name, email address, and account information.
                        </p>
                        <p>
                            You may be able to designate an authorized agent to
                            make requests on your behalf. In order for an
                            authorized agent to be verified, you must provide
                            the authorized agent with signed, written permission
                            to make such requests or a power of attorney. We may
                            also follow up with you to verify your identity
                            before processing the authorized agent’s request as
                            permitted by applicable law.
                        </p>
                        <p>
                            We may use the methods described in this Privacy
                            Policy to identify you or make inferences about you
                            as described above. Where required by applicable
                            law, you have the option to activate these features
                            by creating an account with us.
                        </p>
                        <p>
                            <h2 className="terms-title">
                                Third Party Sites and Services
                            </h2>
                            Our Services may contain links to websites and other
                            online services operated by third parties. In
                            addition, our content may be integrated into web
                            pages or other online services that are not
                            associated with us. These links and integrations are
                            not an endorsement of, or representation that we are
                            affiliated with, any third party. We do not control
                            websites or online services operated by third
                            parties, and we are not responsible for their
                            actions.
                        </p>
                        <h2 className="terms-title">Security</h2>
                        <p>
                            We employ a number of technical, organizational and
                            physical safeguards designed to protect the personal
                            information we collect. However, no security
                            measures are failsafe and we cannot guarantee the
                            security of your personal information. You use the
                            Services at your own risk.
                        </p>
                        <p>
                            You play a critical role in protecting your
                            information by maintaining up-to-date computer
                            security protections. Steps you take to ensure the
                            security of your computer, computer passwords, or
                            other personal identifier authentication mechanisms
                            are key components of the protection of your
                            personal information. You agree that you are
                            responsible for any additional verification
                            procedures and security you deem necessary.
                        </p>
                        <p>
                            <h2 className="terms-title">
                                Processing of Data in the U.S.
                            </h2>
                            We are headquartered in the United States and may
                            use service providers that operate in other
                            countries. Your personal information may therefore
                            be processed in the United States or transferred to
                            other locations where privacy laws may not be as
                            protective as those in your state, province, or
                            country, and that information may be accessible to
                            the authorities of those jurisdictions.
                        </p>
                        <h2 className="terms-title">Children</h2>
                        <p>
                            The Services are intended for general audiences and
                            not for children under 16. If we learn that we have
                            collected personal information through the Services
                            from a child under 16 without the consent of the
                            child’s parent or guardian, we will take reasonable
                            steps to delete it as soon as possible. We also
                            comply with other age restrictions and requirements
                            in accordance with applicable local laws..
                        </p>

                        <h2 className="terms-title">
                            Notice to California Users
                        </h2>

                        <p>
                            This section applies only to “personal information”
                            about California residents, as that term is defined
                            in the California Consumer Privacy Act (“CCPA”), and
                            it supplements the information in the rest of our
                            Privacy Policy. Information about individuals who
                            are not residents of California may be handled
                            differently and is not subject to the same
                            California rights described below. This section does
                            not apply to personal information we collect from
                            employees or job applicants in their capacity as
                            employees or job applicants.
                        </p>
                        <h2 className="terms-title">
                            Personal Information We Collect and Disclose
                        </h2>
                        <p>
                            <p>
                                Throughout this Privacy Policy, we discuss in
                                detail the specific pieces of personal
                                information we collect from and about our users,
                                how we use that information, and how we disclose
                                it. Under the CCPA, we are also required to
                                provide you with the CCPA “categories” of
                                personal information and sensitive personal
                                information we collect. Those categories, how we
                                use them, and to whom we disclose them, are
                                below:
                            </p>
                            <br />
                            <table>
                                <tr>
                                    <td>
                                        <b>Category of Personal Information</b>
                                    </td>
                                    <td>
                                        <b>Processing Purposes</b>
                                    </td>
                                    <td>
                                        <b>Categories of Recipients</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Identifiers</td>
                                    <td>
                                        Service delivery; research and
                                        development; marketing and advertising;
                                        creating public profiles; suggesting
                                        connections and allowing you to send
                                        invitations; and compliance and
                                        protection.
                                    </td>
                                    <td>
                                        Related companies; vendors; advertising
                                        partners; professional advisors; legal
                                        authorities and others; business
                                        transferees; other users/public (if you
                                        publicly post it on the Services).
                                    </td>
                                </tr>
                                <tr>
                                    <td>Commercial information</td>
                                    <td>
                                        Service delivery; research and
                                        development; marketing and advertising;
                                        creating public profiles; suggesting
                                        connections and allowing you to send
                                        invitations; and compliance and
                                        protection.
                                    </td>
                                    <td>
                                        Related companies; vendors; advertising
                                        partners; professional advisors; legal
                                        authorities and others; business
                                        transferees.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Financial data</td>
                                    <td>
                                        Service delivery; and compliance and
                                        protection
                                    </td>
                                    <td>
                                        Related companies; vendors; professional
                                        advisors; legal authorities and others;
                                        business transferees.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Approximate geolocation data</td>
                                    <td>
                                        Service delivery; research and
                                        development; marketing and advertising;
                                        creating public profiles; suggesting
                                        connections and allowing you to send
                                        invitations; and compliance and
                                        protection.
                                    </td>
                                    <td>
                                        Related companies; vendors; advertising
                                        partners; professional advisors; legal
                                        authorities and others; business
                                        transferees; other users/public (if you
                                        publicly post it on the Services).
                                    </td>
                                </tr>
                                <tr>
                                    <td>Internet activity information</td>
                                    <td>
                                        Service delivery; research and
                                        development; marketing and advertising;
                                        creating public profiles; suggesting
                                        connections and allowing you to send
                                        invitations; and compliance and
                                        protection.
                                    </td>
                                    <td>
                                        Related companies; vendors; advertising
                                        partners; professional advisors; legal
                                        authorities and others; business
                                        transferees.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Professional data</td>
                                    <td>
                                        Service delivery; research and
                                        development; creating public profiles;
                                        and compliance and protection.
                                    </td>
                                    <td>
                                        Related companies; vendors; professional
                                        advisors; legal authorities and others;
                                        business transferees; other users/public
                                        (if you publicly post it on the
                                        Services).
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Audio, electronic, visual, or similar
                                        information
                                    </td>
                                    <td>
                                        Service delivery and compliance and
                                        protection.
                                    </td>
                                    <td>
                                        Related companies; vendors; professional
                                        advisors; legal authorities and others;
                                        business transferees.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Inferences</td>
                                    <td>
                                        Service delivery; research and
                                        development; marketing and advertising;
                                        creating public profiles; suggesting
                                        connections and allowing you to send
                                        invitations; and compliance and
                                        protection.
                                    </td>
                                    <td>
                                        Related companies; vendors; advertising
                                        partners; professional advisors.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Other information that identifies or can
                                        be reasonably associated with you
                                    </td>
                                    <td>
                                        Service delivery; research and
                                        development; marketing and advertising;
                                        creating public profiles; suggesting
                                        connections and allowing you to send
                                        invitations; and compliance and
                                        protection.
                                    </td>
                                    <td>
                                        Related companies; vendors; advertising
                                        partners; professional advisors; legal
                                        authorities and others; business
                                        transferees; other users/public (if you
                                        publicly post it on the Services).
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Sensitive personal information (account
                                        log-in and password, financial data, or
                                        other credentials allowing access to
                                        your account, and other sensitive
                                        personal information you may voluntarily
                                        provide to us (including biometric
                                        information —such as voice recordings
                                        that may be used for identification or
                                        voice synthesis purposes) in your
                                        capacity as a user (e.g. if you
                                        voluntarily provide content revealing
                                        your race, religion, or sexual
                                        orientation)
                                    </td>
                                    <td>
                                        Service delivery; and compliance and
                                        protection.
                                    </td>
                                    <td>Related companies; vendors</td>
                                </tr>
                            </table>
                        </p>
                        <p>
                            We collect the categories of personal information
                            identified above from the following sources: (1)
                            directly from you; (2) through your use of the
                            Services; and (3) data providers, such as
                            information services and data licensors, and public
                            sources, such as social media platforms.
                        </p>
                        <p>
                            <h2 className="terms-title">
                                Your California Privacy Rights
                            </h2>
                            If you are a California resident, the CCPA allows
                            you to make certain requests about your personal
                            information. Specifically, the CCPA allows you to
                            request us to:
                            <ul className="terms-items">
                                <li>
                                    Inform you about the categories of personal
                                    information we collect or disclose about
                                    you; the categories of sources of such
                                    information; the business or commercial
                                    purpose for collecting your personal
                                    information; and the categories of third
                                    parties with whom we share/disclose personal
                                    information.
                                </li>
                                <li>
                                    Provide access to and/or a copy of certain
                                    personal information we hold about you.
                                </li>
                                <li>
                                    Correct inaccurate personal information that
                                    we maintain about you.
                                </li>
                                <li>
                                    Delete certain personal information we have
                                    about you.
                                </li>
                            </ul>
                        </p>
                        <p>
                            You may have the right to receive information about
                            the financial incentives that we offer to you (if
                            any). You also have the right not to be
                            discriminated against (as provided for in applicable
                            law) for exercising certain of your rights. Certain
                            information may be exempt from such requests under
                            applicable law. For example, we need certain types
                            of information so that we can provide the Services
                            to you. If you ask us to delete it, you may no
                            longer be able to access or use the Services.
                        </p>
                        <p>
                            If you would like further information regarding your
                            legal rights under California law or would like to
                            exercise any of them, please contact us at{' '}
                            <a
                                href="mailto:concierge@honeybook.com"
                                className="link-act"
                                target="_blank"
                                title="HoneyBook support"
                            >
                                concierge@honeybook.com
                            </a>
                            . You will need to provide us with information
                            sufficient to verify your identity before we can
                            satisfy your request. To do so, you will need to
                            provide us with certain information regarding
                            yourself and/or your usage of the Services, such as
                            name, email address, and account information. You
                            may also designate an authorized agent to make a
                            request on your behalf. To do so, you need to
                            provide us with written authorization or a power of
                            attorney for the agent to act on your behalf. You
                            will still need to verify your identity directly
                            with us.
                        </p>
                        <p>
                            <h2 className="terms-title">
                                “Sharing” and “Selling” Information.
                            </h2>{' '}
                            The CCPA requires businesses that “sell” personal
                            information, as the term “sell” is defined under the
                            CCPA, to provide an opt-out from such sales. Some
                            people have taken the position that when a website
                            uses third parties’ cookies or similar technology
                            for its own analytics or advertising purposes, the
                            website is engaged in a “sale” under the CCPA if the
                            third parties have some ability to use, disclose or
                            retain the data to improve their service or to take
                            steps beyond the most narrowly drawn bounds of
                            merely providing their service to the website/app.
                            Some take this position even when the website pays
                            the third party (not vice versa), and in most cases
                            merely provides the third party with an opportunity
                            to collect data directly, instead of providing
                            personal information to the third party. As part of
                            these analytics and advertising services, these
                            technologies may access identifiers (like IP
                            addresses), internet or other electronic network
                            activity information (like information regarding an
                            individual’s browsing interactions on our Site), and
                            commercial information (like the fact that a browser
                            visited a page directed to people who are
                            considering using the Services) to those sorts of
                            companies. While we do not believe these are “sales”
                            as that term is defined under the CCPA, you can opt
                            out of this activity by visiting this link:{' '}
                            <a
                                className="link-act"
                                href="https://docs.google.com/forms/d/e/1FAIpQLSfL_PmJToXDnK74KCQKpfEbTeVAAOxRtbDrFJIFC74sLMNqWw/viewform"
                                title="Do Not Sell or Share My Personal Information"
                            >
                                Do Not Sell or Share My Personal Information
                            </a>
                            .
                        </p>
                        <p>
                            The CCPA also requires businesses that "share“
                            personal information to provide an opt out from such
                            sharing. Under the CCPA,” sharing” is defined as the
                            targeting of advertising to a consumer based on that
                            consumer’s personal information obtained from the
                            consumer’s activity across websites. We “share”
                            information for these purposes to provide more
                            relevant and tailored advertising to you regarding
                            the Services. As part of this advertising, we may
                            "share“ identifiers (like IP addresses and email
                            addresses), internet or other electronic network
                            activity information (like information regarding an
                            individual’s interactions with our Services), and
                            commercial information (like the fact that a browser
                            visited a page directed to people who are
                            considering using the Services) with advertising
                            platforms and advertising networks. To opt out of
                            such “sharing,” please visit this link:{' '}
                            <a
                                className="link-act"
                                href="https://docs.google.com/forms/d/e/1FAIpQLSfL_PmJToXDnK74KCQKpfEbTeVAAOxRtbDrFJIFC74sLMNqWw/viewform"
                                title="Do Not Sell or Share My Personal Information"
                            >
                                Do Not Sell or Share My Personal Information
                            </a>
                            .
                        </p>
                        <p>
                            <h2 className="terms-title">
                                Sensitive Personal Information.
                            </h2>{' '}
                            The CCPA also allows you to limit the use or
                            disclosure of your “sensitive personal information”
                            (as defined in the CCPA) if your sensitive personal
                            information is used for certain purposes. Please
                            note that we do not use or disclose sensitive
                            personal information other than for purposes for
                            which you cannot opt out under the CCPA.
                        </p>
                        <p>
                            <h2 className="terms-title">Data Retention</h2> You
                            We will retain your information for as long as
                            necessary to provide the Services and for the other
                            purposes set out in this Privacy Policy. We also
                            store information when necessary to comply with
                            contractual and legal obligations, when we have a
                            legitimate interest to do so (such as improving and
                            developing the Services, and enhancing the safety,
                            security, and stability of the Services). We may
                            also store your information in order to defend or
                            ascertain our legal rights, respond to valid legal
                            requests, in the context of a merger/acquisition or
                            in order to implement our Terms.
                        </p>
                        <p>
                            In all cases, in addition to the purposes, we
                            consider the amount, nature and sensitivity of the
                            personal data, as well as the potential risk of harm
                            from unauthorized use or disclosure of your personal
                            data, in determining how long to retain personal
                            data.{' '}
                        </p>

                        <p>
                            <h2 className="terms-title">
                                California “Shine the Light” Disclosure
                            </h2>{' '}
                            The California “Shine the Light” law gives residents
                            of California the right under certain circumstances
                            to opt out of the disclosure of certain categories
                            of personal information (as defined in the Shine the
                            Light law) with third parties for their direct
                            marketing purposes. If you are a California resident
                            and would like to make such a request, please submit
                            your request in writing to us using the contact
                            information provided below.
                        </p>
                        <h2 className="terms-title">How to Contact Us</h2>

                        <p>
                            You can reach HoneyBook and our General Counsel by
                            email at{' '}
                            <a href="mailto:legal@honeybook.com">
                                legal@honeybook.com
                            </a>{' '}
                            or by writing to:
                            <br />
                            HoneyBook, Inc. Attention: Privacy
                        </p>
                        <p>
                            539 Bryant St, Suite 200, San Francisco, CA 94107.
                        </p>
                    </div>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};
/* eslint-enable */

export default PrivacyTabContent;
