import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './tax-calculator.scss';
import HBText from '@honeybook-ui/HBText/HBText';
import InputRange from 'react-input-range';
import type { TTaxData } from '../../types/util-types';

import TaxStore from '../../stores/TaxStore';
import EmailForm from '../EmailForm/EmailForm';

const {
    FILING_STATUSES_MAXIMUMS,
    SLIDER_RANGES,
    US_STATES,
    INDUSTRIES,
    BUSINESS_TYPES
} = TaxStore;

const FILING_STATUSES = Object.keys(FILING_STATUSES_MAXIMUMS || {});
const SLIDERS = ['income1099', 'incomeW2', 'expenses'];

const formatDollarAmount = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

interface Props {
    taxData: TTaxData;
    updateTaxData: (key: string, value: string | number) => void;
    onSubmit: () => void;
}

const TaxCalculator = ({
    taxData,
    updateTaxData,
    onSubmit
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const [userHasInteracted, setUserHasInteracted] = useState(false);

    const formatSliderRange = id => {
        const range = SLIDER_RANGES[id] || { min: 0, max: 300000 };

        const lowerRange = formatDollarAmount(taxData[id] - range.step);

        const upperRange = formatDollarAmount(taxData[id] + range.step);

        if (taxData[id] <= range.min) {
            return `Under $${formatDollarAmount(range.min)}`;
        } else if (taxData[id] >= range.max) {
            return `$${formatDollarAmount(range.max)}+`;
        }
        return `$${lowerRange}–$${upperRange}`;
    };

    const handleSliderAction = (slider, value) => {
        setUserHasInteracted(true);
        updateTaxData(slider, value);
    };

    return (
        <div className="tax-calculator">
            <div className="tax-calculator__sliders-container">
                {SLIDERS.map((slider, index) => (
                    <div key={slider}>
                        <HBText
                            tag="p"
                            type="body-xs"
                            customClass="tax-calculator__label"
                        >
                            {t(`taxCalculator.calculator.questions.${slider}`)}
                        </HBText>

                        <label
                            htmlFor={slider}
                            className="tax-calculator__slider-label"
                        >
                            {formatSliderRange(slider)}
                        </label>
                        <div
                            className={`slider-container ${
                                index === 0 && !userHasInteracted
                                    ? 'slider-container--pulse'
                                    : ''
                            }`}
                        >
                            <InputRange
                                minValue={SLIDER_RANGES[slider].min}
                                maxValue={SLIDER_RANGES[slider].max}
                                step={SLIDER_RANGES[slider].step}
                                name={slider}
                                value={taxData[slider]}
                                onChange={value =>
                                    handleSliderAction(slider, value)
                                }
                            />
                        </div>
                    </div>
                ))}
            </div>

            <label className="tax-calculator__label" htmlFor="us-state">
                {t('taxCalculator.calculator.questions.state')}
            </label>

            <select
                name="us-state"
                className="tax-calculator__dropdown"
                onChange={e => updateTaxData('state', e.target.value)}
                value={taxData.state}
            >
                {US_STATES.map(state => (
                    <option key={state} value={state}>
                        {t(`states.${state}`)}
                    </option>
                ))}
            </select>

            <label className="tax-calculator__label" htmlFor="filing-status">
                {t('taxCalculator.calculator.questions.status')}
            </label>

            <select
                name="filing-status"
                className="tax-calculator__dropdown"
                onChange={e => updateTaxData('filingStatus', e.target.value)}
                value={taxData.filingStatus}
            >
                {FILING_STATUSES.map(status => (
                    <option key={status} value={status}>
                        {t(`taxCalculator.calculator.status.${status}`)}
                    </option>
                ))}
            </select>

            <label className="tax-calculator__label" htmlFor="industry">
                {t('taxCalculator.calculator.questions.industry')}
            </label>

            <select
                name="industry"
                className="tax-calculator__dropdown"
                onChange={e => updateTaxData('industry', e.target.value)}
                value={taxData.industry}
            >
                {INDUSTRIES.map(industry => (
                    <option key={industry} value={industry}>
                        {t(`taxCalculator.calculator.industry.${industry}`)}
                    </option>
                ))}
            </select>

            <label className="tax-calculator__label" htmlFor="business-type">
                {t('taxCalculator.calculator.questions.businessType')}
            </label>

            <select
                name="business-type"
                className="tax-calculator__dropdown"
                onChange={e => updateTaxData('businessType', e.target.value)}
                value={taxData.businessType}
            >
                {BUSINESS_TYPES.map(businessType => (
                    <option key={businessType} value={businessType}>
                        {t(
                            `taxCalculator.calculator.businessTypes.${businessType}`
                        )}
                    </option>
                ))}
            </select>

            <label className="tax-calculator__label" htmlFor="email">
                {t('taxCalculator.calculator.questions.email')}
            </label>

            <EmailForm
                onFormSubmit={onSubmit}
                buttonText={t('taxCalculator.calculator.CTAText')}
            />
        </div>
    );
};

export default TaxCalculator;
