interface Props {
    pageContext: {
        url: string;
    };
}

import UtilsService from '../Services/UtilsService';

const RedirectTemplate = ({ pageContext: { url } }: Props) => {
    UtilsService.redirectWithUTM(url);
};

export default RedirectTemplate;
