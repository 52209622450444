import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import HBText from '@honeybook-ui/HBText/HBText';
import TaxCalculator from '../../TaxCalculator/TaxCalculator';
import TaxCalculatorResults from '../../TaxCalculatorResults/TaxCalculatorResults';
import type { TTaxData } from '../../../types/util-types';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import Star from '../../../svg/Star.inline.svg';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import TaxStore from '../../../stores/TaxStore';
import ApiService from '../../../Services/ApiService';
import getApiUrl from '../../../env';
import UtilsService from '../../../Services/UtilsService';

import './tax-calculator-hero.scss';

const {
    FILING_STATUSES_MAXIMUMS,
    SLIDER_RANGES,
    US_STATES,
    INDUSTRIES,
    BUSINESS_TYPES,
    SELF_EMPLOYMENT_RATE,
    SELF_EMPLOYMENT_TAX_DEDUCTION_RATE,
    FED_TAX_BRACKETS,
    STATE_TAX_BRACKETS,
    STANDARD_DEDUCTIONS
} = TaxStore;

const FILING_STATUSES = Object.keys(FILING_STATUSES_MAXIMUMS || {});

const DEFAULT_TAX_DATA: TTaxData = {
    state: US_STATES[0],
    filingStatus: FILING_STATUSES[0],
    income1099: SLIDER_RANGES.income1099.min,
    incomeW2: SLIDER_RANGES.incomeW2.min,
    expenses: SLIDER_RANGES.expenses.min,
    industry: INDUSTRIES[0],
    businessType: BUSINESS_TYPES[0]
};

const calcStandardDeduction: (filingStatus: string) => number = (
    filingStatus: string
) => {
    return STANDARD_DEDUCTIONS[filingStatus];
};

const getBracketTax = (income, brackets) => {
    let tax = 0;
    let previousLimit = 0;

    for (const bracket of brackets) {
        if (income > bracket.limit) {
            tax += (bracket.limit - previousLimit) * bracket.rate;
        } else {
            tax += (income - previousLimit) * bracket.rate;
            break;
        }
        previousLimit = bracket.limit;
    }

    return tax;
};

interface Props {
    USState: string;
}

export type TCalculationData = {
    yearlyBusinessExpenses: number;
    state: string;
    incomeW2: number;
    income1099: number;
    SETaxableIncome: number;
    SETax: number;
    SETaxDeduction: number;
    standardDeduction: number;
    adjustedGrossIncome: number;
    fedIncomeTax: number;
    stateIncomeTax: number;
    totalTax: number;
    isChecked?: boolean;
    businessType: string;
    email: string;
};

function TaxCalculatorHero({ USState }: Props): JSX.Element {
    const { t } = useTranslation();
    const [submitted, setSubmitted] = useState(false);
    const [taxData, setTaxData] = useState(DEFAULT_TAX_DATA);
    const [calculationData, setCalculationData] = useState<TCalculationData>();

    const submitEmail = async (email, isChecked = true) => {
        if (calculationData) {
            AnalyticsService.trackClick(
                AnalyticsEvents.tax_calculator_submit_email,
                {
                    email
                }
            );

            AnalyticsService.reportIterableEvent(
                'lead_created',
                {
                    email: email,
                    lead_type: 'tax_tool',
                    company_type: calculationData.businessType,
                    calculationData,
                    accept_getting_emails: calculationData.isChecked
                },
                { email }
            );

            if (UtilsService.isProduction()) {
                await taxCalculatorApi({
                    email,
                    accept_getting_emails: isChecked,
                    yearly_business_expenses:
                        calculationData.yearlyBusinessExpenses,
                    final_taxes: calculationData.totalTax,
                    state: calculationData.state,
                    income_w2: calculationData.incomeW2,
                    income1099: calculationData.income1099,
                    SETaxableIncome: calculationData.SETaxableIncome,
                    SETax: calculationData.SETax,
                    SETaxDeduction: calculationData.SETaxDeduction,
                    standardDeduction: calculationData.standardDeduction,
                    adjustedGrossIncome: calculationData.adjustedGrossIncome,
                    fedIncomeTax: calculationData.fedIncomeTax,
                    stateIncomeTax: calculationData.stateIncomeTax,
                    totalTax: calculationData.totalTax
                });
            }
        }
    };

    const taxCalculatorApi = (formattedData: Record<string, any>) => {
        const apiUrl = `${getApiUrl()}/api/v2/tools/tax_calculator`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formattedData)
        };

        return ApiService._apiFetch<void>(apiUrl, requestOptions);
    };

    const updateTaxData = (key: string, value: string | number) => {
        const newTaxData = { ...taxData };
        newTaxData[key] = value;
        setTaxData(newTaxData);
    };

    useEffect(() => {
        if (USState) {
            updateTaxData('state', USState);
        } else if (window.localStorage.location) {
            const location = JSON.parse(window.localStorage.location);

            if (location && location.region && location.country_name) {
                const region = location.region;
                const country = location.country_name;

                if (country === 'United States') {
                    updateTaxData('state', region);
                }
            }
        }
    }, []);

    const calculateTaxes = (email, isChecked = true) => {
        submitEmail(email, isChecked);

        const {
            income1099,
            incomeW2,
            filingStatus,
            state,
            expenses,
            industry
        } = taxData;

        const SETaxableIncome = income1099 - expenses;
        const SETax = SETaxableIncome * SELF_EMPLOYMENT_RATE;
        const SETaxDeduction = SETax * SELF_EMPLOYMENT_TAX_DEDUCTION_RATE;

        const standardDeduction = calcStandardDeduction(filingStatus);
        const adjustedGrossIncome =
            SETaxableIncome - SETaxDeduction - standardDeduction;

        const fedTaxFilingStatus =
            filingStatus === 'widow' ? 'marriedSeparately' : filingStatus;

        const fedIncomeTax = getBracketTax(
            adjustedGrossIncome,
            FED_TAX_BRACKETS[fedTaxFilingStatus]
        );

        const stateFilingStatusMap = {
            marriedSeparate: 'single',
            single: 'single',
            householdHead: 'marriedJointly',
            married: 'marriedJointly',
            widow: 'single'
        };

        const stateFilingStatus =
            stateFilingStatusMap[filingStatus] || 'single';

        const stateIncomeTax =
            STATE_TAX_BRACKETS[state][stateFilingStatus] !== null
                ? getBracketTax(
                      adjustedGrossIncome,
                      STATE_TAX_BRACKETS[state][stateFilingStatus]
                  )
                : 0;

        const totalTax = Math.max(0, SETax + fedIncomeTax + stateIncomeTax);

        const formData = {
            state,
            filingStatus,
            income1099,
            incomeW2,
            yearlyBusinessExpenses: expenses,
            industry,
            SETaxableIncome,
            SETax,
            SETaxDeduction,
            standardDeduction,
            adjustedGrossIncome,
            fedIncomeTax,
            stateIncomeTax,
            totalTax,
            email,
            isChecked
        };

        setCalculationData(formData);

        AnalyticsService.trackClick(
            AnalyticsEvents.tax_calculator_submit_form,
            {
                data: formData,
                company_type: formData.industry
            }
        );
        setSubmitted(true);
    };

    const onReturn = () => {
        AnalyticsService.trackClick(AnalyticsEvents.tax_calculator_return);
        setSubmitted(false);
    };

    return (
        <FeatureBlockSection customClass="tax-calculator-hero">
            <FeatureBlockContent>
                <div className="tax-calculator-hero__background"></div>
                <div className="tax-calculator-hero__text-container">
                    <HBText
                        tag="h3"
                        type="subtitle-s"
                        customClass="tax-calculator-hero__pre-title"
                    >
                        {t('taxCalculator.hero.preTitle')}
                    </HBText>
                    <HBText
                        tag="h1"
                        type="title-l"
                        customClass="tax-calculator-hero__title"
                    >
                        {USState && t(`states.${USState}`)}{' '}
                        {t('taxCalculator.hero.title')}
                    </HBText>
                    <HBText
                        tag="h2"
                        type="body-xl"
                        customClass="tax-calculator-hero__subtitle"
                    >
                        {t('taxCalculator.hero.subtitle')}
                    </HBText>

                    {submitted ? (
                        <TaxCalculatorResults
                            onReturn={onReturn}
                            calculationData={calculationData}
                        />
                    ) : (
                        <TaxCalculator
                            taxData={taxData}
                            updateTaxData={updateTaxData}
                            onSubmit={calculateTaxes}
                        />
                    )}

                    <HBText
                        customClass="tax-calculator-hero__legal"
                        tag="p"
                        type="body-xs"
                    >
                        {t('taxCalculator.hero.legal')}
                    </HBText>

                    <HBMediaElement
                        customClass="tax-calculator-hero__float tax-calculator-hero__float--doc"
                        name="marketing_site/online-invoices/00_Hero/Desktop/Document.png"
                        alt=""
                    />
                    <HBMediaElement
                        customClass="tax-calculator-hero__float tax-calculator-hero__float--cal"
                        name="marketing_site/online-invoices/00_Hero/Desktop/Group_48096347.png"
                        alt=""
                    />

                    <Star className="tax-calculator-hero__star tax-calculator-hero__star--1" />
                    <Star className="tax-calculator-hero__star tax-calculator-hero__star--2" />
                    <Star className="tax-calculator-hero__star tax-calculator-hero__star--3" />
                    <Star className="tax-calculator-hero__star tax-calculator-hero__star--4" />
                    <Star className="tax-calculator-hero__star tax-calculator-hero__star--5" />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default TaxCalculatorHero;
