import { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import HBText from '@honeybook-ui/HBText/HBText';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './keep-exploring-block.scss';

const defaultLinkData = [
    {
        id: 'blog',
        url: '/blog',
        imageUrl:
            'marketing_site/home_page/keep-exploring/Badge_HoneyBook-Blog.png'
    },
    {
        id: 'businessAcademy',
        url: '//academy.honeybook.com/',
        imageUrl:
            'marketing_site/home_page/keep-exploring/Badge_Business-Academy.png'
    },
    {
        id: 'community',
        url: '//community.honeybook.com/',
        imageUrl: 'marketing_site/home_page/keep-exploring/Badge_Community.png'
    },
    {
        id: 'help',
        url: '//help.honeybook.com/en/',
        imageUrl:
            'marketing_site/home_page/keep-exploring/Badge_Help-Center.png'
    }
];

const handleLinkClick = source => {
    AnalyticsService.trackClick(AnalyticsEvents.keep_exploring_block, {
        source
    });
};

export interface LinkDataType {
    id: string;
    url: string;
    imageUrl?: string;
}

interface Props {
    customTitle?: string;
    customSubtitle?: string;
    linkData?: LinkDataType[];
}

const DiscoverLinkBlock = ({
    customTitle,
    customSubtitle,
    linkData = defaultLinkData
}: Props) => {
    const { t } = useTranslation();

    const { mediumUp } = useBreakpoint();

    return (
        <FeatureBlockSection
            customClass="keep-exploring-block"
            darkBackground
            smallHeight
        >
            <FeatureBlockContent>
                <HBText
                    tag="h2"
                    type="title-l"
                    customClass="keep-exploring-block__title"
                >
                    {customTitle ? customTitle : t('DiscoverLinkBlock.title')}
                </HBText>
                {customSubtitle && (
                    <HBText
                        tag="h3"
                        type="body-l"
                        customClass="keep-exploring-block__subtitle"
                    >
                        {customSubtitle}
                    </HBText>
                )}
                <div className="keep-exploring-box-container">
                    {mediumUp ? (
                        <DiscoverLinkBlockContainerDesktop
                            linkData={linkData}
                        />
                    ) : (
                        <DiscoverLinkBlockContainerMobile linkData={linkData} />
                    )}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(DiscoverLinkBlock);

interface TDiscoverLinkBlockContainer {
    linkData: LinkDataType[];
}

const DiscoverLinkBlockContainerDesktop = ({
    linkData
}: TDiscoverLinkBlockContainer) => {
    return (
        <>
            {linkData.map(link => (
                <DiscoverLinkBlockCard key={link.id} link={link} />
            ))}
        </>
    );
};

const DiscoverLinkBlockContainerMobile = ({
    linkData
}: TDiscoverLinkBlockContainer) => {
    return (
        <Swiper
            className="keep-exploring-block__swiper"
            slidesPerView={1.3}
            initialSlide={0}
            centeredSlides={true}
        >
            {linkData.map(link => (
                <SwiperSlide key={link.id}>
                    {<DiscoverLinkBlockCard link={link} />}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

const DiscoverLinkBlockCard = ({ link }) => {
    const { t } = useTranslation();
    return (
        <a
            key={link.id}
            target="_blank"
            className="keep-exploring-box__link"
            href={link.url}
            onClick={() => handleLinkClick(link.id)}
        >
            <div className="keep-exploring-box">
                <div className="keep-exploring-box__text-container">
                    {link.imageUrl && (
                        <HBMediaElement
                            customClass="keep-exploring-box__image"
                            name={link.imageUrl}
                        />
                    )}

                    {t(`DiscoverLinkBlock.${link.id}.title`, {
                        defaultValue: null
                    }) && (
                        <HBText
                            tag="h3"
                            type="title-s"
                            customClass="keep-exploring-box__title"
                        >
                            {t(`DiscoverLinkBlock.${link.id}.title`)}
                        </HBText>
                    )}
                    <HBText
                        tag="p"
                        type="body-s"
                        customClass="keep-exploring-box__description"
                    >
                        {t(`DiscoverLinkBlock.${link.id}.description`)}
                    </HBText>
                    <HBText
                        tag="p"
                        type="body-s"
                        customClass="keep-exploring-box__link-text"
                    >
                        {t(`DiscoverLinkBlock.${link.id}.linkText`)}{' '}
                        <HBIcon
                            customClass="keep-exploring-box__arrow"
                            name="nx-nx-arrow-right-42"
                        />
                    </HBText>
                </div>
            </div>
        </a>
    );
};
