import { useTranslation } from 'react-i18next';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import HBText from '../../honeybook-ui/HBText/HBText';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

import './designed-for-teams.scss';

const DesignedForTeamsBlock = () => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="designed-for-teams">
            <FeatureBlockContent>
                <div className="designed-for-teams__container">
                    <div className="designed-for-teams__text-container">
                        <div className="designed-for-teams__text">
                            <HBText
                                tag="h2"
                                type="title-m"
                                customClass="designed-for-teams__title"
                            >
                                {t('designedForTeams.title')}
                            </HBText>
                            <HBText
                                tag="h3"
                                type="subtitle-l"
                                customClass="designed-for-teams__subtitle"
                            >
                                {t('designedForTeams.subtitle')}
                            </HBText>
                            <HBText
                                tag="p"
                                type="body-m"
                                customClass="designed-for-teams__description"
                            >
                                {t('designedForTeams.description')}
                            </HBText>
                            <a
                                href="/p/for-teams"
                                className="hbui-button hbui-button--medium hbui-button--primary--dark"
                            >
                                {t('designedForTeams.CTAText')}
                            </a>
                        </div>
                    </div>
                    <div className="designed-for-teams__images">
                        <HBMediaElement
                            name="marketing_site/home_page/teams/Rectangle_3319.png"
                            customClass="designed-for-teams__image1"
                        />
                        <HBMediaElement
                            name="marketing_site/home_page/teams/Frame_48096373.png"
                            customClass="designed-for-teams__image2"
                        />
                    </div>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default DesignedForTeamsBlock;
