export interface StaticPage {
    pageContext: {
        resultKey: string;
    };
}
export type TImageBlock = {
    publicId: string;
    width?: number;
    customClass?: string;
};

export type TOGTags = {
    url?: string;
    title?: string;
    description?: string;
    image?: string;
    noIndex?: boolean;
    lang?: 'en' | 'es';
};

export type TFAQ = {
    id: string;
    answer?: string;
};

export interface TMediaFlags {
    type?: string;
    forceImgTag?: boolean;
    width?: number | string;
    height?: number;
    crop?: string;

    alt?: string;
    customClass?: string;
    x?: number;
    y?: number;
    gravity?: string;
    radius?: string;
    fetchFormat?: string;
    flags?: string;
    quality?: string;
    dpr?: number | string;
    responsive?: boolean;
    autoPlay?: boolean;
    muted?: boolean;
    loop?: boolean;
    controls?: boolean;
    playsInline?: boolean;
    zoom?: number;
}

export interface IBlockContent {
    id?: string;
    transKey: string;
    customClass?: string;
    testimonial?: {
        avatar: string;
        memberName: string;
    };
    images: TImageBlock[];
}

export type TCssAbsoluteUnit = 'cm' | 'mm' | 'in' | 'px' | 'pt' | 'pc';

export type TCssRelativeUnit =
    | 'em'
    | 'ex'
    | 'ch'
    | 'rem'
    | 'vw'
    | 'vh'
    | 'vmin'
    | 'vmax'
    | '%';

export type TCssUnits = TCssAbsoluteUnit | TCssRelativeUnit;

export enum TAdditionalDataForClickStartTrialModules {
    CHECK_LIST_HOME_PAGE = 'featuresCheckListHome',
    CHECK_LIST_GET_STARTED_PAGE = 'featuresCheckListGetStarted',
    CHECK_LIST_FEATURES_PAGE = 'featuresCheckListFeatures',
    CHECK_LIST_CRM_PAGE = 'featureCheckListCrm'
}

export type TPricingPlans = 'starter' | 'essentials' | 'premium';

export interface TPlansForDisplay {
    plan: TPricingPlans;
    originalPrice: number;
    priceAfterDiscount?: string | number;
    discountPercentage?: number;
}

export interface TTabsContent {
    name: string;
    id: string;
    panel: JSX.Element;
}

export interface TTaxData {
    state: string;
    filingStatus: string;
    income1099: number;
    incomeW2: number;
    expenses: number;
    industry: string;
    businessType: string;
}

export interface ICXCompanyConfiguration {
    account_id: string;
    company_id: string;
    placement_id: string;
    title: string;
    background_color: string;
    has_logo: boolean;
    has_icon: boolean;
    logo: {
        show: boolean;
        type: string;
        logo_url: string;
        icon_url: string;
    };
    brand: {
        brand_color: string;
        text_color: string;
    };
    redirect_to?: { domain: string; prefix: string };
    sms_consent_disabled?: boolean;
}
