import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FeatureBlockSection from '../../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import HBVideo from '@honeybook-ui/HBVideo/HBVideo';
import HBText from '@honeybook-ui/HBText/HBText';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './best-experience-block.scss';

const handleTestButtonAnalytics = () => {
    AnalyticsService.trackClick(AnalyticsEvents.education_test_button, {
        source: 'best experience block'
    });
};

const BestExperienceBlock = ({ title, subtitle, videoId = '742638848' }) => {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="best-experience-block">
            <FeatureBlockContent customClass="best-experience-block__text-container">
                <HBText tag="h2" type="title-m">
                    {title || t('BestExperienceBlock.title')}
                </HBText>
                <HBText tag="h3" type="body-l">
                    {subtitle || t('BestExperienceBlock.subtitle')}
                </HBText>
                <div className="best-experience-block__button-container">
                    <RegistrationForm
                        source="best experience block"
                        size="large"
                        showNoCcRequired
                    />
                    <a
                        href="https://www.bigmarker.com/honeybook/Discovering-HoneyBook-b6f11fb2d8fd7d6e2beea00f?utm_bmcr_source=site1"
                        className="best-experience-block__education-button hbui-button hbui-button--large"
                        onClick={handleTestButtonAnalytics}
                    >
                        <span className="hbui-button__text">
                            {t('educationTest.buttonText')}
                        </span>
                    </a>
                </div>
            </FeatureBlockContent>
            <FeatureBlockContent customClass="best-experience-block__media-container">
                <HBVideo
                    autoplay
                    loop
                    muted
                    videoId={videoId}
                    title={t('BestExperienceBlock.title')}
                    padding="66.18% 0 0 0"
                    iframeClass="best-experience-block__video"
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

BestExperienceBlock.propTypes = {
    videoId: PropTypes.string
};

export default BestExperienceBlock;
