import * as queryString from 'query-string';
import fetch from 'node-fetch';

import type { RequestInfo, RequestInit } from 'node-fetch';
import type { GeoData } from './GeolocationService';

import getApiUrl from '../env';
import { trackError } from '../utils/error.utils';
import type { TTemplateData } from '../components/PublicTemplatePreview/type';
import UtilsService from './UtilsService';
import { ICXCompanyConfiguration } from '../types/util-types';

export interface ValidateOnboardingResponse {
    is_valid: boolean;
    domain_not_valid: boolean;
    redirect_to_url: string;
}

export interface RegistrationData {
    vendor_email?: string;
    vendor_name?: string;
    registration_variant:
        | 'paywall'
        | 'homepage'
        | 'homepage_facebook'
        | 'community';
    geo_data?: GeoData;
    fullName?: string;
    is_sso: boolean;
    sso_type?: string;
    auth_token?: string;
    auth_code?: string;
    profile_img_url?: string;
    pricingPlan?: unknown; // is it boolean?
    server_response?: ValidateOnboardingResponse;
}

export interface SignupUserResponse {
    api_version: string;
    authentication_token: string;
    _id: string;
}

export interface InvoiceGeneratorResponse {
    secure_url: string;
}

export default class ApiService {
    private static readonly MAX_TRY_COUNT_FOR_REDIRECT_TO_CLIENT_PORTAL_CONFIGURATION = 3;

    static _apiFetch = <T>(
        key: RequestInfo,
        data: RequestInit & { isJsonp?: boolean }
    ): Promise<T> => {
        return fetch(key, data).then(async response => {
            if (response.status === 204) return '';
            if (!response.ok) {
                throw response;
            }
            if (data.isJsonp) {
                const responseText = await response.text();
                return this.extractJsonFromJsonP(responseText);
            }
            return response.json();
        });
    };

    static validateOnboarding = (registrationData: RegistrationData) => {
        const fetchKey = `${getApiUrl()}/api/validate_onboarding? ${queryString.stringify(
            registrationData
        )}`;
        return this._apiFetch<ValidateOnboardingResponse>(fetchKey, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
    };

    static ssoSignupUser = (
        ssoData: Record<string, unknown>
    ): Promise<SignupUserResponse> => {
        return this._apiFetch(
            `${getApiUrl()}/api/v2/users/oauth/sso_signup_user`,
            {
                method: 'POST',
                body: JSON.stringify(ssoData),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ) as Promise<SignupUserResponse>;
    };

    static createEmailSignature = data => {
        interface Res {
            success?: boolean;
            lead_email_signature_id?: string;
            error?: boolean;
            error_message?: string;
            error_type?: string;
            is_timeout?: boolean;
            note?: string;
        }

        return this._apiFetch(
            `${getApiUrl()}/api/v2/lead_email_signature_generator`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then((res: Res) => {
            if (res.error) {
                throw new Error(res.error_message);
            } else {
                return res;
            }
        });
    };

    static getEmailSignatureById(id: string) {
        const url =
            'https://s3.amazonaws.com/' +
            process.env.S3_email_signature_bucket +
            '/' +
            id +
            '.html';
        return fetch(url, { method: 'GET' }).then(response => {
            if (response.status === 200) {
                return response.text();
            } else {
                throw new Error('failed to fetch email signature');
            }
        });
    }

    static uploadImage = imageData => {
        const url =
            'https://api.cloudinary.com/v1_1/honeybook-landing/image/upload';
        return this._apiFetch(url, {
            method: 'POST',
            body: imageData
        });
    };

    static getFeedbackData = (clientFeedbackId: string) => {
        interface Res {
            company_name: string;
            member_name: string;
            company_logo_url: string;
            feedback_submitted: boolean;
            feedback_request_prompt: string;
            error_message?: string;
        }

        const url = `${getApiUrl()}/api/v2/feedback/${clientFeedbackId}`;
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        return ApiService._apiFetch(url, options)
            .catch(err => {
                trackError(err);
            })
            .then((res: Res) => {
                if (res?.error_message) {
                    trackError(res.error_message);
                    return null;
                } else {
                    return res;
                }
            });
    };

    static async fetchClientPortalConfiguration(
        url: string
    ): Promise<ICXCompanyConfiguration> {
        return this._apiFetch<ICXCompanyConfiguration>(url, {
            method: 'GET',
            isJsonp: true
        });
    }

    static async handleRedirectTo({
        clientPortalConfiguration,
        tryCount = 0
    }: {
        clientPortalConfiguration: ICXCompanyConfiguration;
        tryCount?: number;
    }) {
        if (
            !clientPortalConfiguration.redirect_to ||
            tryCount >=
                this.MAX_TRY_COUNT_FOR_REDIRECT_TO_CLIENT_PORTAL_CONFIGURATION
        ) {
            return clientPortalConfiguration;
        }
        const { domain, prefix } = clientPortalConfiguration.redirect_to;
        const domainForUrl = UtilsService.getClientPortalConfigurationAwsDomain(
            {
                domain
            }
        );
        const awsBucket = UtilsService.getClientPortalAwsBucket();
        const companyConfigurationJsonpUrl = `https://s3.amazonaws.com/${awsBucket}/${domainForUrl}/${prefix}_configuration.js?xhr=1`;
        clientPortalConfiguration = await this.fetchClientPortalConfiguration(
            companyConfigurationJsonpUrl
        );

        return this.handleRedirectTo({
            clientPortalConfiguration,
            tryCount: tryCount + 1
        });
    }

    static async getClientPortalConfiguration(
        templateId: string
    ): Promise<ICXCompanyConfiguration> {
        // fetching client portal configuration
        const clientPortalAwsDomain =
            UtilsService.getPublicConfigurationAwsDomain();
        let clientPortalConfiguration =
            await this.fetchClientPortalConfiguration(
                `${clientPortalAwsDomain}/ptc/redirect_files/${templateId}.html`
            );

        // when redirect_to is present, we need to fetch the configuration from target aws bucket
        if (clientPortalConfiguration.redirect_to) {
            clientPortalConfiguration = await this.handleRedirectTo({
                clientPortalConfiguration
            });
        }
        return clientPortalConfiguration;
    }

    static async getTemplateData(templateId: string): Promise<TTemplateData> {
        const clientPortalConfiguration =
            await this.getClientPortalConfiguration(templateId);

        // data we need to fetch the public template data from s3
        const { company_id } = clientPortalConfiguration;
        const s3UrlPrefix = UtilsService.getS3UrlPrefixForPublicTemplateData();
        const envName = UtilsService.getEnvNameForS3();
        const s3Url = `${s3UrlPrefix}/exports/public_flows/${envName}/${company_id}/${templateId}.json${
            UtilsService.isDev() ? '?local=true' : ''
        }`;

        // fetching public template data
        return this._apiFetch<TTemplateData>(s3Url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
    }

    static sendFeedbackData = (
        clientFeedbackId: string,
        rating_score: number,
        rating_comment: string
    ) => {
        const url = `${getApiUrl()}/api/v2/feedback/${clientFeedbackId}`;

        const feedbackData = {
            rating_score,
            rating_comment
        };

        const options = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(feedbackData)
        };

        return ApiService._apiFetch(url, options);
    };

    private static extractJsonFromJsonP(jsonPText: string) {
        // eslint-disable-next-line no-eval
        return eval(
            jsonPText.slice(
                jsonPText.indexOf('('),
                jsonPText.lastIndexOf(')') + 1
            )
        );
    }
}
