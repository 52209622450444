import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import FeatureChecklistHero from '../Hero/FeatureChecklistHero/FeatureChecklistHero';
import StatsAnimation from '../Hero/StatsAnimation/StatsAnimation';
import FeatureValuePropsBlock from '../FeatureBlocks/FeatureValuePropsBlock/FeatureValuePropsBlock';
import TestimonialCarouselBlock from '../FeatureBlocks/TestimonialCarouselBlock/TestimonialCarouselBlock';
import BestExperienceBlock from '../FeatureBlocks/BestExperienceBlock/BestExperienceBlock';
import ShowcaseServicesBlock from '../../components/FeatureBlocks/ShowcaseServicesBlock/ShowcaseServicesBlock';
import PromoBlockLeft from '../FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import FeatureIntegrationBlock from '../FeatureBlocks/FeatureIntegrationBlock/FeatureIntegrationBlock';
import SmarterSystemBlock from '../FeatureBlocks/SmarterSystemBlock/SmarterSystemBlock';
import MobileReviewsBlock from '../FeatureBlocks/MobileReviewsBlock/MobileReviewsBlock';
import BuiltForTeamsBlock from '../FeatureBlocks/BuiltForTeamsBlock/BuiltForTeamsBlock';
import TestimonialCarouselCardBlock from '../FeatureBlocks/TestimonialCarouselCardBlock/TestimonialCarouselCardBlock';
import FeaturedIn from '../FeaturedIn/FeaturedIn';
import DiscoverLinkBlock from '../FeatureBlocks/DiscoverLinkBlock/DiscoverLinkBlock';
import { carouselSlideData } from '../../templates/canada';
import { TAdditionalDataForClickStartTrialModules } from '../../types/util-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import FeatureChecklistSlimHero from '../Hero/FeatureChecklistHero/FeatureChecklistSlimHero';

interface Props {
    promoKey: string;
    promoCode: string;
    stats: {
        title: JSX.Element;
        subtitle: JSX.Element;
    }[];
    featuredItems: {
        imgUrl: string;
        alt: string;
    }[];
}

const HomeContentControl = ({
    promoKey,
    promoCode,
    stats,
    featuredItems
}: Props) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    return (
        <>
            {breakpoints.mediumUp ? (
                <FeatureChecklistSlimHero />
            ) : (
                <FeatureChecklistHero
                    title={t('homeHero.title')}
                    subtitle={t('homeHero.subtitle')}
                    module={
                        TAdditionalDataForClickStartTrialModules.CHECK_LIST_HOME_PAGE
                    }
                />
            )}
            <StatsAnimation stats={stats} />
            <FeatureValuePropsBlock
                hideCta={true}
                title={t('featureValueProps.title')}
            />
            <TestimonialCarouselBlock slideData={carouselSlideData} />
            <BestExperienceBlock videoId="798848864" />
            <ShowcaseServicesBlock videoUrl="marketing_site/homepage2/your-services" />
            <PromoBlockLeft
                title={t(`${promoKey}.promoBlockLeft.title`)}
                subtitle={t(`${promoKey}.promoBlockLeft.subtitle`, {
                    defaultValue: null
                })}
                buttonStyle="primary--bright"
                darkTheme
                source="promo block left - dark"
                promoCode={promoCode}
            />
            <FeatureIntegrationBlock
                title={t('integrationsBlock.title')}
                subtitle={t('integrationsBlock.subtitle')}
            />
            <SmarterSystemBlock />
            <MobileReviewsBlock />

            <BuiltForTeamsBlock />

            <TestimonialCarouselCardBlock />
            <FeaturedIn featuredItems={featuredItems} />
            <DiscoverLinkBlock />
            <PromoBlockLeft
                title={t('PromoBlockLeftYellow.title')}
                customClass="promo-block-left--yellow"
                source="promo block left - yellow"
                buttonStyle="primary--dark"
            />
        </>
    );
};

export default memo(HomeContentControl);
