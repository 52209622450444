import { useTranslation } from 'react-i18next';
import ImageTextElementsBlock from '../ImageTextElementsBlock/ImageTextElementsBlock';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './teams-block.scss';

const TeamsBlock = () => {
    const { t } = useTranslation();

    const imageTextElements = [
        {
            title: t(`bringYourTeam.manage.title`),
            text: t(`bringYourTeam.manage.description`),
            imagePath: 'marketing_site/home_page/manage.png'
        },
        {
            title: t(`bringYourTeam.winClients.title`),
            text: t(`bringYourTeam.winClients.description`),
            imagePath: 'marketing_site/home_page/win.png'
        },
        {
            title: t(`bringYourTeam.trackPerformance.title`),
            text: t(`bringYourTeam.trackPerformance.description`),
            imagePath: 'marketing_site/home_page/performance.png'
        }
    ];

    return (
        <ImageTextElementsBlock
            customClass="teams-block"
            title={t(`bringYourTeam.title`)}
            imageTextElements={imageTextElements}
        >
            <div className="teams-block__buttons-container">
                <RegistrationForm
                    size="large"
                    source="bring your team"
                    buttonStyle="primary--dark"
                />
                <a
                    href="/p/for-teams"
                    className="hbui-button hbui-button--large hbui-button--secondary"
                >
                    {t('general.learnMore')}
                </a>
            </div>
        </ImageTextElementsBlock>
    );
};

export default TeamsBlock;
