import { graphql } from 'gatsby';
import { usePromoCode } from '../hooks/usePromoCode';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../components/FeatureBlockElements';

import '../styles/free-account-migration.scss';

import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import MigrationStepsBlock from '../components/FeatureBlocks/MigrationStepsBlock/MigrationStepsBlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import TeamOfExpertsBlock from '../components/FeatureBlocks/TeamOfExpertsBlock/TeamOfExpertsBlock';
import RegistrationForm from '../components/RegistrationForm/RegistrationForm';
import BlogLinkBlock from '../components/BlogLinkBlock/BlogLinkBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';

const faqs = [
    {
        id: 'migrationWhatIs'
    },
    {
        id: 'migrationCost'
    },
    {
        id: 'migrationNotIncluded'
    },
    {
        id: 'migrationHowLong'
    },
    {
        id: 'migrationFormats'
    },
    {
        id: 'migrationWhereToUpload'
    },
    {
        id: 'migrationBidingTime',
        answer: '#TEXT# <a href="/product-education">#LINK_TEXT#</a>'
    },
    {
        id: 'migrationChange'
    },
    {
        id: 'migrationTransferClients',
        answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2286455-adding-your-clients-to-honeybook">#LINK_TEXT#</a> #TEXT# <a href="mailto:concierge@honeybook.com">#LINK_TEXT#</a>'
    },
    {
        id: 'migrationSelfSetup',
        answer: '#TEXT# <a href="https://help.honeybook.com/en/">#LINK_TEXT#</a> #TEXT# <a href="https://www.bigmarker.com/honeybook/HoneyBook-Basics-Introduction-to-Your-New-Account">#LINK_TEXT#</a>'
    }
];

const blogLinks = [
    {
        titleId: 'migrationPage.blogLinks.blogs.blog1',
        imagePath: 'marketing_site/blog-images/witch_to_hb.jpg',
        url: '/blog/honeybook-setup'
    },
    {
        titleId: 'migrationPage.blogLinks.blogs.blog2',
        imagePath: 'marketing_site/blog-images/10_reasons.jpg',
        url: '/blog/honeybook-invoice'
    },
    {
        titleId: 'migrationPage.blogLinks.blogs.blog3',
        imagePath: 'marketing_site/blog-images/laptop.jpg',
        url: '/blog/honeybook-contracts'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/free-account-migration',
    title: 'Free Account Migration',
    description:
        'Free account migration makes your transition onto HoneyBook seamless, allowing you to focus on hitting your business goals. Try free for 7 days. '
};

function FreeAccountMigrationTemplate() {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    return (
        <div className="account-migration-lp">
            <Layout ogTags={ogTags}>
                <WhiteHero
                    image="marketing_site/account-migration/free-account-migration-hero-image-desktop.png"
                    mobileImage="marketing_site/account-migration/free-account-migration-hero-image-mweb.png"
                    mainTitle={t('heroAccountMigration.title')}
                    description={t('heroAccountMigration.subtitle')}
                    imageAltTag="files and documents"
                    showRegistration
                />

                <MigrationStepsBlock />

                <TeamOfExpertsBlock />

                <FeatureBlockSection
                    lightBackground
                    customClass="account-migration-lp__booking-clients"
                >
                    <FeatureBlockContent>
                        <FeatureBlockText
                            fullWidth
                            preTitle={t(
                                `migrationPage.bookingClients.preTitle`
                            )}
                            primary={t(`migrationPage.bookingClients.title`)}
                        />
                        <div className="feature-block__signup-wrapper">
                            <RegistrationForm
                                source="interstitial"
                                size="large"
                                showNoCcRequired
                            />
                        </div>
                    </FeatureBlockContent>
                </FeatureBlockSection>

                <FAQBlock title={t(`migrationPage.faqs.title`)} faqs={faqs} />

                <BlogLinkBlock
                    title={t('migrationPage.blogLinks.title')}
                    blogs={blogLinks}
                />

                <PromoBlockLeft
                    customClass="promo-block-left--yellow"
                    title={t(`${promoCode}.promoBlockLeft.title`)}
                    subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                        defaultValue: null
                    })}
                    buttonStyle="primary--dark"
                    source="promo block left - yellow"
                    promoCode={promoCode}
                />
            </Layout>
        </div>
    );
}

export default FreeAccountMigrationTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "freeAccountMigration", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
