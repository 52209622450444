import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';

import PictureAndListBlock from '../components/FeatureBlocks/PictureAndListBlock/PictureAndListBlock';
import FeatureGridBlock from '../components/FeatureBlocks/FeatureGridBlock/FeatureGridBlock';
import ThinkOfSwitchingBlock from '../components/FeatureBlocks/ThinkOfSwitchingBlock/ThinkOfSwitchingBlock';
import TestimonialsContainer from '../components/TestimonialsContainer/TestimonialsContainer';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import VerticalTemplatesIntro from '../components/FeatureBlocks/VerticalTemplatesIntro/VerticalTemplatesIntro';
import VerticalTemplatesBlock from '../components/FeatureBlocks/VerticalTemplatesBlock/VerticalTemplatesBlock';
import VerticalEmailTemplatesBlock from '../components/FeatureBlocks/VerticalEmailTemplatesBlock/VerticalEmailTemplatesBlock';

import FAQStore from '../stores/FAQStore';

import '../styles/verticals.scss';

const imageTextElements = [
    {
        title: 'HealthCoachPage.imageTextElements.Streamline.title',
        text: 'HealthCoachPage.imageTextElements.Streamline.body',
        imagePath: 'marketing_site/vertical-lps/streamlined_icon.svg',
        altText: 'HealthCoachPage.imageTextElements.Streamline.title',
        mediaFlags: { forceImgTag: true, width: 50 }
    },
    {
        title: 'HealthCoachPage.imageTextElements.brand.title',
        text: 'HealthCoachPage.imageTextElements.brand.body',
        imagePath: 'marketing_site/vertical-lps/showcase_icon.svg',
        altText: 'HealthCoachPage.imageTextElements.brand.title',
        mediaFlags: { forceImgTag: true, width: 50 }
    },
    {
        title: 'HealthCoachPage.imageTextElements.booking.title',
        text: 'HealthCoachPage.imageTextElements.booking.body',
        imagePath: 'marketing_site/vertical-lps/booked_icon.svg',
        altText: 'HealthCoachPage.imageTextElements.booking.title',
        mediaFlags: { forceImgTag: true, width: 50 }
    }
];

const quotes = [
    {
        image: 'marketing_site/health-coach-lp/image1.jpg',
        text: 'HealthCoachPage.quotes.quote1.text',
        name: 'Erica Antonetti',
        description: 'HealthCoachPage.quotes.quote1.description'
    },
    {
        image: 'marketing_site/health-coach-lp/image2.jpg',
        text: 'HealthCoachPage.quotes.quote2.text',
        name: 'Becky Mollencamp',
        description: 'HealthCoachPage.quotes.quote2.description'
    },
    {
        image: 'marketing_site/health-coach-lp/image3.jpg',
        text: 'HealthCoachPage.quotes.quote3.text',
        name: 'Joel Melendrez',
        description: 'HealthCoachPage.quotes.quote3.description'
    }
];

const templateBlocks = [
    'invoiceTemplates',
    'contractTemplates',
    'brocureTemplates',
    'proposalTemplates',
    'questionnarieTemplates'
];

const templateBlockLinks = {
    invoiceTemplates: '/free-invoice-templates/coach'
};

const templateFeatureImages = {
    invoiceTemplates: {
        name: 'marketing_site/vertical-lps/template-images/vertical-groups/Group%202%20-%20Coaches/Group_75_Copy_1.png'
    },
    contractTemplates: {
        name: 'marketing_site/vertical-lps/template-images/vertical-groups/Group%202%20-%20Coaches/coaching_contract_final.png'
    },
    brocureTemplates: {
        name: 'marketing_site/vertical-lps/template-images/vertical-groups/Group%202%20-%20Coaches/Group_73_new.png'
    },
    proposalTemplates: {
        name: 'marketing_site/vertical-lps/template-images/vertical-groups/Group%202%20-%20Coaches/Group_77_Copy_4.png'
    },
    questionnarieTemplates: {
        name: 'marketing_site/vertical-lps/template-images/group-42_3x.png'
    }
};

const faqs = [
    {
        id: 'healthCoachOverview',
        answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'healthCoachCRMHelp',
        answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2542771-video-tutorial-your-clients-experience-when-you-send-a-proposal">#LINK_TEXT#</a> #TEXT# <a href="/free-account-migration">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'healthCoachTools',
        answer: '#TEXT# <a href="/features">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'healthCoachHelp',
        answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'healthCoachTemplates',
        answer: '#TEXT# <a href="https://intercom.help/honeybook-5891e2a409ff/en/articles/2209099-using-templates">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'healthCoachSession',
        answer: '#TEXT# <a href="https://www.youtube.com/watch?v=teD20iJHtbk">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'healthCoachOthers',
        answer: '#TEXT# <a href="/features">#LINK_TEXT#</a> #TEXT# <a href="/reviews">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'healthCoachMasseuse'
    },
    {
        id: 'healthCoachDoula'
    },
    {
        id: 'healthCoachTherapist'
    },
    {
        id: 'healthCoachPTHelp',
        answer: '#TEXT# <a href="https://www.youtube.com/watch?v=iYAxvUBb_yQ">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'healthCoachPTOthers'
    }
];

// Add FAQs from generic store
const genericFAQs = ['busy', 'tutorials', 'HBCost'];
genericFAQs.map(faq => faqs.push(FAQStore[faq]));

const ogTags = {
    url: 'https://www.honeybook.com/health-coach-software',
    title: 'Health Coach Software',
    description:
        'Software for health coaches to grow your coaching business Manage client communication, contracts, and payments Get started with templates made for you'
};

const contentKey = 'HealthCoachPage';

function HealthCoachSoftwareTemplate({ pageContext: { isCtaTest } }) {
    const { t } = useTranslation();

    return (
        <div className="vertical-lp">
            <Layout ogTags={ogTags} isCtaTest={isCtaTest}>
                <WhiteHero
                    image="marketing_site/health-coach-lp/health_wellness_coach_hero.png"
                    mainTitle={t('HealthCoachPage.hero.title')}
                    description={t('HealthCoachPage.hero.subtitle')}
                    showRegistration
                />

                <ImageTextElementsBlock
                    lightBackground
                    customClass="dj-lp__image-text-block payments-block"
                    imageTextElements={imageTextElements}
                />

                <PictureAndListBlock
                    title="HealthCoachPage.featureTitleList.trackLeads.title"
                    listName="HealthCoachPage.featureTitleList.trackLeads.list"
                    image="marketing_site/health-coach-lp/module2_wellness_pipeline.png"
                />

                <PictureAndListBlock
                    title="HealthCoachPage.featureTitleList.communicateWithClients.title"
                    listName="HealthCoachPage.featureTitleList.communicateWithClients.list"
                    image="marketing_site/health-coach-lp/module3_health_wellness_workspace.png"
                    imageWidth={550}
                />

                <PictureAndListBlock
                    title="HealthCoachPage.featureTitleList.manageYourInquiries.title"
                    listName="HealthCoachPage.featureTitleList.manageYourInquiries.list"
                    image="marketing_site/health-coach-lp/module4_health_wellness_mobile.png"
                    imageWidth={415}
                />

                <ThinkOfSwitchingBlock
                    title="HealthCoachPage.CTAStrip.title"
                    preTitle="HealthCoachPage.CTAStrip.preTitle"
                />

                <FeatureGridBlock
                    title={t('HealthCoachPage.featureGridTitle')}
                    lightBackground
                />

                <VerticalTemplatesIntro contentKey={contentKey} />

                {templateBlocks.map(templateBlock => (
                    <VerticalTemplatesBlock
                        key={'health-coach-' + templateBlock}
                        contentKey={contentKey}
                        templateBlock={templateBlock}
                        templateFeatureImages={templateFeatureImages}
                        templateBlockLinks={templateBlockLinks}
                    />
                ))}

                <VerticalEmailTemplatesBlock contentKey={contentKey} />

                <TestimonialsContainer
                    customClass="dj-lp__testimonial"
                    quotes={quotes}
                />

                <FAQBlock title={t('HealthCoachPage.faqTitle')} faqs={faqs} />

                <FreeToTryBlockRebrand />
            </Layout>
        </div>
    );
}

export default HealthCoachSoftwareTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "healthCoach", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
