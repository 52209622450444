import React from 'react';
/* eslint-disable */
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../components/FeatureBlockElements';

import './tab-content.scss';

const TOSTabContent = () => {
    return (
        <FeatureBlockSection customClass="tos-tab-content tab-content">
            <FeatureBlockContent fullWidth>
                <div id="termsPage" className="terms-text">
                    <p className="date-revision">
                        Updated March 20th, 2025
                        <br />
                        (Review the previous version{' '}
                        <a
                            className="link-act"
                            href="/terms/terms-of-service-original"
                            title="HoneyBook Terms and Conditions"
                        >
                            here
                        </a>
                        )
                    </p>
                    <div className="terms-paragraph">
                        <p>
                            <b className="terms-marker">
                                Notice of Updated Terms of Service and Privacy
                                Policy
                            </b>
                            <br />
                            We have updated our Terms of Service and Privacy
                            Policy. These updated terms are effective for new
                            users of our Services as of March 20, 2025, and for
                            existing users of our Services as of April 22, 2025.
                            By continuing to use our Services after the
                            applicable effective date, you agree to the updated
                            Terms of Service and Privacy Policy.
                        </p>
                        <h2 className="tab-content__title--primary">
                            Welcome to HoneyBook!
                        </h2>

                        <p>
                            HoneyBook, Inc. (&ldquo;HoneyBook&rdquo;,
                            &ldquo;we&rdquo;, &ldquo;us&rdquo;,
                            &ldquo;our&rdquo;) provides its services (described
                            below) to you through its website located at
                            www.HoneyBook.com (the &ldquo;Site&rdquo;) and
                            through its mobile applications and related services
                            (collectively, such services, including any new
                            features and applications, and the Site, the
                            &ldquo;Service(s)&rdquo;), subject to the following
                            Terms of Service (as amended from time to time, the
                            &ldquo;Terms of Service&rdquo;). We reserve the
                            right, at our sole discretion, to change or modify
                            portions of these Terms of Service at any time. If
                            we do this, we will post the changes on this page
                            and will indicate at the top of this page the date
                            these terms were last revised. Any changes will
                            become effective when posted. Your continued use of
                            the Services after changes are posted constitutes
                            your acceptance of the new Terms of Service. You
                            should visit this page before using the Services
                            each time to see if there have been any changes to
                            these Terms of Services.
                        </p>

                        <p>
                            In addition, when using certain services, you will
                            be subject to any additional terms applicable to
                            such services that may be posted on the Service from
                            time to time, including, without limitation:
                            <ul className="terms-items">
                                <li>
                                    the Privacy Policy -{' '}
                                    <a
                                        className="link-act"
                                        href="https://www.honeybook.com/terms/privacy"
                                        title="the Privacy Policy"
                                    >
                                        https://www.honeybook.com/terms/privacy
                                    </a>
                                    , and
                                </li>
                                <li>
                                    the Affiliate Program{' '}
                                    <a
                                        className="link-act"
                                        href="https://www.honeybook.com/lp/referrals-tos"
                                        title="the Affiliate Program"
                                    >
                                        https://www.honeybook.com/lp/referrals-tos
                                    </a>
                                </li>
                            </ul>
                            All such terms are hereby incorporated by reference
                            into these Terms of Service.
                        </p>

                        <p>
                            PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS
                            THEY CONTAIN AN AGREEMENT TO ARBITRATE AND OTHER
                            IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS,
                            REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO
                            ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT YOU
                            SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND
                            FINAL ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE
                            PERMITTED TO PURSUE CLAIMS AGAINST HONEYBOOK ON AN
                            INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER
                            IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING,
                            (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF
                            (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY
                            RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT
                            BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US
                            RESOLVED BY A JURY OR IN A COURT OF LAW.
                        </p>

                        <h2 className="terms-title">
                            1. Access and Use of the Service
                        </h2>

                        <p>
                            <b className="terms-marker">
                                Services Description:
                            </b>{' '}
                            The Service is designed to provide booking,
                            invoicing, communication and project management
                            tools for and between those who use the Service to
                            help manage their business
                            (&ldquo;Businesses&rdquo;), their clients and
                            related parties (&ldquo;Clients&rdquo;), and the
                            Clients&rsquo; various other vendors
                            (&ldquo;Vendors&rdquo;). In some instances, a
                            Business may qualify as the Client of a Vendor.
                            HoneyBook may connect Clients to Businesses and/or
                            Vendors for the purpose of purchasing goods and/or
                            services. Businesses may use the Service to find and
                            book Vendors, manage their Clients, and send
                            invoices. Clients may use the service to find, hire
                            and work with various Businesses and Vendors.
                            Vendors and Businesses may use the Service to
                            promote and provide their goods and services to
                            Clients.{' '}
                        </p>

                        <p>
                            <b className="terms-marker">
                                Your Registration Obligations:
                            </b>{' '}
                            You may be required to register with HoneyBook in
                            order to access and use certain features of the
                            Service. If you choose to register for the Service,
                            you agree to provide and maintain true, accurate,
                            current and complete information about yourself as
                            prompted by the Service&rsquo;s registration form.
                            Registration data and certain other information
                            about you are governed by our Privacy Policy. If you
                            are under 13 years of age, you are not authorized to
                            use the Service, with or without registering. In
                            addition, if you are under 18 years old, you may use
                            the Service, with or without registering, only with
                            the approval of your parent or guardian.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Business Account Registration Requirements:
                            </b>{' '}
                            If you are a Business registering with HoneyBook,
                            you or the person or people submitting the
                            application must provide us with your business or
                            trade name, your physical address (and proof of
                            address), email, phone number, business license or
                            registration, URL, the nature of your business or
                            activities, and certain other information about you
                            that we may require from time to time. We may also
                            collect personal information (including name,
                            birthdate, and government-issued identification
                            number) about you and your beneficial owners,
                            principals, and your account administrator. Until
                            you have submitted, and we have reviewed and
                            approved all required information, your account will
                            be available to you on a preliminary basis only, and
                            we may terminate it at any time and for any reason.
                            At any time during your use of the Service, we may
                            require additional information from you to verify
                            beneficial ownership or control of the business,
                            validate information you provided, verify you or
                            your representative’s identity, and assess the risk
                            associated with your business. This additional
                            information may include business invoices, copies of
                            government-issued identification, business licenses,
                            or other information related to your business, its
                            beneficial owners or principals. If you use
                            HoneyBook Payments (defined below), we may also
                            request that you provide copies of financial
                            statements or records pertaining to your compliance
                            with these Terms of Service, or require you to
                            provide a personal or company guarantee. Your
                            failure to provide this information or material may
                            result in suspension or termination of your account.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Account Ownership Disputes:
                            </b>{' '}
                            If an account is created by you on behalf of a
                            Business, whether expressly or impliedly, the
                            Business shall be deemed the rightful owner of the
                            account. Ownership may be determined based on, but
                            not limited to, the Business's registered Employer
                            Identification Number (EIN), payment and banking
                            details associated with the account, domain-linked
                            email addresses, or other identifying business
                            information provided to HoneyBook. In the event of a
                            dispute regarding account ownership, HoneyBook
                            reserves the right, in its sole discretion and
                            without liability, to determine the rightful account
                            owner based on available evidence, and may require
                            documentation or further verification. Any
                            information, content, or data uploaded to the
                            account, including User Content, may be accessible
                            to the deemed account owner. HoneyBook shall not be
                            liable for any actions taken in resolving ownership
                            disputes, including suspending or transferring
                            account access.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Member Account, Password and Security:
                            </b>{' '}
                            You are responsible for maintaining the
                            confidentiality of your password and account, if
                            any, and are fully responsible for any and all
                            activities that occur under your password or
                            account. You agree to (a) immediately notify
                            HoneyBook of any unauthorized use of your password
                            or account or any other breach of security, and (b)
                            ensure that you exit from your account at the end of
                            each session when accessing the Service. HoneyBook
                            will not be liable for any loss or damage arising
                            from your failure to comply with this Section.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Modifications to Service:
                            </b>{' '}
                            HoneyBook reserves the right to modify or
                            discontinue, temporarily or permanently, the Service
                            (or any part thereof) with or without notice. You
                            agree that HoneyBook will not be liable to you or to
                            any third party for any modification, suspension or
                            discontinuance of the Service.{' '}
                        </p>

                        <p>
                            <b className="terms-marker">
                                General Practices Regarding Use and Storage:
                            </b>{' '}
                            You acknowledge that HoneyBook may establish general
                            practices and limits concerning use of the Service,
                            including without limitation the maximum period of
                            time that data or other content will be retained by
                            the Service and the maximum storage space that will
                            be allotted on HoneyBook&rsquo;s servers on your
                            behalf. You agree that HoneyBook has no
                            responsibility or liability for the deletion or
                            failure to store any data or other content
                            maintained or uploaded by the Service. You
                            acknowledge that HoneyBook reserves the right to
                            terminate accounts that are inactive for an extended
                            period of time. You further acknowledge that
                            HoneyBook reserves the right to change these general
                            practices and limits at any time, in its sole
                            discretion, with or without notice.{' '}
                        </p>

                        <p>
                            <b className="terms-marker">Mobile Services:</b> The
                            Service includes certain services that are available
                            via a mobile device, including (i) the ability to
                            upload content to the Service via a mobile device,
                            (ii) the ability to browse the Service and the Site
                            from a mobile device, (iii) the ability to access
                            certain features through an application downloaded
                            and installed on a mobile device, and (iv) the
                            ability to receive notifications, messages, and
                            updates on your mobile device (including via calls
                            and text messages) (collectively, the &ldquo;Mobile
                            Services&rdquo;). To the extent you access the
                            Service through a mobile device, your wireless
                            service carrier&rsquo;s standard charges, data rates
                            and other fees may apply. In addition, downloading,
                            installing, or using certain Mobile Services may be
                            prohibited or restricted by your carrier, and not
                            all Mobile Services may work with all carriers or
                            devices. By using the Services, you are also
                            consenting to be contacted by HoneyBook or one of
                            our partners about our (or one of our partners’)
                            services and products by telephone (on a recorded
                            line), automated calling, pre-recorded calling, text
                            message, email, fax, telephone or any means, even if
                            you have opted in to the National Do Not Call List,
                            any state equivalent Do Not Call List or the
                            internal Do Not Call List of any company. You do not
                            have to agree to receive such communications as a
                            condition of getting any services or products from
                            HoneyBook. In the event you no longer want to
                            receive communications from HoneyBook or one of its
                            partners you agree to notify us directly. In the
                            event you no longer want to receive communications
                            from HoneyBook or one of its partners you agree to
                            notify us directly at{' '}
                            <a href="mailto:concierge@honeybook.com">
                                concierge@honeybook.com
                            </a>
                            .
                        </p>

                        <h2 className="terms-title">2. Conditions of Use</h2>

                        <p>
                            <b className="terms-marker">HoneyBook Platform:</b>{' '}
                            HoneyBook offers a platform that helps connect
                            Clients, Vendors and Businesses (&ldquo;HoneyBook
                            Platform&rdquo;). HoneyBook is not a party to any
                            agreement between Clients and Vendors, Vendors and
                            Businesses, or Clients and Businesses. All dealings
                            are solely between the respective parties and
                            HoneyBook makes no representations and warranties on
                            behalf of any Clients, Vendors, or Businesses
                            (including but not limited to the cost, quality, or
                            timeliness of any goods or services provided by any
                            Vendors or Businesses) and will have no liability
                            for any interactions between Clients, Vendors or
                            Businesses. HoneyBook is not responsible for any
                            contracts or proposals between Clients, Businesses
                            and Vendors (or any combination thereof)
                            (collectively, "Vendor Contracts") that you upload
                            to the Service other than making such Vendor
                            Contracts available for review and signature
                            (including e-signature) as authorized by you.
                            Notwithstanding the foregoing, HoneyBook serves as
                            the limited authorized agent of the Vendor and
                            Business for the purpose of accepting payments from
                            Clients on behalf of Vendors and Businesses and is
                            responsible for transmitting such payments to the
                            Vendor or Business, and each Vendor and Business
                            hereby appoints HoneyBook as the Vendor&rsquo;s or
                            Businesses&rsquo;s limited agent solely for the
                            purpose of collecting payments made by Clients on
                            behalf of the Vendor or Business. HoneyBook has no
                            control over and does not guarantee the existence,
                            quality, safety, or legality of any goods or
                            services advertised by Vendors or Businesses; the
                            truth or accuracy of any advertisements; the ability
                            of Vendors to sell goods or services; the ability of
                            Clients to pay for any goods or services; or that a
                            Vendor, Business or Client will actually complete a
                            transaction. HoneyBook does not warrant or guarantee
                            that any goods or services offered through the
                            Service will meet a Client&rsquo;s requirements.
                        </p>

                        <p>
                            No Tax or Other Professional Advice: You acknowledge
                            and agree that HoneyBook does not provide legal,
                            financial, tax or other professional advice as part
                            of providing the Services, including with respect to
                            any estimated taxes calculated using HoneyBook’s tax
                            calculator. No action should be taken based upon any
                            information obtained through the Services without
                            first seeking independent professional advice from
                            an accountant, attorney, financial advisor, or other
                            person who is appropriately licensed and/or
                            qualified in the applicable field. Without limiting
                            the foregoing, you (not HoneyBook) are solely
                            responsible for (and you shall hold HoneyBook
                            harmless with respect to): (i) compliance with any
                            and all applicable laws, rules and regulations, (ii)
                            determination of any and all amounts you may owe to
                            the government or other third parties and full
                            payment thereof (including, without limitation, all
                            applicable taxes, penalties, and interest), and
                            (iii) any use you may make of the Services to assist
                            you with the foregoing.
                        </p>

                        <p>
                            <b className="terms-marker">User Conduct:</b> You
                            are solely responsible for all code, video, images,
                            information, data, text, software, music, sound,
                            photographs, graphics, messages or other materials
                            ("content") that you upload, post, publish or
                            display (hereinafter, “upload”) or email or
                            otherwise instruct HoneyBook to access, make
                            available to HoneyBook or use via the Service,
                            whether as a Client, Business, or Vendor. The
                            following are examples of the kind of content and/or
                            use that is illegal or prohibited by HoneyBook.
                            HoneyBook reserves the right to investigate and take
                            appropriate legal action against anyone who, in
                            HoneyBook’s sole discretion, violates this
                            provision, including without limitation, removing
                            the offending content from the Service, suspending
                            or terminating the account of such violators and
                            reporting you to the law enforcement authorities.
                            You agree to not use the Service to:
                        </p>

                        <ul className="terms-items">
                            <li>
                                email, upload, distribute, promote, or otherwise
                                make use of any content that (i) infringes any
                                intellectual property or other proprietary
                                rights of any party; (ii) you do not have a
                                right to upload under any law or under
                                contractual or fiduciary relationships; (iii)
                                contains software viruses or any other computer
                                code, files or programs designed to interrupt,
                                destroy or limit the functionality of any
                                computer software or hardware or
                                telecommunications equipment; (iv) poses or
                                creates a privacy or security risk to any
                                person; (v) constitutes unsolicited or
                                unauthorized advertising, promotional materials,
                                commercial activities and/or sales, “junk mail,”
                                “spam,” “chain letters,” “pyramid/ponzi
                                schemes,” “contests,” “sweepstakes,” or any
                                other form of solicitation; (vi) constitutes
                                child sex abuse material or otherwise exploits
                                or harms children; (vii) is sexual, adult,
                                erotic, or pornographic content or relates to
                                sexual services; (viii) refers to or incites
                                violence, or relates to weapons or firearms;
                                (ix) is unlawful, harmful, threatening, abusive,
                                harassing, tortious, excessively violent,
                                defamatory, vulgar, obscene, pornographic,
                                libelous, invasive of another’s privacy, hateful
                                racially, ethnically or otherwise objectionable;
                                or (x) in the sole judgment of HoneyBook, is
                                objectionable or which restricts or inhibits any
                                other person from using or enjoying the Service,
                                or which may expose HoneyBook or its users to
                                any harm or liability of any type;{' '}
                            </li>
                            <li>
                                {' '}
                                interfere with or disrupt the Service or servers
                                or networks connected to the Service, or disobey
                                any requirements, procedures, policies or
                                regulations of networks connected to the
                                Service; or
                            </li>

                            <li>
                                violate any applicable local, state, national or
                                international law, or any regulations having the
                                force of law;{' '}
                            </li>
                            <li>
                                impersonate any person or entity, or falsely
                                state or otherwise misrepresent your credentials
                                or your affiliation with a person or entity;{' '}
                            </li>
                            <li>
                                solicit personal information from anyone under
                                the age of 18;
                            </li>
                            <li>
                                harvest or collect email addresses or other
                                contact information of other users from the
                                Service by electronic or other means for the
                                purposes of sending unsolicited emails or other
                                unsolicited communications;{' '}
                            </li>
                            <li>
                                advertise or offer to sell or buy any goods or
                                services for any business purpose that is not
                                specifically authorized;{' '}
                            </li>
                            <li>
                                further or promote any criminal activity or
                                enterprise or provide instructional information
                                about illegal activities; or{' '}
                            </li>
                            <li>
                                obtain or attempt to access or otherwise obtain
                                any materials or information through any means
                                not intentionally made available or provided for
                                through the Service.
                            </li>
                        </ul>

                        <p>
                            To the extent permitted by applicable law, HoneyBook
                            takes no responsibility and assumes no liability for
                            any content or for any loss or damage resulting
                            therefrom, nor is HoneyBook liable for any mistakes,
                            defamation, slander, libel, omissions, falsehoods,
                            obscenity, pornography, or profanity you may
                            encounter when using the HoneyBook Services. Your
                            use of the HoneyBook Services is at your own risk.
                            In addition, these rules do not create any private
                            right of action on the part of any third party or
                            any reasonable expectation that the HoneyBook
                            Services will not contain any content that is
                            prohibited by such rules.
                        </p>

                        <p>
                            HoneyBook is not liable for any statements or
                            representations included in the content. HoneyBook
                            does not endorse any content, opinion,
                            recommendation, or advice expressed therein, and
                            HoneyBook expressly disclaims any and all liability
                            in connection with the content. To the fullest
                            extent permitted by applicable law, HoneyBook
                            reserves the right to remove, screen, or edit any
                            content posted or stored on the HoneyBook Services
                            at any time and without notice, including where such
                            content violates these Terms of Service or
                            applicable law, and you are solely responsible for
                            creating backup copies of and replacing any content
                            you post or store on the HoneyBook Services at your
                            sole cost and expense. Any use of the HoneyBook
                            Services in violation of the foregoing violates
                            these Terms of Service and may result in, among
                            other things, termination or suspension of your
                            rights to use the HoneyBook Services.
                        </p>

                        <p>
                            {' '}
                            <b className="terms-marker">Fees:</b> To the extent
                            the Service or any portion thereof is made available
                            for any fee, or you are a Client paying a Vendor or
                            Business for goods or services via the Service, you
                            will be required to provide HoneyBook information
                            regarding your credit card or other payment
                            instrument. You represent and warrant to HoneyBook
                            that such information is true and that you are
                            authorized to use the payment instrument. If you
                            have registered for the Services via a subscription
                            plan, you expressly acknowledge and agree that (a)
                            HoneyBook is authorized to charge your credit card
                            or other payment instrument in accordance with the
                            terms of your subscription plan for as long as your
                            subscription continues, and (b) your subscription is
                            continuous until you cancel it or HoneyBook suspends
                            or otherwise stops providing access to the site
                            and/or Services in accordance with these terms. You
                            will promptly update your account information with
                            any changes (for example, a change in your billing
                            address or credit card expiration date) that may
                            occur. As a Vendor, you may be required to select a
                            payment plan and you agree to pay a pre-selected
                            percentage of money you receive from purchases made
                            by Clients of your goods and services through the
                            Service, and you will pay HoneyBook a percentage of
                            each such transaction, as set forth in further
                            detail on the Service. If you dispute any charges by
                            HoneyBook you must let HoneyBook know within thirty
                            (30) days after the date that HoneyBook charges you.
                            If you dispute any charges by a Vendor, you must
                            contact the Vendor directly. As a Vendor, if you
                            agree to refund any fees to a Client, you are
                            responsible and will pay HoneyBook (and HoneyBook
                            may retain) any transaction costs associated with
                            such refund. We reserve the right to change
                            HoneyBook&rsquo;s prices. If HoneyBook does change
                            prices, HoneyBook will provide notice of the change
                            on the Site or in email to you, at HoneyBook&rsquo;s
                            option, at least 30 days before the change is to
                            take effect. If you have registered for the Services
                            via a subscription plan, your subscription plan may
                            be subject to fee adjustments, including automatic
                            fee increases, during your subscription term in
                            accordance with the terms of your subscription plan;
                            and where a fee adjustment applies to you, we will
                            charge or invoice you under the new price structure,
                            starting with the next billing period in the
                            subscription term, or otherwise in accordance with
                            the terms of your subscription plan. Your continued
                            use of the Service after the price change or fee
                            adjustments becomes effective constitutes your
                            agreement to pay the changed amount. You shall be
                            responsible for all taxes associated with the
                            Services other than U.S. taxes based on
                            HoneyBook&rsquo;s net income.
                        </p>
                        <p>
                            {' '}
                            If you are located in Canada, Stripe, our payment
                            processor, may obtain information from credit
                            agencies to verify your identity and this
                            information will be used in accordance with Stripe's
                            Privacy Policy
                            (https://stripe.com/en-ca/privacy).Payments
                            Platform: HoneyBook offers access to a payments
                            platform through which you may process payment
                            transactions with your Clients, Vendors, Businesses,
                            or other parties with whom you transact (“HoneyBook
                            Payments”). We use Stripe, Inc. (“Stripe”) as our
                            third party payments processor and platform provider
                            to facilitate HoneyBook Payments (the “Provider”).
                            We reserve the right to change the Provider at any
                            time, in which case you agree to take whatever steps
                            as we may advise in order to migrate the HoneyBook
                            Payments service to another Provider.
                        </p>

                        <p>
                            <b className="terms-marker">Chargebacks: </b>
                            To the extent you have received payment for goods or
                            services through the HoneyBook Platform
                            (&ldquo;Payee&rdquo;) from or on behalf of a Client,
                            Business or other party (&ldquo;Payor&rdquo;), the
                            amount of a transaction may be charged back or
                            reversed to Payee&rsquo;s payment account (a
                            &ldquo;Chargeback&rdquo;) if the transaction (a) is
                            disputed by the Payor, (b) is reversed for any
                            reason, (c) was not authorized or we have any reason
                            to believe that the transaction was not authorized,
                            or (d) is allegedly unlawful, suspicious, or in
                            violation of the terms of these Terms of Service.
                            Payee shall owe HoneyBook and will immediately pay
                            HoneyBook the amount of any Chargeback and any
                            associated fees, fines, or penalties assessed by any
                            processing financial institutions, or MasterCard,
                            Visa, American Express, Discover, and other payment
                            card networks, associations, or companies.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Authorization to Charge Payment Methods on File:{' '}
                            </b>
                            You, as Payee, authorize us to charge any payment
                            method we have on file for you in the amount of any
                            Chargeback; any other amount you owe us; any Losses
                            we may incur relating to your transactions, your use
                            of the Services, or your business; any obligations
                            you owe us, including under these Terms of Service
                            or any other agreement we have with you; or to fund
                            the Reserve. To execute such charges, you expressly
                            authorize us to (a) initiate individual or
                            reoccurring debit entries to any bank account we
                            have on file for you (e.g., ACH debits) and (b)
                            charge any credit or debit card account we have on
                            file for you. If any debit entry or charge is
                            rejected, you agree to immediately pay the amount
                            due directly to us and authorize us to initiate
                            other debit entries and charges at any time and from
                            time to time in any amount up to the amount due
                            until the amount due is paid in full. We are not
                            responsible for any amount you may incur from our
                            charges, including overdraft and overlimit fees.
                            Payment methods we have on file for you include
                            payment methods you identify to us and payment
                            methods we identify on our own. For purposes of this
                            and the next section, &ldquo;Losses&rdquo; include
                            Chargebacks, refunds, returns, shortfalls,
                            adjustments, settlements, losses, liabilities,
                            damages, fines, penalties, fees, costs and expenses.
                        </p>

                        <p>
                            <b className="terms-marker">Reserve: </b>
                            We may withhold funds from payments (including
                            payment processor payouts) to you, as Payee, and/or
                            designate an amount of funds that you must maintain
                            in a reserve account held by us
                            (&ldquo;Reserve&rdquo;) to pay any actual or
                            potential Losses we believe we may incur related to
                            your transactions, your use of the Services, your
                            business, or to secure the performance of your
                            obligations under any agreement between you and us,
                            including these Terms of Service. The Reserve will
                            be in an amount determined by us in our sole
                            discretion to cover actual or potential Losses we
                            may incur and current, past and future obligations
                            you may owe us. The Reserve may be raised, reduced
                            or removed at any time by us, in our sole
                            discretion. If you do not have sufficient funds in
                            your Reserve, we may fund the Reserve from any
                            payment method we have on file for you. You grant us
                            a security interest in and lien on any and all funds
                            held in the Reserve, and also authorize us to make
                            any withdrawals or debits from the Reserve, and
                            charge any payment method we have on file for you,
                            to cover any Losses, obligations and amounts you owe
                            us. You will execute any documents required by us to
                            perfect our security interest in any funds in the
                            Reserve or requested by us in connection with the
                            Reserve.
                        </p>

                        <p>
                            <b className="terms-marker">Setoff: </b>
                            In the event that there is an outstanding amount you
                            owe us, we may set off such amount from any payments
                            that would otherwise be made to you.
                        </p>

                        <p>
                            <b className="terms-marker">
                                1099 Tax Forms; Consent for Electronic Delivery:{' '}
                            </b>
                            As required by law, HoneyBook may in some cases need
                            to report information about your transactions using
                            the Services by filing Form 1099 with the IRS and
                            sending you a copy. By using the Services, you agree
                            to the following:
                            <ul className="terms-items">
                                <li>
                                    You consent to receiving an electronic copy
                                    of your Form 1099. To access your electronic
                                    Form 1099, you will need a computer with
                                    Internet access.
                                </li>
                                <li>
                                    Your consent to electronic delivery will
                                    apply to all future Forms 1099 unless you
                                    withdraw your consent.
                                </li>
                                <li>
                                    Any electronic Forms 1099 made available to
                                    you will remain available until you request
                                    that your HoneyBook account be closed and/or
                                    deleted.
                                </li>
                                <li>
                                    You can opt-out of receiving an electronic
                                    Form 1099 at any time, and instead receive a
                                    hard copy mailed to your physical address.
                                    Please note that, if you withdraw your
                                    consent, you will not be able to access your
                                    Forms 1099 electronically and you will only
                                    receive a paper copy. If you would still
                                    like to withdraw your consent, please
                                    contact{' '}
                                    <a
                                        href="mailto:concierge@honeybook.com"
                                        className="link-act"
                                        target="_blank"
                                        title="HoneyBook support"
                                    >
                                        concierge@honeybook.com
                                    </a>
                                    .
                                </li>
                                <li>
                                    Please ensure that your contact information
                                    is current by updating your account profile.
                                </li>
                                <li>
                                    For any questions about your Forms 1099 not
                                    answered here, please contact{' '}
                                    <a
                                        href="mailto:concierge@honeybook.com"
                                        className="link-act"
                                        target="_blank"
                                        title="HoneyBook support"
                                    >
                                        concierge@honeybook.com
                                    </a>
                                    .
                                </li>
                            </ul>
                        </p>

                        <p>
                            <b className="terms-marker">Power of Attorney: </b>
                            You appoint us and our designees your true and
                            lawful attorney in fact, with full power to take any
                            action in your name and place relating to any
                            amounts you owe us under any agreement you have with
                            us, including these Terms of Service, that we deem
                            advisable and consistent with the terms of such
                            agreement. You will timely execute and deliver to us
                            any power of attorney instrument we may require
                            evidencing our authority and power under this
                            section.
                        </p>

                        <p>
                            <b className="terms-marker">Due Diligence: </b>
                            You, as a Business or Vendor, agree to provide us
                            with any financial statements, balance sheets,
                            statements of income, bank and credit card account
                            information, and any other financial or business
                            information we may request within two (2) business
                            days of our request. Failure to provide this
                            information timely is a breach of these Terms of
                            Service and may result in establishing a Reserve or
                            raising the amount of the Reserve.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Special Notice for International Use; Export
                                Controls:{' '}
                            </b>
                            Software (defined below) available in connection
                            with the Service and the transmission of applicable
                            data, if any, is subject to United States export
                            controls. No Software may be downloaded from the
                            Service or otherwise exported or re-exported in
                            violation of U.S. export laws. Downloading or using
                            the Software is at your sole risk. Recognizing the
                            global nature of the Internet, you agree to comply
                            with all local rules and laws regarding your use of
                            the Service, including as it concerns online conduct
                            and acceptable content.
                        </p>

                        <p>
                            <b className="terms-marker">Commercial Use: </b>
                            Unless otherwise expressly authorized herein or in
                            the Service, you agree not to display, distribute,
                            license, perform, publish, reproduce, duplicate,
                            copy, create derivative works from, modify, sell,
                            resell, exploit, transfer or upload for any
                            commercial purposes, any portion of the Service, use
                            of the Service, or access to the Service.
                        </p>
                        <p>
                            <b className="terms-marker">Third Party Tools. </b>
                            The Services may contain certain features or
                            integrations leveraging third-party websites,
                            services, or technologies (“Third Party Tools”).
                            These Third Party Tools may have their own terms
                            that govern your use or access rather than or in
                            addition to these Terms. Furthermore, when you
                            connect to or access a Third Party Tool from your
                            account, those connections may allow the third party
                            to collect or share data about you. We are not
                            responsible for the practices or the content of any
                            Third Party Tools. By using the Services, you accept
                            and agree to abide by any such additional terms and
                            conditions associated with your use of access to
                            Third Party Tools. We encourage you to carefully
                            read and review the terms applicable to each Third
                            Party Tool that you access or use.
                        </p>
                        <p>
                            <b className="terms-marker">
                                Artificial Intelligence.{' '}
                            </b>
                            Additionally, portions of the Services may use
                            artificial intelligence (“AI”) tools and other
                            technology (the “AI Tools”). Certain of those tools
                            may allow you to generate text, audio, video, and
                            other content (“Output”) in response to prompts,
                            User Content, and other information or input
                            (“Input”), and some of the AI Tools may be Third
                            Party Tools. You acknowledge that your use of AI
                            Tools may involve access to your Inputs and Outputs
                            by the relevant Third Party Tool providers. You
                            understand that, due to the nature of the AI Tools,
                            Outputs may not be unique, and it is possible that
                            AI Tools may generate the same or similar Output for
                            other users if the Inputs are similar. You
                            acknowledge that the use of such AI Tools may affect
                            your ability to obtain or enforce any intellectual
                            property rights in or to the Output.
                        </p>
                        <p>
                            {' '}
                            We may also provide AI Tools that will allow you to
                            automate your use of our Services or your
                            communications with Clients.
                        </p>
                        <p>
                            {' '}
                            You acknowledge that AI is rapidly evolving and our
                            AI Tools may provide results or produce Output that
                            is inaccurate, unreliable, inappropriate,
                            infringing, or otherwise unsuitable or that does not
                            meet your expectations.
                        </p>
                        <p>
                            {' '}
                            You agree not to use AI Tools, AI Tool results, or
                            Output in a manner that may infringe upon or violate
                            the rights of any third party or violate any
                            applicable laws, rules, or regulations, and you
                            acknowledge and agree that (1) you are solely
                            responsible for vetting and evaluating the accuracy,
                            appropriateness, legality, and suitability for your
                            use of any AI Tool result or Output before using it,
                            including evaluating whether such use will infringe
                            upon or violate the rights of others; and (2) you
                            assume all risk associated with your use of AI
                            Tools, AI Tool results, and Output and that
                            HoneyBook will have no liability for such use.
                        </p>
                        <p>
                            {' '}
                            You further agree to not to use AI Tools for any
                            purpose that could have a legal or material impact
                            on that person, such as making credit, educational,
                            employment, housing, insurance, legal, medical, or
                            other important decisions about them.
                        </p>
                        <p>
                            {' '}
                            You also agree that you will not use AI Tools to
                            process sensitive personal information, as such term
                            is defined in applicable law.
                        </p>
                        <p>
                            {' '}
                            Recording & Transcription. Certain features of the
                            Service may allow you to record meetings, and
                            transcribe and summarize them using AI. You are
                            responsible for compliance with all laws governing
                            the monitoring or recording of conversations, and
                            you authorize use to store the recordings and
                            transcriptions. You will receive a notification when
                            recording is enabled. If you do not consent to being
                            recorded, you can choose to leave the recorded
                            meeting.
                        </p>
                        <p>
                            {' '}
                            Lead Enrichment. The Services may provide you with
                            the ability to enrich data about your potential
                            clients (“Enrichment Data”). You agree that you may
                            not resell Enriched Data or use it for any purpose
                            other than marketing your business through the
                            Services.
                        </p>
                        <p>
                            {' '}
                            HoneyBook will take reasonable steps to correct
                            errors and omissions in Enrichment Data when
                            discovered by HoneyBook and/or upon notification by
                            you. But accuracy of Enrichment Data may vary, and
                            availability of Enrichment data may be limited by
                            location.
                        </p>
                        <p>
                            {' '}
                            You acknowledge the Enrichment Data is Service
                            Content and we may use Enrichment Data to provide,
                            maintain, append, improve, enhance, and develop our
                            Services, including to provide Services to other
                            Services users. Upon termination as set forth in
                            Section 10, any license or access to Enrichment Data
                            will cease.
                        </p>
                        <p>
                            {' '}
                            Banking Services: Banking services associated with
                            our Services are provided by Lincoln Savings Bank,
                            Member FDIC. Your transactions will be processed
                            through an FBO account held by Lincoln Savings Bank
                            for the benefit of our customers or users. We will
                            provide you a monthly summary statement that
                            describes your transactions. Your deposit balances
                            held by Lincoln Savings Bank are insured by the
                            Federal Deposit Insurance Corporation (FDIC) for up
                            to the legal limit (currently $250,000 for each
                            category of legal ownership).
                        </p>
                        <h2 className="terms-title">
                            3. Third Party Distribution Channels
                        </h2>

                        <p>
                            HoneyBook offers Software applications that may be
                            made available through the Apple App Store, Android
                            Marketplace or other distribution channels
                            (“Distribution Channels”). If you obtain such
                            Software through a Distribution Channel, you may be
                            subject to additional terms of the Distribution
                            Channel. These Terms of Service are between you and
                            us only, and not with the Distribution Channel. To
                            the extent that you utilize any other third party
                            products and services in connection with your use of
                            our Services, you agree to comply with all
                            applicable terms of any agreement for such third
                            party products and services.
                        </p>

                        <p>
                            With respect to Software that is made available for
                            your use in connection with an Apple-branded product
                            (such Software, &ldquo;Apple- Enabled
                            Software&rdquo;), in addition to the other terms and
                            conditions set forth in these Terms of Service, the
                            following terms and conditions apply:
                        </p>

                        <ul className="terms-items">
                            <li>
                                HoneyBook and you acknowledge that these Terms
                                of Service are concluded between HoneyBook and
                                you only, and not with Apple, and that as
                                between HoneyBook and Apple, HoneyBook, not
                                Apple, is solely responsible for the
                                Apple-Enabled Software and the content thereof.
                            </li>
                            <li>
                                You may not use the Apple-Enabled Software in
                                any manner that is in violation of or
                                inconsistent with the Usage Rules set forth for
                                Apple-Enabled Software in, or otherwise be in
                                conflict with, the App Store Terms of Service.{' '}
                            </li>
                            <li>
                                Your license to use the Apple-Enabled Software
                                is limited to a non-transferable license to use
                                the Apple-Enabled Software on an iOS Product
                                that you own or control, as permitted by the
                                Usage Rules set forth in the App Store Terms of
                                Service.
                            </li>
                            <li>
                                Apple has no obligation whatsoever to provide
                                any maintenance or support services with respect
                                to the Apple-Enabled Software.{' '}
                            </li>
                            <li>
                                Apple is not responsible for any product
                                warranties, whether express or implied by law.
                                In the event of any failure of the Apple-Enabled
                                Software to conform to any applicable warranty,
                                you may notify Apple, and Apple will refund the
                                purchase price for the Apple-Enabled Software to
                                you, if any; and, to the maximum extent
                                permitted by applicable law, Apple will have no
                                other warranty obligation whatsoever with
                                respect to the Apple-Enabled Software, or any
                                other claims, losses, liabilities, damages,
                                costs or expenses attributable to any failure to
                                conform to any warranty, which will be
                                HoneyBook&rsquo;s sole responsibility, to the
                                extent it cannot be disclaimed under applicable
                                law.
                            </li>
                            <li>
                                HoneyBook and you acknowledge that HoneyBook,
                                not Apple, is responsible for addressing any
                                claims of you or any third party relating to the
                                Apple-Enabled Software or your possession and/or
                                use of that Apple-Enabled Software, including,
                                but not limited to: (i) product liability
                                claims; (ii) any claim that the Apple-Enabled
                                Software fails to conform to any applicable
                                legal or regulatory requirement; and (iii)
                                claims arising under consumer protection or
                                similar legislation.{' '}
                            </li>
                            <li>
                                In the event of any third party claim that the
                                Apple-Enabled Software or the end-user&rsquo;s
                                possession and use of that Apple-Enabled
                                Software infringes that third party&rsquo;s
                                intellectual property rights, as between
                                HoneyBook and Apple, HoneyBook, not Apple, will
                                be solely responsible for the investigation,
                                defense, settlement and discharge of any such
                                intellectual property infringement claim.
                            </li>
                            <li>
                                You represent and warrant that (i) you are not
                                located in a country that is subject to a U.S.
                                Government embargo, or that has been designated
                                by the U.S. Government as a &ldquo;terrorist
                                supporting&rdquo; country; and (ii) you are not
                                listed on any U.S. Government list of prohibited
                                or restricted parties.{' '}
                            </li>
                            <li>
                                If you have any questions, complaints or claims
                                with respect to the Apple-Enabled Software, they
                                should be directed to HoneyBook as follows:{' '}
                            </li>
                            <li>
                                <ul>
                                    <li>
                                        <a
                                            href="mailto:concierge@honeybook.com"
                                            className="link-act"
                                            target="_blank"
                                            title="HoneyBook support"
                                        >
                                            concierge@honeybook.com
                                        </a>
                                        <br />
                                        539 Bryant St., San Francisco, CA 94107
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <p>
                            HoneyBook and you acknowledge and agree that Apple,
                            and Apple&rsquo;s subsidiaries, are third party
                            beneficiaries of these Terms of Service with respect
                            to the Apple-Enabled Software, and that, upon your
                            acceptance of the terms and conditions of these
                            Terms of Service, Apple will have the right (and
                            will be deemed to have accepted the right) to
                            enforce these Terms of Service against you with
                            respect to the Apple-Enabled Software as a third
                            party beneficiary thereof.
                        </p>

                        <h2 className="terms-title">
                            4. Intellectual Property Rights
                        </h2>

                        <p>
                            <b className="terms-marker">
                                Service Content, Software and Trademarks:{' '}
                            </b>
                            You acknowledge and agree that the Service may
                            contain content or features (&ldquo;Service
                            Content&rdquo;) that are protected by copyright,
                            patent, trademark, trade secret or other proprietary
                            rights and laws. Except as expressly authorized by
                            HoneyBook, you agree not to modify, copy, frame,
                            scrape, rent, lease, loan, sell, distribute or
                            create derivative works based on the Service or the
                            Service Content, in whole or in part, except that
                            the foregoing does not apply to your own User
                            Content (as defined below) that you legally upload
                            to the Service. In connection with your use of the
                            Service you will not engage in or use any data
                            mining, robots, scraping or similar data gathering
                            or extraction methods. If you are blocked by
                            HoneyBook from accessing the Service (including by
                            blocking your IP address), you agree not to
                            implement any measures to circumvent such blocking
                            (e.g., by masking your IP address or using a proxy
                            IP address). Any use of the Service or the Service
                            Content other than as specifically authorized herein
                            is strictly prohibited. The technology and software
                            underlying the Service or distributed in connection
                            therewith are the property of HoneyBook, our
                            affiliates and our partners (the
                            &ldquo;Software&rdquo;). You agree not to copy,
                            modify, create a derivative work of, reverse
                            engineer, reverse assemble or otherwise attempt to
                            discover any source code, sell, assign, sublicense,
                            or otherwise transfer any right in the Software. Any
                            rights not expressly granted herein are reserved by
                            HoneyBook.
                        </p>

                        <p className="terms-text terms-padding-top">
                            The HoneyBook name and logos are trademarks and
                            service marks of HoneyBook (collectively the
                            &ldquo;HoneyBook Trademarks&rdquo;). Other
                            HoneyBook, product, and service names and logos used
                            and displayed via the Service may be trademarks or
                            service marks of their respective owners who may or
                            may not endorse or be affiliated with or connected
                            to HoneyBook. Nothing in this Terms of Service or
                            the Service should be construed as granting, by
                            implication, estoppel, or otherwise, any license or
                            right to use any of HoneyBook Trademarks displayed
                            on the Service, without our prior written permission
                            in each instance. All goodwill generated from the
                            use of HoneyBook Trademarks will inure to our
                            exclusive benefit.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Third Party Material:
                            </b>{' '}
                            Under no circumstances will HoneyBook be liable in
                            any way for any content or materials of any third
                            parties (including users), including, but not
                            limited to, for any errors or omissions in any
                            content, or for any loss or damage of any kind
                            incurred as a result of the use of any such content.
                            You acknowledge that HoneyBook does not pre-screen
                            content, but that HoneyBook and its designees will
                            have the right (but not the obligation) in their
                            sole discretion to refuse or remove any content that
                            is available via the Service. Without limiting the
                            foregoing, HoneyBook and its designees will have the
                            right to remove any content that violates these
                            Terms of Service or is deemed by HoneyBook, in its
                            sole discretion, to be otherwise objectionable. You
                            agree that you must evaluate, and bear all risks
                            associated with, the use of any content, including
                            any reliance on the accuracy, completeness, or
                            usefulness of such content.
                        </p>

                        <p>
                            <b className="terms-marker">
                                User Content Transmitted Through the Service:{' '}
                            </b>
                            With respect to the content or other materials you
                            upload, instruct HoneyBook to access, or otherwise
                            make available to HoneyBook through the Service or
                            share with other users or recipients, whether as a
                            User, Vendor or Business (collectively, “User
                            Content”), you represent and warrant that you own
                            all right, title and interest in and to such User
                            Content, including, without limitation, all
                            copyrights and rights of publicity contained
                            therein, and are authorized to grant HoneyBook
                            access to the User Content. You hereby grant and
                            will grant HoneyBook and its affiliated companies a
                            nonexclusive, worldwide, royalty free, fully paid
                            up, transferable, sublicensable, perpetual,
                            irrevocable license to copy, display, upload,
                            perform, distribute, store, modify and otherwise use
                            your User Content in connection with the operation
                            of or improvements to the Service in any form,
                            medium or technology now known or later developed.
                        </p>

                        <p>
                            You acknowledge and agree that any questions,
                            comments, suggestions, ideas, feedback or other
                            information about the Service
                            (&ldquo;Submissions&rdquo;), provided by you to
                            HoneyBook are non-confidential and HoneyBook will be
                            entitled to the unrestricted use and dissemination
                            of these Submissions for any purpose, commercial or
                            otherwise, without acknowledgment or compensation to
                            you.
                        </p>

                        <p>
                            You acknowledge and agree that, notwithstanding
                            anything to the contrary herein, HoneyBook may
                            preserve content and may also disclose content if
                            required to do so by law or in the good faith belief
                            that such preservation or disclosure is reasonably
                            necessary to: (a) comply with legal process,
                            applicable laws or government requests; (b) enforce
                            these Terms of Service; (c) respond to claims that
                            any content violates the rights of third parties; or
                            (d) protect the rights, property, or personal safety
                            of HoneyBook, its users and the public. You
                            understand that the technical processing and
                            transmission of the Service, including your content,
                            may involve (a) transmissions over various networks;
                            and (b) changes to conform and adapt to technical
                            requirements of connecting networks or devices.
                        </p>
                        <p>
                            <b className="terms-marker">Publicity: </b>You
                            acknowledge and agree that HoneyBook may use your
                            name and logo on HoneyBook&rsquo;s website and
                            marketing materials to identify your relationship
                            with HoneyBook.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Copyright Complaints:{' '}
                            </b>
                            HoneyBook respects the intellectual property of
                            others, and we ask our users to do the same. If you
                            believe that your work has been copied in a way that
                            constitutes copyright infringement, or that your
                            intellectual property rights have been otherwise
                            violated, you should notify HoneyBook of your
                            infringement claim in accordance with the procedure
                            set forth below.
                        </p>

                        <p>
                            HoneyBook will process and investigate notices of
                            alleged infringement and will take appropriate
                            actions under the Digital Millennium Copyright Act
                            ("DMCA") and other applicable intellectual property
                            laws with respect to any alleged or actual
                            infringement. A notification of claimed copyright
                            infringement should be emailed to HoneyBook&rsquo;s
                            Copyright Agent at{' '}
                            <a
                                href="mailto:copyright@honeybook.com"
                                className="link-act"
                                target="_blank"
                                title="HoneyBook support"
                            >
                                copyright@honeybook.com
                            </a>{' '}
                            (Subject line: &ldquo;DMCA Takedown Request&rdquo;).
                            You may also contact us by mail at:
                        </p>

                        <p>539 Bryant St., San Francisco, CA 94107.</p>

                        <p className="terms-padding-top terms-padding-bottom">
                            To be effective, the notification must be in writing
                            and contain the following information:
                        </p>

                        <ul className="terms-items">
                            <li>
                                an electronic or physical signature of the
                                person authorized to act on behalf of the owner
                                of the copyright or other intellectual property
                                interest;
                            </li>
                            <li>
                                a description of the copyrighted work or other
                                intellectual property that you claim has been
                                infringed;
                            </li>
                            <li>
                                a description of where the material that you
                                claim is infringing is located on the Service,
                                with enough detail that we may find it on the
                                Service;
                            </li>
                            <li>
                                your address, telephone number, and email
                                address;
                            </li>
                            <li>
                                a statement by you that you have a good faith
                                belief that the disputed use is not authorized
                                by the copyright or intellectual property owner,
                                its agent, or the law;
                            </li>
                            <li>
                                a statement by you, made under penalty of
                                perjury, that the above information in your
                                Notice is accurate and that you are the
                                copyright or intellectual property owner or
                                authorized to act on the copyright or
                                intellectual property owner&rsquo;s behalf.
                            </li>

                            <li>
                                <b className="terms-marker">Counter-Notice: </b>
                                If you believe that your User Content that was
                                removed (or to which access was disabled) is not
                                infringing, or that you have the authorization
                                from the copyright owner, the copyright
                                owner&rsquo;s agent, or pursuant to the law, to
                                upload and use the content in your User Content,
                                you may send a written counter-notice containing
                                the following information to the Copyright
                                Agent:
                            </li>
                            <li>your physical or electronic signature;</li>
                            <li>
                                identification of the content that has been
                                removed or to which access has been disabled and
                                the location at which the content appeared
                                before it was removed or disabled;
                            </li>
                            <li>
                                a statement that you have a good faith belief
                                that the content was removed or disabled as a
                                result of mistake or a misidentification of the
                                content; and
                            </li>
                            <li>
                                your name, address, telephone number, and email
                                address, a statement that you consent to the
                                jurisdiction of the federal court located within
                                Northern District of California and a statement
                                that you will accept service of process from the
                                person who provided notification of the alleged
                                infringement.
                            </li>

                            <li>
                                If a counter-notice is received by the Copyright
                                Agent, HoneyBook will send a copy of the
                                counter-notice to the original complaining party
                                informing that person that it may replace the
                                removed content or cease disabling it in 10
                                business days. Unless the copyright owner files
                                an action seeking a court order against the
                                content provider, member or user, the removed
                                content may be replaced, or access to it
                                restored, in 10 to 14 business days or more
                                after receipt of the counter-notice, at our sole
                                discretion.
                            </li>
                        </ul>

                        <p>
                            <b className="terms-marker">
                                Repeat Infringer Policy:
                            </b>{' '}
                            In accordance with the DMCA and other applicable
                            law, HoneyBook has adopted a policy of terminating,
                            in appropriate circumstances and at HoneyBook's sole
                            discretion, users who are deemed to be repeat
                            infringers. HoneyBook may also at its sole
                            discretion limit access to the Service and/or
                            terminate the memberships of any users who infringe
                            any intellectual property rights of others, whether
                            or not there is any repeat infringement.
                        </p>

                        <h2 className="terms-title">5. Third Party Websites</h2>

                        <p>
                            The Service may provide, or third parties may
                            provide, links or other access to other sites and
                            resources on the Internet. HoneyBook has no control
                            over such sites and resources and HoneyBook is not
                            responsible for and does not endorse such sites and
                            resources. You further acknowledge and agree that
                            HoneyBook will not be responsible or liable,
                            directly or indirectly, for any damage or loss
                            caused or alleged to be caused by or in connection
                            with use of or reliance on any content, events,
                            goods or services available on or through any such
                            site or resource. Any dealings you have with third
                            parties found while using the Service are between
                            you and the third party, and you agree that
                            HoneyBook is not liable for any loss or claim that
                            you may have against any such third party.
                        </p>

                        <h2 className="terms-title">
                            6. Social Networking Services
                        </h2>

                        <p>
                            You may enable or log in to the Service via various
                            online third party services, such as social media
                            and social networking services like Facebook or
                            Twitter (&ldquo;Social Networking Services&rdquo;).
                            By logging in or directly integrating these Social
                            Networking Services into the Service, we make your
                            online experiences richer and more personalized. To
                            take advantage of this feature and capabilities, we
                            may ask you to authenticate, register for or log
                            into Social Networking Services on the websites of
                            their respective providers. As part of such
                            integration, the Social Networking Services will
                            provide us with access to certain information that
                            you have provided to such Social Networking
                            Services, and we will use, store and disclose such
                            information in accordance with our Privacy Policy.
                            For more information about the implications of
                            activating these Social Networking Services and
                            HoneyBook&rsquo;s use, storage and disclosure of
                            information related to you and your use of such
                            services within HoneyBook (including your friend
                            lists and the like), please see our Privacy Policy
                            at{' '}
                            <a href="/terms/privacy">
                                https://www.HoneyBook.com/terms/privacy
                            </a>
                            . However, please remember that the manner in which
                            Social Networking Services use, store and disclose
                            your information is governed solely by the policies
                            of such third parties, and HoneyBook shall have no
                            liability or responsibility for the privacy
                            practices or other actions of any third party site
                            or service that may be enabled within the Service.
                        </p>

                        <p>
                            In addition, HoneyBook is not responsible for the
                            accuracy, availability or reliability of any
                            information, content, goods, data, opinions, advice
                            or statements made available in connection with
                            Social Networking Services. As such, HoneyBook is
                            not liable for any damage or loss caused or alleged
                            to be caused by or in connection with use of or
                            reliance on any such Social Networking Services.
                            HoneyBook enables these features merely as a
                            convenience and the integration or inclusion of such
                            features does not imply an endorsement or
                            recommendation.
                        </p>

                        <h2 className="terms-title">
                            7. Indemnity and Release
                        </h2>

                        <p>
                            You agree to release, indemnify and hold HoneyBook
                            and its affiliates and their officers, employees,
                            directors and agent (collectively, "Indemnitees")
                            harmless from any and all losses, damages, expenses,
                            including reasonable attorneys&rsquo; fees, rights,
                            claims, actions of any kind and injury (including
                            death) arising out of or relating to your use of the
                            Service, any User Content, your connection to the
                            Service, your violation of these Terms of Service or
                            your violation of any rights of another.
                            Notwithstanding the foregoing, you will have no
                            obligation to indemnify or hold harmless any
                            Indemnitee from or against any liability, losses,
                            damages or expenses incurred as a result of any
                            action or inaction of such Indemnitee. If you are a
                            California resident, you waive California Civil Code
                            Section 1542, which says: "A general release does
                            not extend to claims that the creditor or releasing
                            party does not know or suspect to exist in his or
                            her favor at the time of executing the release and
                            that, if known by him or her, would have materially
                            affected his or her settlement with the debtor or
                            released party." If you are a resident of another
                            jurisdiction, you waive any comparable statute or
                            doctrine.
                        </p>

                        <h2 className="terms-title">
                            8. Disclaimer of Warranties
                        </h2>

                        <p>
                            YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE
                            SERVICE IS PROVIDED ON AN &ldquo;AS IS&rdquo; AND
                            &ldquo;AS AVAILABLE&rdquo; BASIS. HONEYBOOK
                            EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                            WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING,
                            BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
                            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                            TITLE AND NON-INFRINGEMENT.
                        </p>

                        <p>
                            HONEYBOOK MAKES NO WARRANTY THAT (I) THE SERVICE
                            WILL MEET YOUR REQUIREMENTS, (II) THE SERVICE WILL
                            BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
                            (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE
                            OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV)
                            THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION,
                            OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
                            THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.
                        </p>

                        <h2 className="terms-title">
                            9. Limitation of Liability
                        </h2>

                        <p>
                            YOU EXPRESSLY UNDERSTAND AND AGREE THAT HONEYBOOK
                            WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                            SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR
                            DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT
                            LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA
                            OR OTHER INTANGIBLE LOSSES (EVEN IF HONEYBOOK HAS
                            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
                            WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
                            LIABILITY OR OTHERWISE, RESULTING FROM: (I) THE USE
                            OR THE INABILITY TO USE THE SERVICE; (II) THE COST
                            OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
                            RESULTING FROM ANY GOODS, DATA, INFORMATION OR
                            SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED
                            OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE
                            SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION
                            OF YOUR TRANSMISSIONS OR DATA; (IV) ANY ACTS,
                            OMISSIONS, STATEMENTS OR OTHER CONDUCT OF ANY
                            CLIENTS, VENDORS, BUSINESSES OR OTHER THIRD PARTIES
                            ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO
                            THE SERVICE. IN NO EVENT WILL HONEYBOOK&rsquo;S
                            TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR
                            CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID
                            HONEYBOOK IN THE LAST SIX (6) MONTHS, OR, IF
                            GREATER, ONE HUNDRED DOLLARS ($100).
                        </p>

                        <p>
                            SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR
                            EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR
                            EXCLUSION OF LIABILITY FOR INCIDENTAL OR
                            CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE
                            ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO
                            YOU OR BE ENFORCEABLE WITH RESPECT TO YOU. IF YOU
                            ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR
                            WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE
                            REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
                        </p>

                        <p>
                            IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING
                            SECTIONS TITLED &ldquo;DISCLAIMER OF
                            WARRANTIES&rdquo; AND &ldquo;LIMITATION OF
                            LIABILITY&rdquo; ARE INTENDED TO BE ONLY AS BROAD AS
                            IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW
                            JERSEY. IF ANY PORTION OF THESE SECTIONS IS HELD TO
                            BE INVALID UNDER THE LAWS OF THE STATE OF NEW
                            JERSEY, THE INVALIDITY OF SUCH PORTION SHALL NOT
                            AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE
                            APPLICABLE SECTIONS.
                        </p>

                        <h2 className="terms-title">
                            10. Dispute Resolution By Binding Arbitration
                        </h2>
                        <p className="uppercase">
                            <b>
                                PLEASE READ THIS SECTION CAREFULLY BECAUSE IT
                                REQUIRES YOU AND HONEYBOOK TO ARBITRATE CERTAIN
                                DISPUTES AND CLAIMS AND LIMITS THE MANNER IN
                                WHICH WE CAN SEEK RELIEF FROM EACH OTHER.
                                ARBITRATION PRECLUDES YOU AND HONEYBOOK FROM
                                SUING IN COURT OR HAVING A JURY TRIAL. YOU AND
                                HONEYBOOK AGREE THAT ARBITRATION WILL BE SOLELY
                                ON AN INDIVIDUAL BASIS AND NOT AS A CLASS
                                ARBITRATION, CLASS ACTION, OR ANY OTHER KIND OF
                                REPRESENTATIVE PROCEEDING. HONEYBOOK AND YOU ARE
                                EACH WAIVING THE RIGHT TO TRIAL BY A JURY.
                            </b>
                        </p>
                        <p className="uppercase">
                            <b>
                                FOLLOW THE INSTRUCTIONS BELOW IF YOU WISH TO OPT
                                OUT OF THE REQUIREMENT OF ARBITRATION ON AN
                                INDIVIDUAL BASIS. NO CLASS OR REPRESENTATIVE
                                ACTIONS OR ARBITRATIONS ARE ALLOWED UNDER THIS
                                ARBITRATION AGREEMENT.
                            </b>
                        </p>
                        <p>
                            (a) For any dispute or claim that you have against
                            HoneyBook, that HoneyBook has against you or that
                            you have, or HoneyBook has, in each case arising
                            from, relating to, or stemming from these Terms, our
                            Services, or any aspect of the relationship between
                            you and HoneyBook as relates to these Terms, our
                            Services, including any privacy or data security
                            claims, (collectively, “<b>Claims</b>”, and each a “
                            <b>Claim</b>”), you and HoneyBook agree to attempt
                            to first resolve the Claim informally via the
                            following process. If you assert a Claim against
                            HoneyBook, you will first contact HoneyBook by
                            sending a written notice of your Claim (“
                            <b>Claimant Notice</b>”) to HoneyBook by certified
                            mail addressed to 539 Bryant St., Suite 200, San
                            Francisco, CA 94107 or by email to{' '}
                            <a
                                href="mailto:legal@honeybook.com"
                                className="link-act"
                                target="_blank"
                                title="legal@honeybook.com"
                            >
                                legal@honeybook.com
                            </a>
                            . The Claimant Notice must (i) include your name,
                            residence address, email address, and telephone
                            number; (ii) describe the nature and basis of the
                            Claim; and (iii) set forth the specific relief
                            sought. If HoneyBook asserts a Claim against you,
                            HoneyBook will first contact you by sending a
                            written notice of HoneyBook’s Claim (“
                            <b>HoneyBook Notice</b>”), and each of a Claimant
                            Notice and HoneyBook Notice, a “<b>Notice</b>”) to
                            you via email to the primary email address
                            associated with your account. The HoneyBook Notice
                            must (i) include the name of a HoneyBook contact and
                            the contact’s email address and telephone number;
                            (ii) describe the nature and basis of the Claim; and
                            (iii) set forth the specific relief sought. If you
                            and HoneyBook cannot reach an agreement to resolve
                            the Claim within thirty (30) days after you or
                            HoneyBook receives such a Notice, then either party
                            may submit the Claim to binding arbitration as set
                            forth below. The statute of limitations and any
                            filing fee deadlines shall be tolled for thirty (30)
                            days from the date that either you or HoneyBook
                            first send the applicable Notice so that the parties
                            can engage in this informal dispute-resolution
                            process.
                        </p>

                        <p>
                            (b) Except for individual disputes that qualify for
                            small claims court and any disputes exclusively
                            related to the intellectual property or intellectual
                            property rights of you or HoneyBook, including any
                            disputes in which you or HoneyBook seek injunctive
                            or other equitable relief for the alleged unlawful
                            use of your or HoneyBook’s intellectual property or
                            other infringement of your or HoneyBook’s
                            intellectual property rights (“<b>IP Claims</b>”),
                            all Claims, whether based in contract, tort,
                            statute, fraud, misrepresentation, or any other
                            legal theory, including Claims that are not related
                            to intellectual property or intellectual property
                            rights but are jointly filed with IP Claims, that
                            are not resolved in accordance with Section 10(a)
                            will be resolved by a neutral arbitrator through
                            final and binding arbitration instead of in a court
                            by a judge or jury. Such Claims include, without
                            limitation, disputes arising out of or relating to
                            interpretation or application of this arbitration
                            provision, including the enforceability,
                            revocability, or validity of the arbitration
                            provision or any portion of the arbitration
                            provision. The arbitrator will have the authority to
                            grant any remedy or relief that would otherwise be
                            available in court.
                        </p>

                        <p>
                            (c) These Terms affect interstate commerce, and the
                            enforceability of this Section 10 will be
                            substantively and procedurally governed by the
                            Federal Arbitration Act, 9 U.S.C. § 1, et seq., to
                            the extent permitted by law.
                        </p>

                        <p>
                            (d) YOU AND HONEYBOOK AGREE THAT ANY ARBITRATION
                            UNDER THESE TERMS WILL TAKE PLACE ON AN INDIVIDUAL
                            BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT
                            PERMITTED, AND YOU AND HONEYBOOK ARE AGREEING TO
                            GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS
                            ACTION. All Claims must be submitted to the American
                            Arbitration Association (“<b>AAA</b>”) and will be
                            resolved through binding arbitration before one
                            arbitrator. If you are not a consumer (as defined
                            below), the then-current version of the AAA’s
                            Commercial Arbitration Rules will apply, including
                            Expedited Procedures for all Disputes with a value
                            of $75,000 or less. If you are a consumer (meaning
                            someone who uses the Services for personal, family,
                            or household purposes), the then-current version of
                            the AAA’s Consumer Arbitration Rules will apply,
                            which are available on the AAA’s website (adr.org),
                            as amended by these Terms as follows:
                        </p>
                        <ul className="terms-items">
                            <li>
                                1. The arbitrator may conduct only an individual
                                arbitration and, except as described below for
                                the additional procedures to govern if
                                twenty-five (25) or more similar or coordinated
                                claims are asserted against HoneyBook or you by
                                the same or coordinated counsel, may not
                                consolidate more than one individual’s claims,
                                preside over any type of class or representative
                                proceeding, or preside over any proceeding
                                involving more than one individual.
                            </li>
                            <li>
                                2. For any arbitration you initiate, you will
                                pay the consumer filing fee, and HoneyBook will
                                pay the remaining AAA fees and costs. For any
                                arbitration initiated by HoneyBook, HoneyBook
                                will pay all AAA fees and costs.
                            </li>
                            <li>
                                3. For all arbitrations where the claims
                                asserted are $25,000 or less, the arbitration
                                shall be resolved according to the AAA’s
                                Procedures for the Resolution of Disputes
                                through Document Submission, and for all other
                                arbitrations the following procedure will apply:
                                (i) the arbitrator will conduct hearings, if
                                any, by teleconference or videoconference rather
                                than by personal appearances, unless the
                                arbitrator determines upon request by you or by
                                us that an in-person hearing is appropriate;
                                (ii) any in-person appearances will be held at a
                                location which is reasonably convenient to both
                                parties with due consideration of their ability
                                to travel and other pertinent circumstances; and
                                (iii) if the parties are unable to agree on a
                                location, such determination should be made by
                                the AAA or by the arbitrator.
                            </li>
                            <li>
                                4. If you or HoneyBook submits a dispute to
                                arbitration and the arbitrator orders any
                                exchange of information, you and HoneyBook agree
                                to cooperate to seek from the arbitrator
                                protection for any confidential, proprietary,
                                trade secret, or otherwise sensitive
                                information, documents, testimony, and/or other
                                materials that might be exchanged or the subject
                                of discovery in the arbitration. You and
                                HoneyBook agree to seek such protection before
                                any such information, documents, testimony,
                                and/or materials are exchanged or otherwise
                                become the subject of discovery in the
                                arbitration.{' '}
                            </li>
                            <li>
                                5. The arbitrator’s decision will follow these
                                Terms and will be final and binding. The
                                arbitrator will have authority to award
                                temporary, interim, or permanent injunctive
                                relief or relief providing for specific
                                performance of these Terms but only to the
                                extent necessary to provide relief warranted by
                                the individual claim before the arbitrator. The
                                award rendered by the arbitrator may be
                                confirmed and enforced in any court having
                                jurisdiction thereof. Notwithstanding any of the
                                foregoing, nothing in these Terms will preclude
                                you from bringing issues to the attention of
                                federal, state, or local agencies and, if the
                                law allows, they can seek relief against us for
                                you.
                            </li>
                            <li>
                                6. The AAA Supplementary Rules for Multiple Case
                                Filings and the AAA Multiple Consumer Case
                                Filing Fee Schedule will apply if twenty-five
                                (25) or more similar claims are asserted against
                                HoneyBook or against you by the same or
                                coordinated counsel or are otherwise
                                coordinated. In addition to the application of
                                the AAA Supplementary Rules for Multiple Case
                                Filings and the AAA Multiple Consumer Case
                                Filing Fee Schedule, you and HoneyBook
                                understand and agree that when twenty-five (25)
                                or more similar claims are asserted against
                                HoneyBook or you by the same or coordinated
                                counsel or are otherwise resolved, your or
                                HoneyBook’s Claim might be delayed. For such
                                coordinated actions, you and HoneyBook also
                                agree to the following coordinated bellwether
                                process. Counsel for the claimants and counsel
                                for HoneyBook shall each select ten (10) cases
                                (per side) to proceed first in individual
                                arbitration proceedings as part of a bellwether
                                process. The remaining cases shall not be filed
                                or deemed filed in arbitration nor shall any AAA
                                fees be assessed in connection with those cases
                                until they are selected to proceed to individual
                                arbitration proceedings as part of a bellwether
                                process. If the parties are unable to resolve
                                the remaining cases after the conclusion of the
                                initial twenty (20) proceedings, each side shall
                                select another ten (10) cases (per side) to
                                proceed to individual arbitration proceedings as
                                part of a second bellwether process. The
                                remaining cases shall not be filed or deemed
                                filed in arbitration nor shall any AAA fees be
                                assessed in connection with those cases until
                                they are selected to proceed to individual
                                arbitration proceedings as part of a bellwether
                                process. A single arbitrator shall preside over
                                each case. Only one case may be assigned to each
                                arbitrator as part of a bellwether process
                                unless the parties agree otherwise. This staged
                                process shall continue, consistent with the
                                parameters identified above, until all the
                                claims included in these coordinated filings,
                                including your case, are adjudicated or
                                otherwise resolved. The statute of limitations
                                and any filing fee deadlines shall be tolled for
                                claims subject to this staged process from the
                                time the first cases are selected for a
                                bellwether process until the time your case is
                                selected for a bellwether process, withdrawn, or
                                otherwise resolved. A court shall have authority
                                to enforce this paragraph and, if necessary, to
                                enjoin the mass filing or prosecution of
                                arbitration demands against HoneyBook or you.
                            </li>
                        </ul>
                        <p>
                            <b>
                                (e) One Year to Assert Claims. To the extent
                                permitted by law, any Claim by you or HoneyBook
                                relating in any way to these Terms, our
                                Services, or any aspect of the relationship
                                between you and HoneyBook as relates to these
                                Terms or our Services, must be filed within one
                                year after such Claim arises; otherwise, the
                                Claim is permanently barred, which means that
                                you and HoneyBook will not have the right to
                                assert the Claim.
                            </b>
                        </p>
                        <p>
                            (f) You have the right to opt out of binding
                            arbitration within 30 days of the date you first
                            accepted these Terms by providing us with notice of
                            your decision to opt-out via email at{' '}
                            <a
                                href="mailto:legal@honeybook.com"
                                className="link-act"
                                target="_blank"
                                title="legal@honeybook.com"
                            >
                                legal@honeybook.com
                            </a>{' '}
                            or by certified mail addressed to 539 Bryant St.,
                            Suite 200, San Francisco, CA 94107. In order to be
                            effective, the opt-out notice must include your full
                            name, mailing address, and email address. The notice
                            must also clearly indicate your intent to opt out of
                            binding arbitration. By opting out of binding
                            arbitration, you are agreeing to resolve disputes in
                            accordance with Section 13.
                        </p>
                        <p>
                            (g) If any portion of this Section 10 is found to be
                            unenforceable or unlawful for any reason, including
                            but not limited to because it is found to be
                            unconscionable, (i) the unenforceable or unlawful
                            provision will be severed from these Terms; (ii)
                            severance of the unenforceable or unlawful provision
                            will have no impact whatsoever on the remainder of
                            this Section 10 or the parties’ ability to compel
                            arbitration of any remaining claims on an individual
                            basis pursuant to this Section 10; and (iii) to the
                            extent that any claims must therefore proceed on a
                            class, collective, consolidated, or representative
                            basis, such claims must be litigated in a civil
                            court of competent jurisdiction and not in
                            arbitration. The litigation of those claims will be
                            stayed pending the outcome of any individual claims
                            in arbitration. Further, if any part of this Section
                            10 is found to prohibit an individual claim seeking
                            public injunctive relief, that provision will have
                            no effect to the extent such relief is allowed to be
                            sought out of arbitration, and the remainder of this
                            Section 10 will be enforceable.
                        </p>
                        <p>
                            (h) Notwithstanding anything to the contrary in the
                            Terms, if you reside in any country outside of the
                            United States, you may bring legal proceedings
                            regarding the Terms either by following the
                            arbitration procedure detailed above in this Section
                            10 of the Terms or, if given the right by applicable
                            law, by submitting the dispute to an arbitration
                            administrator in the jurisdiction in which you
                            reside. To the extent any proceeding is not subject
                            to arbitration under applicable law, you may submit
                            the dispute to the courts of the jurisdiction in
                            which you reside.
                        </p>
                        <h2 className="terms-title">11. Termination</h2>

                        <p>
                            You agree that HoneyBook, in its sole discretion,
                            may suspend or terminate your account (or any part
                            thereof) or use of the Service and remove and
                            discard any content within the Service, for any
                            reason, including, without limitation, for lack of
                            use or if HoneyBook believes that you have violated
                            or acted inconsistently with the letter or spirit of
                            these Terms of Service. Any suspected fraudulent,
                            abusive or illegal activity that may be grounds for
                            termination of your use of Service, may be referred
                            to appropriate law enforcement authorities.
                            HoneyBook may also in its sole discretion and at any
                            time discontinue providing the Service, or any part
                            thereof, with or without notice. You agree that any
                            termination of your access to the Service under any
                            provision of this Terms of Service may be effected
                            without prior notice, and acknowledge and agree that
                            HoneyBook may immediately deactivate or delete your
                            account and all related information and files in
                            your account and/or bar any further access to such
                            files or the Service. Further, you agree that
                            HoneyBook will not be liable to you or any third
                            party for any termination of your access to the
                            Service.
                            <br />
                            If you have registered for the Service via a
                            subscription plan, you may cancel your subscription
                            at any time by sending an email to
                            concierge@honeybook.com notifying us of your intent
                            to cancel at least thirty (30) days prior to the end
                            of your then-current subscription term.
                        </p>

                        <h2 className="terms-title">12. User Disputes</h2>

                        <p>
                            You agree that you are solely responsible for your
                            interactions with any other user in connection with
                            the Service (including through the use of AI Tools)
                            and HoneyBook will have no liability or
                            responsibility with respect thereto. HoneyBook
                            reserves the right, but has no obligation, to become
                            involved in any way with disputes between you and
                            any other user of the Service, including by giving
                            Clients access to their agreements with Businesses
                            and Vendors.
                        </p>

                        <h2 className="terms-title">13. General</h2>

                        <p>
                            These Terms of Service constitute the entire
                            agreement between you and HoneyBook and govern your
                            use of the Service, superseding any prior agreements
                            between you and HoneyBook with respect to the
                            Service. You also may be subject to additional terms
                            and conditions that may apply when you use affiliate
                            or third party services, third party content or
                            third party software. These Terms of Service will be
                            governed by the laws of the State of California
                            without regard to its conflict of law provisions.
                            With respect to any disputes or claims not subject
                            to arbitration, as set forth above, you and
                            HoneyBook agree to submit to the personal and
                            exclusive jurisdiction of the state and federal
                            courts located within San Francisco County,
                            California. The failure of HoneyBook to exercise or
                            enforce any right or provision of these Terms of
                            Service will not constitute a waiver of such right
                            or provision. If any provision of these Terms of
                            Service is found by a court of competent
                            jurisdiction to be invalid, the parties nevertheless
                            agree that the court should endeavor to give effect
                            to the parties&rsquo; intentions as reflected in the
                            provision, and the other provisions of these Terms
                            of Service remain in full force and effect. You
                            agree that regardless of any statute or law to the
                            contrary, any claim or cause of action arising out
                            of or related to use of the Service or these Terms
                            of Service must be filed within one (1) year after
                            such claim or cause of action arose or be forever
                            barred. A printed version of this agreement and of
                            any notice given in electronic form will be
                            admissible in judicial or administrative proceedings
                            based upon or relating to this agreement to the same
                            extent and subject to the same conditions as other
                            business documents and records originally generated
                            and maintained in printed form. You may not assign
                            this Terms of Service without the prior written
                            consent of HoneyBook, but HoneyBook may assign or
                            transfer this Terms of Service, in whole or in part,
                            without restriction. The section titles in these
                            Terms of Service are for convenience only and have
                            no legal or contractual effect. Notices to you may
                            be made via either email or regular mail. The
                            Service may also provide notices to you of changes
                            to these Terms of Service or other matters by
                            displaying notices or links to notices generally on
                            the Service.
                        </p>

                        <h2 className="terms-title">14. Beta Terms</h2>

                        <p>
                            We may occasionally provide features or services
                            designated as beta, trial, preview, or the like
                            (“Beta Services”). The Beta Services are part of the
                            Services. We grant certain users a non-exclusive,
                            revocable, non-transferable license to use the Beta
                            Services in exchange for their active and diligent
                            participation in testing and evaluating the Beta
                            Services, including providing Submissions as
                            requested by HoneyBook via surveys, interviews, and
                            other means. Any such Submissions are subject to
                            Section 4 (Intellectual Property Rights). Users who
                            do not provide Submissions as requested will lose
                            access to the Beta Services.
                        </p>
                        <p>
                            The Beta Services, and all information related
                            thereto, are the proprietary information of
                            HoneyBook and you will (1) preserve the
                            confidentiality of such information, (2) not use
                            such information other than as needed to test and
                            evaluate the Beta Services and provide related
                            Submissions, (3) not disclose such information other
                            than to HoneyBook personnel or other authorized
                            users of the Beta Services, and (4) promptly delete
                            such information on HoneyBook’s request.
                        </p>
                        <p className="uppercase">
                            YOU ACKNOWLEDGE THAT THE BETA SERVICES MAY NOT HAVE
                            BEEN FULLY TESTED, MAY NOT BE READY FOR GENERAL
                            COMMERCIAL RELEASE, AND MAY CONTAIN BUGS, ERRORS AND
                            DEFECTS. ACCORDINGLY, THE BETA SERVICES ARE PROVIDED
                            "AS IS," WITH ALL FAULTS, DEFECTS AND ERRORS, AND
                            DESCRIPT WILL HAVE NO LIABILITY OF ANY KIND FOR ANY
                            ERROR, OMISSION OR DEFECT IN THE BETA SERVICES, ANY
                            INABILITY TO USE THE BETA SERVICES, OR ANY OR ANY
                            HARM OR DAMAGE ARISING FROM YOUR OR ANY
                            THIRD-PARTY’S USE OF THE BETA SERVICES.{' '}
                        </p>

                        <h2 className="terms-title">15. Your Privacy</h2>

                        <p>
                            At HoneyBook, we respect the privacy of our users.
                            For details please see our Privacy Policy. By using
                            the Service, you consent to our collection and use
                            of personal data as outlined therein.
                        </p>

                        <h2 className="terms-title">
                            16. Notice for California Users
                        </h2>

                        <p>
                            Under California Civil Code Section 1789.3, users of
                            the Service from California are entitled to the
                            following specific consumer rights notice: The
                            Complaint Assistance Unit of the Division of
                            Consumer Services of the California Department of
                            Consumer Affairs may be contacted in writing at 1625
                            North Market Blvd., Suite N 112, Sacramento, CA
                            95834, or by telephone at (916) 445-1254 or (800)
                            952-5210. You may contact us at HoneyBook, Inc.,
                            (415) 591-7768, 539 Bryant St., Suite 200, San
                            Francisco, CA 94107.
                        </p>

                        <h2 className="terms-title">
                            17. Questions? Concerns? Suggestions?
                        </h2>

                        <p>
                            Please contact us at{' '}
                            <a
                                href="mailto:concierge@honeybook.com"
                                className="link-act"
                                target="_blank"
                                title="HoneyBook support"
                            >
                                concierge@honeybook.com
                            </a>{' '}
                            to report any violations of these Terms of Service
                            or to pose any questions regarding this Terms of
                            Service or the Service.
                        </p>
                    </div>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};
/* eslint-enable */

export default TOSTabContent;
