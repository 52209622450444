import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './feature-checklist-hero.scss';
import HBText from '@honeybook-ui/HBText/HBText';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

import { FEATURES_LIST_SLIM } from './constants-and-types';

const checklistIdsForDisplay = Object.keys(FEATURES_LIST_SLIM);

const FeatureChecklistItem = ({ id, onClick }) => {
    const { t } = useTranslation();

    const handleClick = selectedItem => {
        AnalyticsService.trackClick(
            AnalyticsEvents.feature_checklist_slim_select_item,
            {
                type: selectedItem
            }
        );
        onClick();
    };

    return (
        <div
            className="feature-checklist-item__wrapper"
            onClick={() => handleClick(id)}
        >
            <div
                tabIndex={0}
                role="checkbox"
                className={`feature-checklist-item feature-checklist-item--${id}
			}`}
            >
                <div className="feature-checklist-item__icon">
                    <HBMediaElement
                        name={FEATURES_LIST_SLIM[id]}
                        lazyLoad={false}
                        alt={t(
                            `checklistHeroSlim.featureChecklistSlim.desktop.${id}`
                        )}
                    />
                </div>
                <div className="feature-checklist-item__description">
                    {t(`checklistHeroSlim.featureChecklistSlim.${id}`)}
                </div>
            </div>
        </div>
    );
};
interface Props {
    title?: string;
    subtitle?: string;
    titleImage?: string;
    customButtonText?: string;
}

const FeatureChecklistSlimHero = ({
    title,
    subtitle,
    titleImage,
    customButtonText
}: Props) => {
    const { t } = useTranslation();

    const [isButtonAnimating, setIsButtonAnimating] = useState(false);

    const animateButton = () => {
        setIsButtonAnimating(true);
        setTimeout(() => {
            setIsButtonAnimating(false);
        }, 600);
    };

    return (
        <FeatureBlockSection
            customClass={`feature-checklist-hero feature-checklist-hero--slim`}
        >
            <FeatureBlockContent customClass="feature-checklist-hero__title-content">
                <div className="feature-checklist-hero__icon-wrapper">
                    <div className={`feature-checklist-hero__title-wrapper`}>
                        {titleImage && (
                            <HBMediaElement
                                name={titleImage}
                                customClass="feature-checklist-hero__title-image"
                                lazyLoad={false}
                            />
                        )}
                        <HBText
                            customClass="feature-checklist-hero__title"
                            tag="h1"
                            type="title-m"
                        >
                            {title || t('checklistHeroSlim.title')}
                        </HBText>
                        <HBText
                            customClass="feature-checklist-hero__subtitle"
                            tag="h2"
                            type="body-xl"
                        >
                            {subtitle || t('checklistHeroSlim.subtitle')}
                        </HBText>
                    </div>
                    <RegistrationForm
                        customClass={`${
                            isButtonAnimating
                                ? 'registration-form--animate'
                                : ''
                        }`}
                        source="feature checklist hero"
                        showNoCcRequired
                        direction="column"
                        size="large"
                        buttonText={customButtonText}
                    />
                </div>
                <div className="feature-checklist__checklist-container">
                    <HBText
                        tag="p"
                        type="subtitle-l"
                        customClass="feature-checklist__pre-description"
                    >
                        {t('checklistHeroSlim.featureChecklistSlim.title')}{' '}
                    </HBText>
                    {checklistIdsForDisplay.map((checkboxId, index) => {
                        let content = (
                            <FeatureChecklistItem
                                key={checkboxId}
                                id={checkboxId}
                                onClick={animateButton}
                            />
                        );

                        if (index === checklistIdsForDisplay.length - 1) {
                            return (
                                <>
                                    <HBText tag="p" type="subtitle-l">
                                        {t(
                                            'checklistHeroSlim.featureChecklistSlim.and'
                                        )}
                                    </HBText>
                                    {content}
                                </>
                            );
                        }

                        return content;
                    })}
                    <HBText tag="p" type="subtitle-l">
                        {t('checklistHeroSlim.featureChecklistSlim.inOnePlace')}
                    </HBText>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(FeatureChecklistSlimHero);
