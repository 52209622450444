import { Trans } from 'react-i18next';
import FullBleedBlock from '../../FullBleedBlock/FullBleedBlock';
import { TRANSLATION_COMPONENTS } from '../../../constants/constants';

import './trusted-block.scss';
import HBVideo from '../../honeybook-ui/HBVideo/HBVideo';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const TrustedBlock = () => {
    const breakpoints = useBreakpoint();

    return (
        <FullBleedBlock
            customClass="trusted-block"
            title={
                <Trans
                    i18nKey="trusted.title"
                    components={TRANSLATION_COMPONENTS}
                />
            }
            backgroundImage={
                breakpoints.mediumUp
                    ? ''
                    : 'https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1742418486/marketing_site/home_page/AI-photographer-hero-image.jpg'
            }
            showCTA
            source="trusted by"
            buttonStyle="primary--bright"
        >
            {breakpoints.mediumUp && (
                <HBVideo
                    videoId="1068311313"
                    poster="https://res.cloudinary.com/honeybook/image/upload/f_auto,fl_lossy,q_auto/v1742418486/marketing_site/home_page/AI-photographer-hero-image.jpg"
                    autoplay
                    loop
                />
            )}
        </FullBleedBlock>
    );
};

export default TrustedBlock;
