import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import HBText from '../../honeybook-ui/HBText/HBText';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './home-hero.scss';

const HomeHero = () => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    return (
        <FeatureBlockSection customClass="home-hero">
            <FeatureBlockContent>
                <HBText tag="h1" type="title-l" customClass="home-hero__title">
                    {t('homeHero.title')}
                </HBText>

                <HBText
                    tag="h2"
                    type="body-l"
                    customClass="home-hero__subtitle"
                >
                    {t('homeHero.subtitle')}
                </HBText>
                <RegistrationForm
                    source="homepage hero"
                    showNoCcRequired
                    size={breakpoints.medium ? 'medium' : 'large'}
                />
                <HBMediaElement
                    customClass="home-hero__image home-hero__image--left"
                    name="marketing_site/home_page/hero-left.png"
                    lazyLoad={false}
                />
                <HBMediaElement
                    customClass="home-hero__image home-hero__image--right"
                    name="marketing_site/home_page/hero-right.png"
                    lazyLoad={false}
                />
                <HBMediaElement
                    customClass="home-hero__image home-hero__squiggle1"
                    name="marketing_site/home_page/hero/squiggle1.png"
                    lazyLoad={false}
                />
                <HBMediaElement
                    customClass="home-hero__image home-hero__squiggle2"
                    name="marketing_site/home_page/hero/squiggle2.png"
                    lazyLoad={false}
                />
                <HBMediaElement
                    customClass="home-hero__image home-hero__plane"
                    name="marketing_site/home_page/hero/plane.png"
                    lazyLoad={false}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default memo(HomeHero);
