import type { MouseEvent as ReactMouseEvent } from 'react';
import {
    useCallback,
    useEffect,
    useRef,
    useState,
    memo,
    ReactNode
} from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import HBButton from '../honeybook-ui/HBButton/HBButton';

import NavBarBtn from '../NavBarBtn/NavBarBtn';
import MobileMenuDrawer from '../MobileMenuDrawer/MobileMenuDrawer';

import './navbar.scss';

import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import HBLogo from '../../svg/honeybook-logo.inline.svg';

import CalendarNavIcon from '../../svg/navbar-icons/calendar-clock-24.inline.svg';
import AutomationNavIcon from '../../svg/navbar-icons/automation-24.inline.svg';
import ContractNavIcon from '../../svg/navbar-icons/contract-24.inline.svg';
import DollarNavIcon from '../../svg/navbar-icons/dollar-circle-24.inline.svg';
import GridNavIcon from '../../svg/navbar-icons/grid-24.inline.svg';
import InvoiceNavIcon from '../../svg/navbar-icons/invoice-24.inline.svg';
import ProposalNavIcon from '../../svg/navbar-icons/proposal-24.inline.svg';
import AINavIcon from '../../svg/navbar-icons/ai.inline.svg';
import WalletIcon from '../../svg/wallet.inline.svg';
import { GOOGLE_ONE_TAP_PARENT_ID } from '../../google-one-tap/constants';
import {
    NavigationMenu,
    NavigationMenuList
} from '@radix-ui/react-navigation-menu';

import { useABTestContext } from '../../ab-tests/context/useABTestContext';

let lastScrollY = 0;

export function sendAnalytics(
    event: ReactMouseEvent<HTMLAnchorElement | HTMLDivElement>,
    analyticsTarget: string
): void {
    const analyticsPayload = {
        source: analyticsTarget
    };

    if (event.type === 'click' || event.type === 'touchstart') {
        AnalyticsService.track(
            AnalyticsEvents.header_navigation_button_click,
            analyticsPayload
        );
    }
}

export function logIn(event: ReactMouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();

    sendAnalytics(event, 'Log in');

    window.location.href = `${window.location.origin}/app/login`;
}

export interface NavBarSlimItem {
    id: string;
    link: string;
    customClass: string;
    analyticsTarget: string;
    mobileAnalyticsTarget: string;
    icon: ReactNode;
    subItems?: undefined;
}

const logo = {
    id: 'home',
    link: '/',
    icon: <HBLogo />,
    customClass: 'navbar-btn__logo',
    analyticsTarget: 'Honeybook homepage',
    mobileAnalyticsTarget: 'Honeybook homepage'
};

const slim: NavBarSlimItem[] = [];

export interface NavBarSubItem {
    title: string;
    link: string;
    description: string;
    icon?: ReactNode;
    analyticsTarget: string;
    mobileAnalyticsTarget: string;
    onClick: (
        event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
    ) => void;
    iconPath?: string;
}

export interface NavBarItem {
    id: string;
    analyticsTarget: string;
    mobileAnalyticsTarget: string;
    link?: string;
    icon?: ReactNode;
    customClass?: string;
    onClick?: (
        event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
    ) => void;
    subItems?: NavBarSubItem[];
}

const fat: NavBarItem[] = [
    {
        id: 'features',
        analyticsTarget: 'Features',
        mobileAnalyticsTarget: 'Features',
        onClick: (
            event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
        ): void => {
            event.stopPropagation();

            sendAnalytics(event, 'Features');
        },
        subItems: [
            {
                title: 'Features overview',
                link: '/features',
                description: 'Powerful features built for small businesses.',
                icon: <GridNavIcon />,
                analyticsTarget: 'Features / Features',
                mobileAnalyticsTarget: 'Mobile / Features',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Features');
                }
            },
            {
                title: 'Online contracts',
                link: '/online-contract',
                description: 'Customizable contracts that look professional.',
                icon: <ContractNavIcon />,
                analyticsTarget: 'Features / Online Contracts',
                mobileAnalyticsTarget: 'Mobile / Online Contracts',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Online Contracts');
                }
            },
            {
                title: 'Online invoices',
                link: '/online-invoices',
                description: 'Professional invoices that get you paid fast.',
                icon: <InvoiceNavIcon />,
                analyticsTarget: 'Features / Online Invoices',
                mobileAnalyticsTarget: 'Mobile / Online Invoices',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Online Invoices');
                }
            },
            {
                title: 'Online payments',
                link: '/online-payment-software',
                description:
                    'Make it easy for clients to pay you with online payments.',
                icon: <DollarNavIcon />,
                analyticsTarget: 'Features / Online Payments',
                mobileAnalyticsTarget: 'Mobile / Online Payments',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Online Payments');
                }
            },
            {
                title: 'Online proposals',
                link: '/proposal-software',
                description:
                    'Easily book clients by combining an invoice, contract, and payment page.',
                icon: <ProposalNavIcon />,
                analyticsTarget: 'Features / Proposals',
                mobileAnalyticsTarget: 'Mobile / Proposals',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Proposals');
                }
            },
            {
                title: 'Online scheduling',
                link: '/meeting-scheduler',
                description:
                    'Skip the back-and-forth and book meetings 2x faster.',
                icon: <CalendarNavIcon />,
                analyticsTarget: 'Features / Scheduling',
                mobileAnalyticsTarget: 'Mobile / Scheduling',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Scheduling');
                }
            },
            {
                title: 'Automations',
                link: '/automations',
                description:
                    'Put tasks, emails, files, and follow-up on autopilot.',
                icon: <AutomationNavIcon />,
                analyticsTarget: 'Features / Automations',
                mobileAnalyticsTarget: 'Mobile / Automations',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Scheduling');
                }
            },
            {
                title: 'HoneyBook AI',
                link: 'https://www.honeybook.com/lp/ai-start',
                description:
                    'Gain insight, boost productivity, and increase revenue.',
                icon: <AINavIcon />,
                analyticsTarget: 'Features / AI',
                mobileAnalyticsTarget: 'Mobile / AI',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / AI');
                }
            },
            {
                title: 'HoneyBook Finance',
                link: '/lp/finance',
                description:
                    'Monitor cashflow, track spending, automate savings, and more.',
                icon: <WalletIcon />,
                analyticsTarget: 'Features / Finance',
                mobileAnalyticsTarget: 'Mobile / Finance',
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Features / Finance');
                }
            }
        ]
    },
    {
        id: 'templates',
        link: '/templates',
        analyticsTarget: 'Templates',
        mobileAnalyticsTarget: 'Mobile / Templates'
    },
    {
        id: 'pricing',
        link: '/pricing',
        analyticsTarget: 'Pricing',
        mobileAnalyticsTarget: 'Mobile / Pricing'
    },
    {
        id: 'resources',
        analyticsTarget: 'Resources',
        mobileAnalyticsTarget: 'Mobile / Resources',
        subItems: [
            {
                title: 'Pros Marketplace',
                link: 'https://pros.honeybook.com',
                description: 'Find experts to help grow your business.',
                analyticsTarget: 'Resources / Pros',
                mobileAnalyticsTarget: 'Mobile / Pros',
                icon: null,
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Resources / Pros');
                }
            },
            {
                title: 'Unbreakable Business podcast',
                link: 'https://podcast.honeybook.com',
                description:
                    'Join us as we break down the science of self-made success.',
                analyticsTarget: 'Resources / Podcast',
                mobileAnalyticsTarget: 'Mobile / Podcast',
                icon: null,
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Resources / Podcast');
                }
            },
            {
                title: 'Help Center',
                link: 'https://help.honeybook.com',
                description: 'Answers and guidance on how to use HoneyBook.',
                analyticsTarget: 'Resources / Help Center',
                mobileAnalyticsTarget: 'Mobile / Help Center',
                icon: null,
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Resources / Help Center');
                }
            },
            {
                title: 'HoneyBook blog',
                link: '/blog',
                description:
                    'Helpful articles and industry tips for independents.',
                analyticsTarget: 'Community / Rising Tide Blog',
                mobileAnalyticsTarget: 'Mobile / Rising Tide Blog',
                icon: null,
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Resources / RisingTide Blog');
                }
            },
            {
                title: 'Live virtual classes',
                link: '/product-education',
                description: 'Live and on-demand trainings hosted by experts.',
                analyticsTarget: 'Resources / Product Education',
                mobileAnalyticsTarget: 'Mobile / Product Education',
                icon: null,
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Resources / Product Education');
                }
            },
            {
                title: 'Free file setup',
                link: '/free-account-migration',
                description:
                    'Let us turn your existing files into ready-to-use client-facing templates.',
                analyticsTarget: 'Resources / Free File Setup',
                mobileAnalyticsTarget: 'Mobile / Free File Setup',
                icon: null,
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Resources / Free File Setup');
                }
            },
            {
                title: 'HoneyBook business academy',
                link: '//academy.honeybook.com/',
                description: 'Resources to grow your business.',
                analyticsTarget: 'Resources / Academy',
                mobileAnalyticsTarget: 'Mobile / Academy',
                icon: null,
                onClick: (
                    event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>
                ) => {
                    event.stopPropagation();

                    sendAnalytics(event, 'Resources / Academy');
                }
            }
        ]
    }
];

const teams = {
    id: 'teams',
    link: '/p/for-teams',
    analyticsTarget: 'Teams',
    mobileAnalyticsTarget: 'Mobile / Teams'
};

interface Props {
    darkText?: boolean;
    slimNav?: boolean;
    noCta?: boolean;
}

function NavBar({
    darkText = true,
    slimNav,
    noCta = false
}: Props): JSX.Element {
    const [displayMobileDrawer, setDisplayMobileDrawer] =
        useState<boolean>(false);

    const {
        variantPerTest: { teams_test }
    } = useABTestContext();

    if (teams_test === 'teams' && !fat.some(item => item.id === 'teams')) {
        fat.splice(2, 0, teams);
    }

    const { t } = useTranslation();
    const navRef = useRef<HTMLElement>(
        typeof window === 'undefined' ? null : document.createElement('nav')
    );

    const handleScroll = useCallback((): void => {
        if (typeof window !== 'undefined') {
            lastScrollY = window.scrollY;

            if (navRef.current !== null) {
                if (
                    lastScrollY > navRef.current.offsetHeight &&
                    !displayMobileDrawer
                ) {
                    navRef.current.classList.add('fixed');
                } else {
                    navRef.current.classList.remove('fixed');
                }
            }
        }
    }, [displayMobileDrawer]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const handleNavItemClick = useCallback(
        (event: ReactMouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
            const { itemLink } = event.currentTarget.dataset;

            const clickedItem = fat.find(item => item.link === itemLink); // Get item

            if (typeof clickedItem !== 'undefined') {
                sendAnalytics(event, clickedItem.analyticsTarget);

                if (typeof clickedItem.onClick !== 'undefined') {
                    clickedItem.onClick(event);
                }
            } else if (itemLink === logo.link) {
                AnalyticsService.trackClick(AnalyticsEvents.honeybook_logo, {
                    source: window.location.pathname
                });
            }
        },
        []
    );

    const onLoginMouseEnter = useCallback(event => {
        event.preventDefault();

        sendAnalytics(event, 'Log In');
    }, []);

    const toggleMobileDrawer = useCallback(() => {
        navRef.current.classList.remove('fixed');
        setDisplayMobileDrawer((bool: boolean): boolean => !bool);
    }, []);

    const items = slimNav ? slim : fat;

    const isDarkText = darkText ? 'navbar__mode--dark-text' : '';

    const navClassName = classnames('navbar', {
        'navbar--dark-text': darkText
    });

    return (
        <>
            <NavigationMenu className="NavigationMenuRoot">
                <nav
                    className={`${navClassName} ${
                        slimNav ? 'navbar--slim' : ''
                    }`}
                    ref={navRef}
                    role="menubar"
                >
                    <div className="nav-logo">
                        <NavBarBtn
                            key={logo.id}
                            id={logo.id}
                            icon={logo.icon}
                            link={logo.link}
                            onClick={handleNavItemClick}
                            onMouseEnter={undefined}
                            customClass={`${
                                logo.customClass || ''
                            } ${isDarkText}`}
                        />
                    </div>
                    <div className="nav-buttons-container">
                        <NavigationMenuList className="navbar__radix-menu">
                            {items.map(item => (
                                <NavBarBtn
                                    key={item.id}
                                    id={item.id}
                                    icon={item.icon}
                                    subItems={item.subItems}
                                    link={item.link}
                                    onClick={handleNavItemClick}
                                    onMouseEnter={undefined}
                                    customClass={`${
                                        item.customClass || ''
                                    } ${isDarkText}`}
                                />
                            ))}
                        </NavigationMenuList>
                    </div>

                    {!slimNav && (
                        <NavBarBtn
                            id={t(`logIn`)}
                            link={
                                process.env.GATSBY_env === 'production'
                                    ? 'https://www.honeybook.com/app/login'
                                    : '/app/login'
                            }
                            onClick={logIn}
                            customClass={`login ${isDarkText}`}
                            onMouseEnter={onLoginMouseEnter}
                        />
                    )}

                    {!noCta && (
                        <RegistrationForm
                            source="header"
                            size="small"
                            customClass="hide-for-mobile"
                            buttonStyle="primary--dark"
                        />
                    )}

                    <div className="mobile-menu-buttons-wrapper">
                        <RegistrationForm
                            source="header"
                            size="small"
                            buttonStyle="primary--dark"
                        />

                        <HBButton
                            icon="nx-hamburger-16"
                            customClass="drawer-button"
                            buttonStyle="invisible"
                            size="large"
                            onClick={toggleMobileDrawer}
                            ariaLabel="menuitem"
                            role="menuitem"
                        />
                    </div>
                    {displayMobileDrawer && (
                        <MobileMenuDrawer
                            isMobileDrawerOpen={displayMobileDrawer}
                            toggleMobileDrawer={toggleMobileDrawer}
                            items={items}
                            isRebrand
                        />
                    )}
                </nav>
                <div id={GOOGLE_ONE_TAP_PARENT_ID}></div>
            </NavigationMenu>
        </>
    );
}

export default memo(NavBar);
