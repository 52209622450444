import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../components/honeybook-ui/HBText/HBText';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../components/FeatureBlockElements';
import '../styles/activation.scss';

const Activation = () => {
    const { t } = useTranslation();

    const ogTags = {
        url: 'https://www.honeybook.com/activation',
        title: 'Activation',
        description: 'Confirm your email',
        noindex: true
    };
    return (
        <Layout customClass="activation" ogTags={ogTags} slimNav noFooter noCta>
            <FeatureBlockSection customClass="activation__container">
                <FeatureBlockContent customClass="activation__content">
                    <HBMediaElement
                        customClass="activation__graphic"
                        name="marketing_site/activation_page/Illustration.png"
                        lazyLoad={false}
                    />
                    <HBText
                        customClass="activation__title"
                        tag="h1"
                        type="body-l"
                    >
                        {t('Activation.title')}
                    </HBText>
                    <HBText
                        customClass="activation__subtitle"
                        tag="p"
                        type="body-s"
                    >
                        {t('Activation.subtitle')}
                    </HBText>
                    <HBText
                        customClass="activation__check"
                        tag="p"
                        type="body-m"
                    >
                        {t('Activation.check')}
                    </HBText>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </Layout>
    );
};

export default Activation;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "activation"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
