import { useEffect, useMemo } from 'react';
import { useRecipientPageContext } from '../RecipientPage/context/recipient-page-context';
import AnalyticsService from '../../Services/AnalyticsService';
import UtilsService from '../../Services/UtilsService';

export const PublicTemplateIframe = () => {
    const {
        iframeRef,
        checkIsEventValid,
        emitToIframeEvent,
        templateData: {
            public_configuration: { public_url_cx: public_url }
        }
    } = useRecipientPageContext();

    const publicUrl = useMemo(() => {
        const hbAnonymousId = AnalyticsService.hbAnonymousId;
        const stableIdParamKey = UtilsService.HB_ANONYMOUS_ID_COOKIE_NAME;

        const currentSearchParams = new URLSearchParams(window.location.search);
        const publicUrlParams = new URLSearchParams(new URL(public_url).search);

        currentSearchParams.forEach((value, key) => {
            publicUrlParams.set(key, value);
        });

        publicUrlParams.set(stableIdParamKey, hbAnonymousId);

        const mergedParams = publicUrlParams.toString();
        const baseUrl = public_url.split('?')[0];

        return `${baseUrl}?${mergedParams}`;
    }, [public_url]);

    useEffect(() => {
        if (!publicUrl) {
            return;
        }

        const handler = (event: MessageEvent) => {
            const { origin } = new URL(publicUrl);
            if (event.origin !== origin) {
                return;
            }
            const iframeEvent = event.data.type;
            if (checkIsEventValid(iframeEvent)) {
                emitToIframeEvent({ eventName: iframeEvent, data: event.data });
            }
        };
        window.addEventListener('message', handler);
        return () => window.removeEventListener('message', handler);
    }, [publicUrl]);

    return <iframe ref={iframeRef} src={publicUrl} />;
};
