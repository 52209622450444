const BusinessPersonalityToolActionableTipsBlockStore = {
    sectionTitles: {
        actualizerRisingStar: 'Keep moving towards your big picture.',
        actualizerWaveMaker: 'Leverage the traction you’ve created.',
        actualizerDrivingForce:
            'Focus on only what you can do, then outsource the rest.',
        actualizerEmpireBuilder:
            'Focus on increasing revenue while doing less.',
        connectorRisingStar:
            'Use your relationships to build a solid foundation.',
        connectorWaveMaker: 'Incorporate structure and process.',
        connectorDrivingForce:
            'Focus on only what you can do, then outsource the rest.',
        connectorEmpireBuilder: 'Focus on increasing revenue while doing less.',
        creativeRisingStar: 'Build a strong foundation.',
        creativeWaveMaker: 'Leverage the traction you’ve created.',
        creativeDrivingForce:
            'Focus on only what you can do, then outsource the rest.',
        creativeEmpireBuilder: 'Focus on increasing revenue while doing less.',
        visionaryRisingStar: 'Keep moving towards your big picture.',
        visionaryWaveMaker: 'Leverage the traction you’ve created.',
        visionaryDrivingForce:
            'Focus on only what you can do, then outsource the rest.',
        visionaryEmpireBuilder: 'Focus on increasing revenue while doing less.'
    },
    tips: {
        visionaryRisingStar: [
            {
                title: 'First things first, define your mission',
                body: '#TEXT_START# <a href="/mission-statement">#LINK_TEXT#</a>#TEXT_END#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryRisingStar.tips.tip1.body',
                card: {
                    body: 'Create your mission statement in minutes. Just fill in the blanks in our free mission statement generator, and we’ll do the rest.',
                    linkText: 'Create mission statement',
                    linkUrl: '/mission-statement'
                }
            },
            {
                title: 'Nail down your service',
                body: 'What exactly are you going to offer? What are you not going to offer? At this stage in your business, try out different services and packages to see what your clients want. Adaptability is the key here.'
            },
            {
                title: 'Establish your brand',
                body: 'You’re naturally charismatic and a great communicator; use these strengths to get the word out about your brand. Let your personality shine in your social media, email communications and on your website—your brand is you after all!',
                card: {
                    body: 'Consistent branding is key. Check out our library of customizable brochure, invoice & contract templates that make it easy to showcase your brand—& turn inquiries into clients.',
                    linkText: 'Make a lasting impression',
                    linkUrl: '/'
                }
            },
            {
                title: 'Test out different ways of getting new inquiries',
                body: 'Add a call to action to book your services in different places, including Instagram, Facebook, on your website, in emails, etc.—don’t forget to remind current clients to spread the word—and track which channel gets you the most inquiries and which has the highest conversion rate.'
            },
            {
                title: 'Put your busywork on autopilot',
                body: 'Let’s face it, you don’t love getting caught up in the details, but that’s exactly why establishing processes are so important for your business. Take the time to think through every step you take in your business and establish systems to help you automate and systematize repetitive tasks. This will free up more of your time for big-picture thinking!',
                card: {
                    body: 'Integrated workflows let you take repetitive tasks, like follow-up emails, off your plate, so you can focus on the work you love.',
                    linkText: 'Save time with workflows',
                    linkUrl: '/automations'
                }
            }
        ],
        actualizerRisingStar: [
            {
                title: 'Set your brand’s vision and mission',
                body: 'Customers hire companies they connect with. Start connecting with your dream clients by creating your mission statement. Let these statements serve as a constant reminder of what you’re doing, who you’re doing it for and why you started this business in the first place.',
                card: {
                    body: 'Create your mission statement in minutes. Just fill in the blanks in our free mission statement generator, and we’ll do the rest.',
                    linkText: 'Create a mission statement',
                    linkUrl: '/mission-statement'
                }
            },
            {
                title: 'Establish your brand',
                body: '#TEXT_START# <a href="https://www.creativelive.com/class/branding-strategies-to-ignite-your-marketing-ashlyn-carter">#LINK_TEXT#</a>#TEXT_END#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.actualizerRisingStar.tips.tip2.body'
            },
            {
                title: 'Get repeat customers',
                body: 'Bust out your (very thorough) to-do list and make a note to follow up with your favorite clients to see if they have any additional needs in a few months.',
                card: {
                    body: 'Remember to send follow-up notes (or gifts!) with automatic reminders. HoneyBook can create a task and reminder for you, adding it to your to-do list as the date draws near.',
                    linkText: 'Remember every follow up',
                    linkUrl: ''
                }
            },
            {
                title: 'Establish processes',
                body: '#TEXT_START# <a href="/blog/process-map">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.actualizerRisingStar.tips.tip4.body'
            },
            {
                title: 'Test out different ways of getting new inquiries',
                body: 'Add a call to action to book your services in different places, including Instagram, Facebook, on your website, in emails, etc.—don’t forget to remind current clients to spread the word—and track which channel gets you the most inquiries and which has the highest conversion rate.',
                card: {
                    body: 'Easily customize your HoneyBook contact form to fit your brand. Then, embed it on your website or send it out to clients via a direct link. All your inquiries—in one place.',
                    linkText: 'Get more inquiries',
                    linkUrl: 'https://www.youtube.com/watch?v=ntrze1C5clg'
                }
            }
        ],
        actualizerWaveMaker: [
            {
                title: 'Build your own brand',
                body: 'Your brand is a reflection of you, your services and what makes you unique. Make sure to keep honing in on what makes your services like no one else’s and focus on building that out. (If you need a reminder about what differentiates your brand, go back and look at client reviews. People find your achievement-driven work ethic a breath of fresh air!)',
                card: {
                    body: 'Consistent branding is key. Check out our customizable brochure, invoice & contract templates that make it easy to showcase your brand—& book more clients as a result.',
                    linkText: 'Make a lasting impression',
                    linkUrl: '/features'
                }
            },
            {
                title: 'Identify your real client',
                body: '#TEXT# <a href="/blog/attract-ideal-clients">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.actualizerWaveMaker.tips.tip2.body'
            },
            {
                title: 'Follow up with clients fast',
                body: 'As the to-do-list master, you have a good handle on your processes and what works. It’s now time to start automating repetitive tasks so you can attend to your growing client list and fine-tuning your craft so you can start increasing your pricing.',
                card: {
                    body: 'Got an inquiry? Using the workflow feature, you can set up an automation to respond to them ASAP in your voice & branding (& even send automated follow-ups if they don’t respond!).',
                    linkText: 'Save time with workflows',
                    linkUrl: '/automations'
                }
            },
            {
                title: "Price like it's hot",
                body: 'You, my friend, are past the “getting started” phase where you might have had to price your services a little lower to get your first few customers. It can be hard to embrace change for your business personality type, but don’t let that keep you from charging what you’re worth. As you scale, aim to avoid these <a href="/blog/three-pricing-mistakes-creatives-make"3 pricing mistakes creatives make</a>.'
            },
            {
                title: 'Say goodbye to scattered spreadsheets and emails',
                body: 'We know you love staying organized and thank goodness for that. Because taking the time now to get organized will pay off as your business starts to scale. From client details to client communications to whom you need to follow up with and about what, making everything accessible in one easy-to-find place is critical to your business growth.',
                card: {
                    body: 'Find all your projects, documents, client communications & payments in one place. Best of all? Keep your more scattered clients organized, too, by providing them with their own client portal.',
                    linkText: 'Get organized',
                    linkUrl: '/all-in-one-business-platform'
                }
            }
        ],
        actualizerDrivingForce: [
            {
                title: 'Stop doing All The Things',
                body: ' With more clients and bigger projects come more work. At this stage in your business, efficiency is the name of the business game. Keep scaling your business by outsourcing tasks others could help you with, and save your brain power for the things only you can do.',
                card: {
                    body: 'Easily collaborate and communicate with clients in a shared workspace. You can also give access to your team so everyone’s on the same page.',
                    linkText: 'Learn about workspaces',
                    linkUrl: ''
                }
            },
            {
                title: 'Focus on your finances',
                body: 'Focus on your business’ cash flow, aiming to bring in more revenue than you’re spending for optimal financial health. It may also be time to start exploring where to invest in your business next.'
            },
            {
                title: 'Put your busywork on autopilot',
                body: 'As the organization master, you have a good handle on your processes and likely have each processes’ steps well documented. Study these steps and look for ways to increase your efficiency even more by automating repetitive tasks. By freeing up your busy schedule, you’ll get time back to hone your craft and allow you to provide a superior client experience that commands higher prices.',
                card: {
                    body: 'Having a hard time following up with all your inquiries? Our automated emails respond to them ASAP in your voice & branding (& even send automated follow-ups if they don’t respond!).',
                    linkText: 'Save time with workflows',
                    linkUrl: '/automations'
                }
            },
            {
                title: 'Revisit your branding',
                body: '#TEXT# <a href="/blog/how-i-elevate-my-brand-with-brochures">#LINK_TEXT#</a>#TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.actualizerDrivingForce.tips.tip4.body'
            },
            {
                title: "Price it like it's hot",
                body: 'We know it can be hard as an Actualizer to embrace change. But we challenge you to step outside of your comfort zone when it comes to your business financials. Take a critical look at your pricing compared to the quality of your services and don’t be afraid to charge what you’re worth.',
                card: {
                    body: 'Want to get paid faster? Our automated payment reminders follow up with overdue clients so you don’t have to.',
                    linkText: 'Get paid faster',
                    linkUrl: '/online-payment-software'
                }
            }
        ],
        actualizerEmpireBuilder: [
            {
                title: 'Make mo’ money, mo’ ways',
                body: 'Scale your business without adding more hours of work by creating passive income streams. Whether it’s creating a digital product or joining an affiliate program, multiple opportunities exist for a go-getter like you.',
                card: {
                    body: 'Turn your knowledge into passive income by becoming a HoneyBook Educator. You give your audience 30% off their first year of HoneyBook & earn $100 for every new member that subscribes using your link.',
                    linkText: 'Become an educator',
                    linkUrl: '/educator'
                }
            },
            {
                title: 'Keep it fresh',
                body: '#TEXT# <a href="/blog/dianuh-aerin-tripled-sales-live-painting-service"> #LINK_TEXT#</a>',
                bodyTranslationKey:
                    'businessPersonalityTool.result.actualizerEmpireBuilder.tips.tip2.body'
            },
            {
                title: 'Put your busywork on autopilot',
                body: '#TEXT# <a href="/blog/time-management-tips">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.actualizerEmpireBuilder.tips.tip3.body',
                card: {
                    body: 'Want to get paid faster? Our automated payment reminders follow up with overdue clients so you don’t have to.',
                    linkText: 'Get paid faster',
                    linkUrl: '/online-payment-software'
                }
            },
            {
                title: 'Focus on your superpowers',
                body: 'With more clients and bigger projects come more work. At this stage in your business, efficiency is the name of the business game. Keep scaling your business by outsourcing tasks others could help you with, and save your brain power for the things only you can do.'
            },
            {
                title: 'Take organization to the next level',
                body: 'Your projects are growing in complexity and so is your to-do list. Even multitasking pros like you need some help to save time--and your sanity. Find systems that help you keep tabs on all aspects of your business at a glance to increase efficiency.',
                card: {
                    body: 'We make it easy to keep your projects, documents, client communications & payments in one place. You can keep your more scattered clients organized, too, by providing them with their own client portal.',
                    linkText: 'Get organized',
                    linkUrl: '/features'
                }
            }
        ],
        connectorRisingStar: [
            {
                title: 'Set your business’ vision and mission',
                body: '#TEXT# <a href="/blog/mission-statement-examples">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.connectorRisingStar.tips.tip1.body',
                card: {
                    body: 'Create your mission statement in minutes. Just fill in the blanks in our free mission statement generator, and we’ll do the rest.',
                    linkText: 'Create mission statement',
                    linkUrl: '/mission-statement'
                }
            },
            {
                title: 'Establish your brand',
                body: '#TEXT# <a href="/blog/why-you-need-a-brand-strategy-before-branding">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.connectorRisingStar.tips.tip2.body'
            },
            {
                title: 'Connect in person',
                body: '#TEXT# <a href="/blog/podcasts-for-creatives">#LINK_TEXT#</a> #TEXT# <a href="/blog/how-getting-personal-on-instagram-fueled-my-business">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.connectorRisingStar.tips.tip3.body',
                card: {
                    body: 'Try attending a meetup, like the ones by The Rising Tide, our free community of solopreneurs across different industries who openly share decades of experiences — saving you decades of time.',
                    linkText: 'Find a meetup',
                    linkUrl: '/blog/tuesdaystogether-location-map'
                }
            },
            {
                title: 'Get your first repeat customers',
                body: '#TEXT# <a href="/blog/how-to-become-an-expert-at-writing-follow-up-emails">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.connectorRisingStar.tips.tip4.body'
            },
            {
                title: 'Take organization to the next level',
                body: 'You’re great at building relationships. Focus on developing that superpower and find other systems to provide the structure and organization to make your relationships impactful for your business.',
                card: {
                    body: ' Find all your projects, documents, client communications and payments in one place with HoneyBook so you’re always able to follow through and follow up efficiently.',
                    linkText: 'Get organized',
                    linkUrl: '/features'
                }
            }
        ],
        connectorWaveMaker: [
            {
                title: 'Connect in person',
                body: '#TEXT# <a href="/blog/podcasts-for-creatives">#LINK_TEXT#</a> #TEXT# <a href="/blog/how-getting-personal-on-instagram-fueled-my-business">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.connectorWaveMaker.tips.tip1.body',
                card: {
                    body: 'Try attending a meetup, like the ones by The Rising Tide, our free community of solopreneurs across different industries who openly share decades of experiences — saving you decades of time.',
                    linkText: 'Find a meetup',
                    linkUrl: '/blog/tuesdaystogether-location-map'
                }
            },
            {
                title: 'Focus on your pricing',
                body: '#TEXT <a href="/blog/three-pricing-mistakes-creatives-make">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.connectorWaveMaker.tips.tip2.body'
            },
            {
                title: 'Practice consolidation',
                body: '#TEXT# <a href="/blog/8-tools-your-small-business-should-be-using">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.connectorWaveMaker.tips.tip3.body',
                card: {
                    body: 'HoneyBook combines invoices, contracts, payments, client communication & more — all in one place. Want help getting set up? Our concierge team helps set up your projects so you can hit the ground running.',
                    linkText: 'Get organized',
                    linkUrl: '/all-in-one-business-platform'
                }
            },
            {
                title: 'Identify your ideal client',
                body: 'While you already have quite a few happy customers (it’s obvious your passion for building relationships makes you quite an attractive creative), your business is stable enough now that you can get a little more picky about whom you work with. Zone in on what your ideal customer looks like. Make a list of their qualities and try to understand how you got them. Then use this info to get more of your dream clients. Here are <a href="/blog/attract-ideal-clients">5 tips to get you started</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.connectorWaveMaker.tips.tip4.body'
            },
            {
                title: 'Set it and forget it',
                body: '#TEXT# <a href="/blog/work-efficiency">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/watch?v=fyWtVFyH9Go&list=PL6TUvBzCkmeVBPcjNtWORHcs2g5hRKQeX&index=12&t=0s">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.connectorWaveMaker.tips.tip5.body',
                card: {
                    body: 'Got an inquiry? You can easily set up a workflow to respond to them ASAP in your voice and with your branding (and even send automated follow-up emails if they don’t respond!).',
                    linkText: 'Save time with workflows',
                    linkUrl:
                        'https://www.youtube.com/watch?v=fyWtVFyH9Go&list=PL6TUvBzCkmeVBPcjNtWORHcs2g5hRKQeX&index=12&t=0s'
                }
            }
        ],
        connectorDrivingForce: [
            {
                title: 'Keep client relationships organized and centralized',
                body: 'Relationships are your thing, working hard is your thing, and often that means organization isn’t. If you care about relationship quality (think: replying promptly, referencing relevant details gracefully), you’ll want to have everything about the relationship, especially a client relationship, at your fingertips— their invoice number, their payment status, that little side note they made about hating multi-colored stripes because it reminds them of the wallpaper in their childhood bedroom.',
                card: {
                    body: 'Find all your projects, client communications & payments in one place so you’re always able to follow through & follow up efficiently.',
                    linkText: 'Get organized',
                    linkUrl: '/all-in-one-business-platform'
                }
            },
            {
                title: 'Practice consolidation',
                body: 'Like most business owners who are killin’ it, you’re probably balancing a hundred different tasks every day. And, let’s face it, you’re probably switching back and forth between places for every task, every day. Before it gets out of hand, pause, breathe, and start identifying systems that consolidate two or more of the main functions of your business. For example, Google combines: email, calendar, and document storage. HoneyBook combines: invoices, contracts, lead tracking, to-do’s, payments, client communication, document storage, project management and a quickbooks integration to boot. Less context switching will save you more brain power and energy than you know.'
            },
            {
                title: 'Sustain your business on mobile',
                body: 'When you’re on-site or on the go, leave the laptop at home and have your client relationships accessible from your phone. Get your favorite business apps on your phone so you can manage your business from anywhere.',
                card: {
                    body: 'Check payment updates, fire off invoices, review questionnaire responses and more from our mobile app.',
                    linkText: 'Run your business on the go',
                    linkUrl: `https://apps.apple.com/us/app/honeybook/id${process.env.GATSBY_honeybookAppId}`
                }
            },
            {
                title: 'Connect with other Driving Forces and Empire Builders',
                body: '#TEXT# <a href="https://www.goupwrd.honeybook.com">#LINK_TEXT#</a> #TEXT# <a href="https://www.goupwrd.honeybook.com/">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.connectorDrivingForce.tips.tip4.body'
            },
            {
                title: 'Take payment reminders 100% off your plate',
                body: 'You’ve set the foundation for a sustainable, successful business. That doesn’t mean you still don’t think — “Did the client pay me?” Automatic payment reminders are simple, yet critical to freeing up your time and making sure you get paid. Set up automated reminders to help streamline your business.',
                card: {
                    body: 'Want to get paid faster? Our automated payment reminders follow up with overdue clients so you don’t have to.',
                    linkText: 'Get paid faster',
                    linkUrl: '/online-payment-software'
                }
            }
        ],
        connectorEmpireBuilder: [
            {
                title: 'Master the ‘No’',
                body: 'As a CEB CEO, you’ve gotten okay at saying ‘no,’ but now’s the time to master it. When a new business opportunity presents itself — you genuinely want to make it happen, but you know scaling a business takes focus. The beauty of being your own boss is that you CAN say yes! But, understanding when, and more importantly why, to say ‘no’ might be the difference between spinning your wheels and climbing to the next level. When a new inquiry comes in: ask yourself -- is this my ideal client? Is there a positive ROI? Does this have the potential to turn into repeat or referral business? Am I genuinely excited about this? If no, it’s a no. Don’t wait, learn how to <a href="/blog/how-to-say-no-a-key-ingredient-to-time-management">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.connectorEmpireBuilder.tips.tip2.body'
            },
            {
                title: 'Put your busywork on autopilot',
                body: 'You have a good handle on your processes and likely have each processes’ steps well documented. Study these steps and look for ways to increase your efficiency even more by automating repetitive tasks. By freeing up your busy schedule, you’ll get time back to hone your craft and allow you to provide a superior client experience that commands higher prices.',
                card: {
                    body: 'Want to get paid faster? Our automated payment reminders follow up with overdue clients so you don’t have to. And our automated emails follow up with inquries if they don’t respond!',
                    linkText: 'Save time with workflows',
                    linkUrl: '/automations'
                }
            },
            {
                title: 'Connect with other Driving Forces and Empire Builders',
                body: '#TEXT# <a href="https://www.goupwrd.honeybook.com">#LINK_TEXT#</a> #TEXT# <a href="https://www.goupwrd.honeybook.com/">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.connectorEmpireBuilder.tips.tip4.body'
            },
            {
                title: 'Take organization to the next level',
                body: 'Your projects are growing in complexity and so is your to-do list. Even multitasking pros like you need some help to save time--and your sanity. Find systems that help you keep tabs on all aspects of your business at a glance to increase efficiency.',
                card: {
                    body: 'HoneyBook makes it easy to keep your projects, client communications & payments in one place. And keep your more scattered-brain clients organized, too, by providing them their own client portal.',
                    linkText: 'Get organized',
                    linkUrl: '/all-in-one-business-platform'
                }
            }
        ],
        creativeRisingStar: [
            {
                title: 'Set your brand’s vision and mission',
                body: 'As a CRS, your business is inherently unique. Your brand can grow in so many directions, but these statements will serve as a constant reminder of what you’re doing, who you’re doing it for, and why you started this business in the first place.',
                card: {
                    body: 'Not sure of where to start? Try our free mission statement generator to create your mission statement in seconds. Just fill in the blanks and we’ll do the rest.',
                    linkText: 'Create mission statement',
                    linkUrl: '/mission-statement'
                }
            },
            {
                title: 'Nail down the services you offer',
                body: 'As a creative, it can be easy to let your imagination run wild. Though this creativity sets you apart from your competition, it can lead to spending more time on projects than you initially anticipated. When you implement a framework of the services you offer, you can ensure you don’t burn out while giving yourself room to create.'
            },
            {
                title: 'Connect in person',
                body: '#TEXT# <a href="/blog/what-i-wish-i-knew">#LINK_TEXT#</a>. #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.creativeRisingStar.tips.tip3.body',
                card: {
                    body: 'Try attending a meetup, like the ones by The Rising Tide, our free community of solopreneurs across different industries who openly share decades of experiences — saving you decades of time.',
                    linkText: 'Find a meet up',
                    linkUrl: '/blog/tuesdaystogether-location-map'
                }
            },
            {
                title: 'Establish processes',
                body: '#TEXT# <a href="/blog/how-automation-working-efficiently-can-lead-to-a-better-client-experience">#LINK_TEXT#</a>#TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.creativeRisingStar.tips.tip4.body'
            },
            {
                title: 'Build your brand presence to get more clients',
                body: 'When you create, you can often get leads by word of mouth or referral, but you’ll need more to take your business to the next level. Make sure you document the work you’ve done to build out your online presence. Showcase your work on Instagram, create a business Facebook page, and build out your website. Then, leverage that content in your booking process as soon as a client inquiries.',
                card: {
                    body: 'Brochures are the super-customizable files within HoneyBook that make it easy for you to insert brand content, capture a clients attention, sell your services, and let them book you.',
                    linkText: 'Learn about brouchures',
                    linkUrl: 'https://www.youtube.com/watch?v=Hfl_3kdQ1eA'
                }
            }
        ],
        creativeWaveMaker: [
            {
                title: 'Evaluate your pricing',
                body: 'In the beginning, you may have priced yourself to take on as many clients as you could, but now you’ve proven that you can execute. You can start getting picky about who you work with and adjusting your pricing so that you can work with your ideal clients. Make sure that you evaluate the market and do the math. Figure out how many clients you would need at this higher price point to feel comfortable and grow.',
                card: {
                    body: 'Want to get paid faster? Our automated payment reminders follow up with overdue clients so you don’t have to.',
                    linkText: 'Get paid faster',
                    linkUrl: '/online-payment-software'
                }
            },
            {
                title: 'Make sure your finances are in order',
                body: '#TEXT# <a href="https://www.creativelive.com/class/put-your-money-to-work-take-control-of-your-business-finances-dominique-broadway">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.creativeWaveMaker.tips.tip2.body'
            },
            {
                title: 'Reduce repetitive tasks',
                body: '#TEXT# <a href="/blog/business-process-automation-examples">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.creativeWaveMaker.tips.tip3.body',
                card: {
                    body: 'Having a hard time following up with all your inquiries? Our automated emails respond to them ASAP in your voice & branding (& even send automated follow-ups if they don’t respond!).',
                    linkText: 'Start saving time',
                    linkUrl: '/automations'
                }
            },
            {
                title: 'Build out your brand',
                body: '#TEXT# <a href="/blog/why-you-need-a-brand-strategy-before-branding">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.creativeWaveMaker.tips.tip4.body'
            },
            {
                title: 'Consolidate aspects of your business',
                body: 'You likely have processes in place, though at times things can feel scattered. We’ve all been there, slowed down by checking calendars and email threads to make sure we have the right information. But this shouldn’t interrupt your creative process. Make sure you’re investing in tools that consolidate tasks so you can keep moving without skipping a beat.',
                card: {
                    body: 'HoneyBook makes it easy to keep your projects, client communications & payments in one place. And keep your more scattered-brain clients organized, too, by providing them their own client portal.',
                    linkText: 'Get organized',
                    linkUrl: '/all-in-one-business-platform'
                }
            }
        ],
        creativeDrivingForce: [
            {
                title: 'Automate aspects of your business',
                body: 'There’s no question — you’ve made it to the big leagues. And an established brand brings more inquiries, with high expectations in communication and execution. In order to keep up with this increased brand awareness, we recommend implementing automation tools that reduce repetitive tasks so you can focus on creating.',
                card: {
                    body: 'Having a hard time following up with all your inquiries? Our automated emails respond to them ASAP in your voice & branding (& even send automated follow-ups if they don’t respond!).',
                    linkText: 'Save time with workflows',
                    linkUrl: '/all-in-one-business-platform'
                }
            },
            {
                title: 'Keep healthy finances',
                body: '#TEXT# <a href="https://www.creativelive.com/class/put-your-money-to-work-take-control-of-your-business-finances-dominique-broadway">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.creativeDrivingForce.tips.tip2.body'
            },
            {
                title: 'Take payment reminders 100% off your plate',
                body: 'Speaking of automating aspects of your business, it’s definitely time to stop worrying about chasing clients down for payments. Automatic payment reminders are simple, yet critical to freeing up your time and making sure you get paid for your hard work.',
                card: {
                    body: 'Our automated payment reminders follow up with overdue clients so you don’t have to. Customize them with your branding and voice so your creative self shines through.',
                    linkText: 'Get paid faster',
                    linkUrl: '/online-payment-software'
                }
            },
            {
                title: 'Network with others and establish yourself as a thought leader',
                body: '#TEXT# <a href="https://www.goupwrd.honeybook.com/">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.creativeDrivingForce.tips.tip4.body'
            },
            {
                title: 'Hire a team/outsource tasks',
                body: 'With more clients and bigger projects come more work. At this stage in your business, efficiency is the name of the game. In order to stay sane as you continue to scale your business, practice delegating tasks to others, so you can save your brainpower for the things only you can do.',
                card: {
                    body: 'HoneyBook makes it easy to keep all your projects, documents, client communications and payments in one place, keeping you, your team and your client all on the same page.',
                    linkText: 'Smart scaling',
                    linkUrl: '/all-in-one-business-platform'
                }
            }
        ],
        creativeEmpireBuilder: [
            {
                title: 'Create order and cohesion between team members',
                body: 'In order for your business to thrive, you need to make sure your team has processes in place to keep the administrative tasks and creative execution working seamlessly together. Using tools make it easy for your team to share and pass along information so operations run smoothly from start to finish.',
                card: {
                    body: 'HoneyBook makes it easy to keep all your projects, documents, client communications and payments in one place, keeping you, your team and your client all on the same page.',
                    linkText: 'Smart scaling',
                    linkUrl: '/all-in-one-business-platform'
                }
            },
            {
                title: 'Keep it fresh',
                body: '#TEXT# <a href="https://www.creativelive.com/class/branding-strategies-to-ignite-your-marketing-ashlyn-carter">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.creativeEmpireBuilder.tips.tip2.body'
            },
            {
                title: 'Establish yourself as a thought leader',
                body: '#TEXT# <a href="/blog/mentorship-mastermind-groups#email">#LINK_TEXT#</a>#TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.creativeEmpireBuilder.tips.tip3.body',
                card: {
                    body: ' Turn your knowledge into passive income by becoming a HoneyBook Educator. You give your audience 30% off their first year of HoneyBook & earn $100 for every new member that subscribes using your link.',
                    linkText: 'Become an educator',
                    linkUrl: '/educator'
                }
            },
            {
                title: 'Master the ‘No’',
                body: '#TEXT# <a href="/blog/attract-ideal-clients">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.creativeEmpireBuilder.tips.tip4.body'
            },
            {
                title: 'Put your busywork on autopilot',
                body: 'At this stage in your business, you have a good handle on your processes and likely have each processes’ steps well documented. Study these steps and look for ways to increase your efficiency even more by automating repetitive tasks. By freeing up your busy schedule, you’ll get time back to hone your craft and allow you to provide a superior client experience that commands higher prices.',
                card: {
                    body: 'Want to get paid faster? Our automated payment reminders follow up with overdue clients so you don’t have to. And our automated emails follow up with inquiries if they don’t respond!',
                    linkText: 'Save time with workflows',
                    linkUrl: '/automations'
                }
            }
        ],
        visionaryWaveMaker: [
            {
                title: 'Let your brand shine',
                body: '#TEXT# <a href="https://www.creativelive.com/class/branding-strategies-to-ignite-your-marketing-ashlyn-carter">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryWaveMaker.tips.tip1.body',
                card: {
                    body: 'Consistent branding is key. Check out our library of customizable brochure, invoice & contract templates that make it easy to showcase your brand—& turn inquiries into clients.',
                    linkText: 'Make a lasting impression',
                    linkUrl: '/'
                }
            },
            {
                title: 'Identify your ideal clients',
                body: '#TEXT# <a href="/blog/how-i-scaled-my-business-from-2500-per-wedding-to-upwards-of-15k">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryWaveMaker.tips.tip2.body'
            },
            {
                title: 'Don’t want to do that? Automate it!',
                body: '#TEXT# <a href="/blog/email-automation-triple-bookings">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryWaveMaker.tips.tip3.body',
                card: {
                    body: 'Having a hard time following up with all your inquiries? Our automated emails respond to them ASAP in your voice & branding (& even send automated follow-ups if they don’t respond!).',
                    linkText: 'Save time with workflows',
                    linkUrl: '/automations'
                }
            },
            {
                title: "Price it like it's hot",
                body: 'You, my friend, are past the “getting started” phase where you might have had to price your services a little lower to get your first few customers. It can be hard to embrace change, but don’t let that keep you from charging what you’re worth. Check out Jeremy Chou’s post <a href="/blog/pricing-for-long-term-success">How to Price Your Business for Long Term Success</a> for actionable tips you can take today.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryWaveMaker.tips.tip4.body'
            },
            {
                title: 'Consolidate aspects of your business',
                body: '#TEXT# <a href="/blog/fusion-sound-and-lighting">#LINK_TEXT#<a/> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryWaveMaker.tips.tip5.body',
                card: {
                    body: 'HoneyBook makes it easy to keep your projects, client communications & payments in one place. And keep your more scattered-brain clients organized, too, by providing them their own client portal.',
                    linkText: 'Get organized',
                    linkUrl: '/all-in-one-business-platform'
                }
            }
        ],
        visionaryDrivingForce: [
            {
                title: 'Evaluate your pricing',
                body: '#TEXT# <a href="/blog/how-i-scaled-my-business-from-2500-per-wedding-to-upwards-of-15k">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryDrivingForce.tips.tip1.body',
                card: {
                    body: 'Want to get paid faster? Our automated payment reminders follow up with overdue clients so you don’t have to.',
                    linkText: 'Get paid faster',
                    linkUrl: '/online-payment-software'
                }
            },
            {
                title: 'Make sure your finances are in order',
                body: '#TEXT# <a href="https://www.creativelive.com/class/put-your-money-to-work-take-control-of-your-business-finances-dominique-broadway">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryDrivingForce.tips.tip2.body'
            },
            {
                title: 'Put your busywork on autopilot',
                body: '#TEXT# <a href="/blog/email-automation-triple-bookings">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryDrivingForce.tips.tip3.body',
                card: {
                    body: 'Having a hard time following up with all your inquiries? Our automated emails respond to them ASAP in your voice & branding (& even send automated follow-ups if they don’t respond!).',
                    linkText: 'Save time with workflows',
                    linkUrl: '/automations'
                }
            },
            {
                title: 'Evaluate your branding',
                body: '#TEXT# <a href="/blog/how-i-elevate-my-brand-with-brochures">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryDrivingForce.tips.tip4.body'
            },
            {
                title: 'Hire a team/outsource',
                body: '#TEXT# <a href="/blog/level-up-your-income-with-the-4-levels-of-value">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryDrivingForce.tips.tip5.body',
                card: {
                    body: 'HoneyBook makes it easy to keep all your projects, documents, client communications and payments in one place, keeping you, your team and your client all on the same page.',
                    linkText: 'Start scaling',
                    linkUrl: '/features'
                }
            }
        ],
        visionaryEmpireBuilder: [
            {
                title: 'Put your busywork on autopilot',
                body: '#TEXT# <a href="/blog/email-automation-triple-bookings">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryEmpireBuilder.tips.tip1.body',
                card: {
                    body: 'Having a hard time following up with all your inquiries? Our automated emails respond to them ASAP in your voice & branding (& even send automated follow-ups if they don’t respond!).',
                    linkText: 'Save time with workflows',
                    linkUrl: '/automations'
                }
            },
            {
                title: 'Keep it fresh',
                body: '#TEXT# <a href="/blog/reel-time-films">#LINK_TEXT#</a>#TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryEmpireBuilder.tips.tip2.body'
            },
            {
                title: 'Make mo’ money, mo’ ways',
                body: '#TEXT# <a href="/educator">HoneyBook Educator</a>)#TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryEmpireBuilder.tips.tip3.body',
                card: {
                    body: 'Turn your knowledge into  passive income by becoming a HoneyBook Educator. You give your audience 30% off their first year of HoneyBook & earn $200 for every new member that subscribes using your link.',
                    linkText: 'Become an educator',
                    linkUrl: '/educator'
                }
            },
            {
                title: 'Make sure your finances are in order',
                body: '#TEXT# <a href="https://www.creativelive.com/class/put-your-money-to-work-take-control-of-your-business-finances-dominique-broadway">#LINK_TEXT#</a> #TEXT#',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryEmpireBuilder.tips.tip4.body'
            },
            {
                title: 'Hire a team/outsource',
                body: '#TEXT# <a href="/blog/level-up-your-income-with-the-4-levels-of-value">#LINK_TEXT#</a>.',
                bodyTranslationKey:
                    'businessPersonalityTool.result.visionaryEmpireBuilder.tips.tip5.body',
                card: {
                    body: 'HoneyBook makes it easy to keep your projects, client communications & payments in one place. And keep your more scattered-brain clients organized, too, by providing them their own client portal.',
                    linkText: 'Start scaling',
                    linkUrl: '/features'
                }
            }
        ]
    }
};

export default BusinessPersonalityToolActionableTipsBlockStore;
