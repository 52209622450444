import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HBText from '../honeybook-ui/HBText/HBText';
import ArrowLeftIcon from '../../../src/svg/arrow-left-line.inline.svg';
import ArrowIcon from '../../../src/svg/arrow-circle.inline.svg';
import HBLoader from '../honeybook-ui/HBLoader/HBLoader';
import UtilsService from '../../Services/UtilsService';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import RegistrationForm from '../RegistrationForm/RegistrationForm';
import type { TCalculationData } from '../FeatureBlocks/TaxCalculatorHero/TaxCalculatorHero';

import './tax-calculator-results.scss';
export const TAX_TOOL_RESULT_CTA_CLASS_NAME = 'email-form__cta-button';

interface Props {
    onReturn: () => void;
    calculationData: TCalculationData;
}

const TaxCalculatorResults = ({
    onReturn,
    calculationData
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const [isLoadingComplete, setIsloadingComplete] = useState(false);

    const resultsSectionId = 'tax-calculator-results';

    useEffect(() => {
        setTimeout(() => setIsloadingComplete(true), 2000);

        if (!breakpoints.mediumUp) {
            UtilsService.scrollToElementId(resultsSectionId, 20);
        }
    }, []);

    const loadingContent = (
        <div className="tax-calculator-results__loading">
            <HBLoader />
            <HBText tag="p" type="body-s">
                {t('taxCalculator.calculatorResults.loading')}
            </HBText>
        </div>
    );

    const formattedResult = (
        <HBText
            customClass="tax-calculator-results__result"
            tag="h3"
            type="title-m"
        >{`$${Math.ceil(calculationData.totalTax)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</HBText>
    );

    const tips = ['expense', 'integrate', 'empower'];

    return (
        <div id={resultsSectionId} className="tax-calculator-results">
            <div className="tax-calculator-results__result-container">
                <div
                    className="tax-calculator-results__back-button"
                    onClick={onReturn}
                >
                    <ArrowLeftIcon />
                </div>

                <HBText
                    customClass="tax-calculator-results__title"
                    tag="p"
                    type="body-s"
                >
                    {t('taxCalculator.calculatorResults.title')}
                </HBText>

                <div className="tax-calculator-results__result-text-container">
                    {isLoadingComplete ? formattedResult : loadingContent}
                </div>

                <HBText
                    customClass="tax-calculator-results__description"
                    tag="p"
                    type="body-s"
                >
                    {t('taxCalculator.calculatorResults.enterEmail')}
                </HBText>

                <RegistrationForm
                    source="tax-tool-cta"
                    customClass={TAX_TOOL_RESULT_CTA_CLASS_NAME}
                    dataForIntakeFormTest={{
                        taxToolData: { email: calculationData.email }
                    }}
                />
            </div>
            <div className="tax-calculator-results__tips">
                <HBText tag="h4" type="subtitle-l">
                    {t('taxCalculator.calculatorResults.didYouKnow.title')}
                </HBText>

                <div className="tax-calculator-results__list">
                    {tips.map(tip => (
                        <div
                            key={tip}
                            className="tax-calculator-results__list-row"
                        >
                            <ArrowIcon />
                            <HBText
                                customClass="tax-calculator-results__list-item"
                                tag="p"
                                type="body-s"
                            >
                                {t(
                                    `taxCalculator.calculatorResults.didYouKnow.${tip}`
                                )}
                            </HBText>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TaxCalculatorResults;
