import { useTranslation } from 'react-i18next';

import './tax-filing-block.scss';
import HBText from '@honeybook-ui/HBText/HBText';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import EmailForm from '../../EmailForm/EmailForm';

import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';

import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

const TaxFilingBlock = (): JSX.Element => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const handleFormSubmit = (email, isChecked) => {
        AnalyticsService.trackClick(
            AnalyticsEvents.tax_filing_block_submit_email,
            {
                source: 'tax filing block'
            }
        );

        AnalyticsService.reportIterableEvent(
            'lead_created',
            {
                email: email,
                lead_type: 'tax_tool',
                accept_getting_emails: isChecked
            },
            { email }
        );
    };

    return (
        <FeatureBlockSection customClass="tax-filing-block">
            <FeatureBlockContent>
                <div className="tax-filing-block__image-container">
                    <HBMediaElement
                        name="marketing_site/tax_calculator/tax24.png"
                        customClass="tax-filing-block__image"
                        alt="Tax-Filing checklist cover"
                        width={700}
                    />
                </div>
                <div className="tax-filing-block__text-container">
                    <HBText
                        customClass="tax-filing-block__pre-title"
                        tag="h2"
                        type="body-m"
                    >
                        {t('TaxFilingBlock.preTitle')}
                    </HBText>
                    <HBText
                        customClass="tax-filing-block__title"
                        tag="h1"
                        type="title-m"
                    >
                        {t('TaxFilingBlock.title')}
                    </HBText>
                    <HBText
                        customClass="tax-filing-block__body"
                        tag="p"
                        type="body-m"
                    >
                        {t('TaxFilingBlock.body')}
                    </HBText>
                    <ul className="tax-filing-block__list">
                        <HBText
                            customClass="tax-filing-block__list-item"
                            tag="li"
                            type="body-m"
                        >
                            {t('TaxFilingBlock.list.listItem1')}
                        </HBText>
                        <HBText
                            customClass="tax-filing-block__list-item"
                            tag="li"
                            type="body-m"
                        >
                            {t('TaxFilingBlock.list.listItem2')}
                        </HBText>
                        <HBText
                            customClass="tax-filing-block__list-item"
                            tag="li"
                            type="body-m"
                        >
                            {t('TaxFilingBlock.list.listItem3')}
                        </HBText>
                    </ul>

                    {breakpoints.mediumUp && (
                        <HBText
                            customClass="tax-filing-block__input-pre-text"
                            tag="p"
                            type="body-xs"
                        >
                            {t('TaxFilingBlock.sendEmail')}
                        </HBText>
                    )}

                    <EmailForm
                        customClass="tax-filing-block__form"
                        onFormSubmit={handleFormSubmit}
                        buttonStyle="primary"
                        buttonText={
                            breakpoints.mediumUp
                                ? t('TaxFilingBlock.ctaText')
                                : t('TaxFilingBlock.ctaTextSmall')
                        }
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default TaxFilingBlock;
